import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.css']
})
export class CompanyFormComponent implements OnInit {
  @Input() parent: UntypedFormGroup;
  @Input() superParent: UntypedFormGroup;
  @Input() submitted : boolean;
  breakpoint = (window.innerWidth <= 768) ? 1 : 2;
  rowHeight = (window.innerWidth <= 768) ? '140px' : '75px';

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
  }

  get companyNameFormGroup(): UntypedFormGroup {
    return this.parent.get('companyName') as UntypedFormGroup;
  }

  get companiesRegisterNumberFormGroup(): UntypedFormGroup {
    return this.parent.get('companiesRegisterNumber') as UntypedFormGroup;
  }

  get addressFormGroup(): UntypedFormGroup {
    return this.parent.get('address') as UntypedFormGroup;
  }

  get postalCodeFormGroup(): UntypedFormGroup {
    return this.parent.get('postalCode') as UntypedFormGroup;
  }

  get cityFormGroup(): UntypedFormGroup {
    return this.parent.get('city') as UntypedFormGroup;
  }

  get beteiligungsquoteFormGroup(): UntypedFormGroup {
    return this.parent.get('beteiligungsquote') as UntypedFormGroup;
  }
}
