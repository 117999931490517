<!-- OLD -->
<!-- <app-header *ngIf="!newUi"></app-header> -->
<!-- <div class="router-container" [class.dashboard]="page == 'dashboard'">
  <router-outlet>
  </router-outlet>
</div> -->
<!-- old ends -->

<div class="new-ui-wrapper" [class.notAuthYet]="!isUserLoggedIn">
  <app-sidebar class="sidebar-container" *ngIf="isUserLoggedIn"></app-sidebar>
  <div class="main-panel">
    <app-navbar [title]="page" *ngIf="isUserLoggedIn"></app-navbar>
    <div class="content text-left">
      <div class="row">
        <div class="col-md-12 container-parent">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="full-loader" *ngIf="loading">
  <div class="text-center">
    <div class="spinner-border" style="width: 7rem; height: 7rem;">
      <span class="sr-only"></span>
    </div>
    <h4>Vertrag wird übermittelt...</h4>
  </div>
</div>
