import { OnDestroy, AfterViewInit, Component, HostListener, Inject, OnInit, ElementRef, ChangeDetectorRef} from '@angular/core';
import { UntypedFormBuilder, UntypedFormArray, UntypedFormGroup, Validators, } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { Router, ActivatedRoute } from '@angular/router';
import { ContractDataService } from '../../../shared/contractData.service';
import { faTimes, faInfo, faArrowRight, faUniversity, faQuestion, faDotCircle } from '@fortawesome/free-solid-svg-icons';
import { DlgImmoSteuerComponent } from '../../../dialogs/dlg-immo-steuer/dlg-immo-steuer.component';
import { DlgWEGComponent } from '../../../dialogs/dlg-weg/dlg-weg.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { DlgVorschauPersonaldataComponent } from '../../../dialogs/dlg-vorschau-personaldata/dlg-vorschau-personaldata.component';
import { SaveDlgComponent } from '../../../save-dlg/save-dlg.component';
import { interval, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as data from '../../../../../data.json';
import { SharedService } from '../../../shared/shared.service';
import { AuthService } from '../../../shared/auth.service';
import {Location} from '@angular/common';


export interface BuyerPerson {
  fNAme: string;
  llName: string;
  street: string;
  postalCode: number;
  town: string;
  birthDate: string;
  beteiligungsquote: string;
}

export interface BuyerCompany {
  Name: string;
  llName: string;
  street: string;
  postalCode: number;
  town: string;
  beteiligungsquote: string;
}

@Component({
  selector: 'app-contactdata',
  templateUrl: './contact-data.component.html',
  styleUrls: ['./contact-data.component.css'],
})

export class ContactDataComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    console.log('this');
    this.saveDB();
  }

  testValues: any = (data as any).default;
  masterForm: UntypedFormGroup;
  selectedBuyerType: string;
  faTimes = faTimes;
  faInfoCircle = faInfo;
  faArrowRight = faArrowRight;
  faUniversity = faQuestion;
  faDotCircle = faDotCircle;
  submitted = false;
  variantTouched = false;
  breakpoint = (window.innerWidth <= 768) ? 1 : 2;
  rowHeight = (window.innerWidth <= 768) ? '140px' : '75px';
  primeType;
  isUserLoggedIn;
  sharesError = false;

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
      panelClass: 'custom-snackBar',
    });
  }

  ngAfterViewInit() {
    let scrolloptions = { top: 0, left: 0 }
    window.scrollTo(scrolloptions);
  }

  moreLSBT: boolean = true;
  lessLSBT: boolean = false;

  contactData = {
    eMail: undefined,
    firstName: undefined,
    lastName: undefined,
    contactPerson: undefined,
    phoneNumber: undefined,
    contractOption: undefined,
    eMailCC: undefined,
    estate: undefined,
    kaeuferanteile: undefined,
    AnzahlLS: undefined
  };

  public OneBuyer: boolean;
  public SellerAndBuyerBOOL: boolean;
  public contractData: any;
  public userId: any;
  shares = 0;

  constructor(
    private _location: Location,
    private changeDetectorRef: ChangeDetectorRef,
    private auth : AuthService,
    private shared : SharedService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    @Inject(ContractDataService)
    private contractDataService: ContractDataService,
    public WEGDlg: MatDialog,
    public VorschauDlg: MatDialog,
    public SaveDlg: MatDialog,
    public snackBar: MatSnackBar,
    private el: ElementRef,
    private route:ActivatedRoute
  ) {
    this.contractDataService.changePage({ page : route.snapshot.data.type ? route.snapshot.data.type : localStorage.getItem('page') });
    switch (route.snapshot.data.type) {
      case 'gesellschaftsvertrag':
        localStorage.setItem('primeType','gesellschaftsvertrag');
        this.contractDataService.setContractPrimeType('gesellschaftsvertrag');
        break;
    }
    this.userId = localStorage.getItem('userId');
  }

  SetOneBuyerBool() {
    if ((this.buyersPrivateFormArray.length + this.buyersCompanyFormArray.length) > 1) this.OneBuyer = false;
    else this.OneBuyer = true;
  }

  ifMaximumBuyers() {
    if((this.buyersPrivateFormArray.length + this.buyersCompanyFormArray.length) >= 8) {
      return true
    } else {
      return false;
    }
  }

  ngOnDestroy() {
    console.log('unsubascaribe');
    if (!this.nextClicked) this.saveDB();
  }

  ngOnInit() {
    this.isUserLoggedIn = this.auth.isUserLoggedIn();

    this.contractDataService.State = 1;
    this.nextClicked = false;
    this.contractDataService.getContractData();
    this.primeType = localStorage.getItem('primeType');
    let gesellschafts = localStorage.getItem('gesellschafts');
    if(gesellschafts) {
      // let obj = JSON.parse(object);
      // console.log(obj);
      // console.log(this.primeType);
      // if(obj) {
      //   if(this.primeType == 'miet' && obj['contractOption'] == 'UBL')
      //     this.variant = 'BBL';
      //   else
      //     this.variant = obj['contractOption'];
      // }
    }

    this.masterForm = this.formBuilder.group({
      buyersPrivate: this.formBuilder.array([]),
      buyersCompany: this.formBuilder.array([])
    });

    const contactData = this.contractDataService.getContractContactData();
    const masterData = this.contractDataService.getContractMasterData();
    if (contactData && masterData) {
      this.fillFormWithData(contactData, masterData);
    }
    console.log(masterData);
    this.checkShares(masterData);

    if(!environment.production) {
      // this.fillMasterData(this.testValues.masterData);
      // this.contactData = this.testValues.contactData;
    }

    let isEditing = localStorage.getItem('isEditing');
    console.log(isEditing);

    if(isEditing == 'true') {
      console.log("editt");
    } else {
      let user = this.auth.getUser();
      if(user) {
        this.contactData = {
          eMail: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          contactPerson: undefined,
          phoneNumber: user.phoneNumber,
          contractOption: undefined,
          eMailCC: undefined,
          estate: undefined,
          kaeuferanteile: undefined,
          AnzahlLS: undefined
        };
      }
    }

    this.SetOneBuyerBool();
    this.masterForm.valueChanges.subscribe((formValues) => {
      this.checkShares(formValues);
    });
  }

  checkShares(formValues) {
     // console.log('Form values changed:', formValues);
     // console.log(JSON.stringify(formValues));

     let shares = 0;
     if(formValues.buyersCompany && formValues.buyersCompany.length) {
       let bc = formValues.buyersCompany;
       for (let b of bc) {
         if(b.beteiligungsquote && b.beteiligungsquote != '' && !isNaN(b.beteiligungsquote)) {
           shares += Number(b.beteiligungsquote);
         }
       }
     }

     if(formValues.buyersPrivate && formValues.buyersPrivate.length) {
       let bp = formValues.buyersPrivate;
       for (let b of bp) {
         if(b.beteiligungsquote && b.beteiligungsquote != '' && !isNaN(b.beteiligungsquote)) {
           shares += Number(b.beteiligungsquote);
         }
       }
     }

     this.shares = shares;
     if(shares != 100) {
       this.sharesError = true;
       this.masterForm.setErrors({ sharesError: true });
     }
  }

  private fillMasterData(masterData: any): void {
    console.log('masterData');
    console.log(masterData);

    const { buyersCompany, buyersPrivate } = masterData;

    if (buyersCompany.length > 0) {
      this.selectedBuyerType = 'company';
      buyersCompany.forEach(buyer =>
        this.buyersCompanyFormArray.push(this.initBuyerCompanyWithData(buyer))
      );
    }

    if (buyersPrivate.length > 0) {
      this.selectedBuyerType = 'person';
      buyersPrivate.forEach(buyer => {
        this.buyersPrivateFormArray.push(this.initBuyerPrivateWithData(buyer))
        }
      );
    }
  }

  private fillFormWithData(contactData: any, masterData: any): void {
    const { buyersCompany, buyersPrivate } = masterData;
    this.contactData = { ...contactData };

    if (buyersCompany.length > 0) {
      this.selectedBuyerType = 'company';
      buyersCompany.forEach(buyer =>
        this.buyersCompanyFormArray.push(this.initBuyerCompanyWithData(buyer))
      );
    }

    if (buyersPrivate.length > 0) {
      this.selectedBuyerType = 'person';
      buyersPrivate.forEach(buyer => {
        this.buyersPrivateFormArray.push(this.initBuyerPrivateWithData(buyer))
        }
      );
    }
  }

  addBuyer(): void {
    switch (this.selectedBuyerType) {
      case 'person': {
        this.buyersPrivateFormArray.push(this.initBuyerPrivateEmpty());
        break;
      }
      case 'company': {
        this.buyersCompanyFormArray.push(this.initBuyerCompanyEmpty());
      }
    }
    this.SetOneBuyerBool();
  }

  addBuyerComplex(buyerType): void {
    switch (buyerType) {
      case 'person': {
        this.buyersPrivateFormArray.push(this.initBuyerPrivateEmpty());
        break;
      }
      case 'company': {
        this.buyersCompanyFormArray.push(this.initBuyerCompanyEmpty());
      }
    }
    this.SetOneBuyerBool();
  }

  get buyersPrivateFormArray(): UntypedFormArray {
    return this.masterForm.get('buyersPrivate') as UntypedFormArray;
  }

  get buyersCompanyFormArray(): UntypedFormArray {
    return this.masterForm.get('buyersCompany') as UntypedFormArray;
  }

  logConsole() {
    this.contractDataService.getContractData();
  }

  gotoNext(IsValid: boolean) {
    this.submitted = true;

    console.log(this.masterForm.value);
    this.checkShares(this.masterForm.value);

    if (this.masterForm.valid) {
      let buypriv, buycomp : number;
      buypriv = this.buyersPrivateFormArray.length;
      buycomp = this.buyersCompanyFormArray.length;

      if (buypriv === 0) {
        this.buyersPrivateFormArray.removeAt(0);
      }

      if (buycomp === 0) {
        this.buyersCompanyFormArray.removeAt(0);
      }

      if (buycomp > 0 || buypriv > 0)
        this.SellerAndBuyerBOOL = true;
      else
        this.SellerAndBuyerBOOL = false;

      this.contractDataService.setContractContactData(this.contactData);
      this.contractDataService.setgesellContactData(this.masterForm.value);

      if (this.SellerAndBuyerBOOL) {
        this.openVorschau();
      }
      else {
        this.scrollToFirstInvalidControl();
        this.openSnackBar('Bitte mindestens einen Eintrag pro Vertragspartei eingeben.', 'schließen');
      }

    }
    else {
      console.log('here');
      this.scrollToFirstInvalidControl();
      this.openSnackBar('Bitte alles ausfüllen und jeweils eine Option auswählen.', 'schließen');
    }
  }

  private scrollToFirstInvalidControl() {
    // var firstInvalidControl: HTMLElement;
    var firstInvalidControl = this.el.nativeElement.querySelector("form .ng-invalid");
    console.log(firstInvalidControl);

    if(firstInvalidControl) {
      window.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
        behavior: "smooth"
      });
    }
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl ? controlEl.getBoundingClientRect().top +( window.scrollY - labelOffset) : 0;
  }

  private nextClicked = false;

  openVorschau() {
    const dialogRef = this.VorschauDlg.open(DlgVorschauPersonaldataComponent, {
      width: '500px',
      disableClose : true
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.nextClicked = true;
          if(this.userId && this.userId !== '')
            this.router.navigate(['./gesell-logic/'+this.userId]);
          else
            this.router.navigate(['./gesell-logic']);
        }
        else {
          this.contractDataService.ResetMasterDates();
        }
      }
    );
  }

  saveDB() {
    try {
       let buypriv, buycomp: number;
      buypriv = this.buyersPrivateFormArray.length;
      buycomp = this.buyersCompanyFormArray.length;

      if (buypriv === 0) {
        this.buyersPrivateFormArray.removeAt(0);
      }

      if (buycomp === 0) {
        this.buyersCompanyFormArray.removeAt(0);
      }

      this.contractDataService.setContractContactData(this.contactData);
      this.contractDataService.setgesellContactData(this.masterForm.value);
    } catch (error) {
      console.log('speichern hat nicht geklappt')  //this.openSnackBar('Das Speichern hat leider nicht geklappt!', 'schließen');
    }
    this.contractDataService.ResetMasterDates();
  }

  removeBuyerCompany(index: number) {
    this.buyersCompanyFormArray.removeAt(index);
    this.SetOneBuyerBool();
  }

  removeBuyerPrivate(index: number) {
    this.buyersPrivateFormArray.removeAt(index);
    this.SetOneBuyerBool();
  }

  private initBuyerPrivateEmpty(): UntypedFormGroup {
    return this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      formatedBirthday: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      postalCode: ['', Validators.required],
      beteiligungsquote: ['', [Validators.required, Validators.min(0), Validators.max(100), Validators.pattern(/^[0-9]*$/)]],
      id: [this.makeuid(9)]
    });
  }

  private initBuyerPrivateWithData(buyerPrivate: any): UntypedFormGroup {
    return this.formBuilder.group({
      firstname: [buyerPrivate.firstname, Validators.required],
      lastname: [buyerPrivate.lastname, Validators.required],
      formatedBirthday: [new Date(buyerPrivate.formatedBirthday), Validators.required],
      address: [buyerPrivate.address, Validators.required],
      city: [buyerPrivate.city, Validators.required],
      postalCode: [buyerPrivate.postalCode, Validators.required],
      beteiligungsquote: [buyerPrivate.beteiligungsquote, [Validators.required, Validators.min(0), Validators.max(100), Validators.pattern(/^[0-9]*$/)]],
      id: [buyerPrivate.uid ? buyerPrivate.uid : ( buyerPrivate.id ? buyerPrivate.id : this.makeuid(9))]
    });
  }

  private initBuyerCompanyEmpty(): UntypedFormGroup {
    return this.formBuilder.group({
      companyName: ['', Validators.required],
      companiesRegisterNumber: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      postalCode: ['', Validators.required],
      beteiligungsquote: ['', [Validators.required, Validators.min(0), Validators.max(100), Validators.pattern(/^[0-9]*$/)]],
      id: [this.makeuid(9)]
    });
  }

  private initBuyerCompanyWithData(buyerCompany: any): UntypedFormGroup {
    return this.formBuilder.group({
      companyName: [buyerCompany.companyName, Validators.required],
      companiesRegisterNumber: [
        buyerCompany.companiesRegisterNumber,
        Validators.required
      ],
      address: [buyerCompany.address, Validators.required],
      city: [buyerCompany.city, Validators.required],
      postalCode: [buyerCompany.postalCode, Validators.required],
      beteiligungsquote: [buyerCompany.beteiligungsquote, [Validators.required, Validators.min(0), Validators.max(100), Validators.pattern(/^[0-9]*$/)]],
      id: [buyerCompany.uid ? buyerCompany.uid : ( buyerCompany.id ? buyerCompany.id : this.makeuid(9))]
    });
  }

  formChange(e) {
    console.log(e);
  }

  makeuid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  goBack() {
    this.saveDB();
    this._location.back();
  }

}
