<div class="container">
  <mat-form-field appearance="none" class="no-padding no-line" [class.error]="invalidJson">
    <textarea matInput cdkAutosizeMinRows="10" [(ngModel)]="json" placeholder="{}" appTrim></textarea>
  </mat-form-field>
  <p class="text-danger" *ngIf="invalidJson">Please put valid json</p>
  <button mat-raised-button color="primary" (click)="parseJson()">
    json laden
  </button>
</div>
<br>
