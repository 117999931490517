// TODO:  Für KAufanbot ImmoEst raus, Geldlastenfrei (Wie erfolgt Übertragung) raus, Sonstige Rechte (Ja/NEin) -> raus
// TODO: Wie lange soll der Käufer an das Anbot genbunden sein? -> Datepicker -> varName: weglogic.bindungDatum

import {
  Component,
  OnDestroy,
  Inject,
  NgZone,
  OnInit,
  ViewChild,
  AfterViewInit,
  HostListener,
  ElementRef
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ContractDataService } from '../shared/contractData.service';
import { WEGlogicImmoDetailComponent } from '../weglogic-immo-detail/weglogic-immo-detail.component';
import { WEGlogicImmoDetailComponentMietWeg } from '../weglogic-immo-detail-miet-weg/weglogic-immo-detail-miet-weg.component';
import { faInfo, faTimes, faArrowRight, faSave, faUniversity, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DlgImmoSteuerComponent } from '../dialogs/dlg-immo-steuer/dlg-immo-steuer.component';
import { DlgLoeschenSonstigerLasterComponent } from '../dialogs/dlg-loeschen-sonstiger-laster/dlg-loeschen-sonstiger-laster.component';
import { DlgGeldlastenComponent } from '../dialogs/dlg-geldlasten/dlg-geldlasten.component';
import { DlgWidmungComponent } from '../dialogs/dlg-widmung/dlg-widmung.component';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImmoDetailLogicComponent } from '../immo-detail-logic/immo-detail-logic.component';
import { toNumber } from 'lodash';
import Fraction from 'fraction.js';
import { DlgVorschauWegComponent } from '../dialogs/dlg-vorschau-weg/dlg-vorschau-weg.component';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { LooseCurrencyPipe } from '../shared/looseCurrency.pipe';
import { isNumber } from 'util';
import { SonstigeRechteLogicComponent } from '../sonstige-rechte-logic/sonstige-rechte-logic.component';
import { interval, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
declare var $:any;
import { StripePaymentComponent } from '../shared/components/stripe-payment/stripe-payment.component';
import { SharedService } from '../shared/shared.service';

export interface Variant {
  value: string;
  viewValue: string;
  toolTip: string;
}

@Component({
  selector: 'app-weglogic',
  templateUrl: './weglogic.component.html',
  styleUrls: ['./weglogic.component.css']
})
export class WEGlogicComponent implements OnInit, AfterViewInit, OnDestroy {

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    console.log('unload');
    this.saveDB();
    // $event.returnValue = true;
  }

  timerSub: Subscription;
  submitted: boolean = false;
  disableSubmit: boolean = false;
  rowHeight = (window.innerWidth <= 768) ? '140px' : '75px';

  constructor(
    private shared: SharedService,
    private dialog: MatDialog,
    private router: Router,
    @Inject(ContractDataService)
    private contractDataService: ContractDataService,
    private _ngZone: NgZone,
    public immoSteuerdialog: MatDialog,
    public sonstigeLastenDlg: MatDialog,
    public geldLastenDlg: MatDialog,
    private formBuilder: UntypedFormBuilder,
    public snackBar: MatSnackBar,
    public WidmungDlg: MatDialog,
    public VorschauDlg: MatDialog,
    public SaveDlg: MatDialog,
    private datepipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private looseCurrencyPipe: LooseCurrencyPipe,
    private el: ElementRef,
    private route:ActivatedRoute
  ) {
    this.contractDataService.changePage({ page : route.snapshot.data.type ? route.snapshot.data.type : localStorage.getItem('page') });
    // this.timerSub = interval(environment.savetime).subscribe((func => {
    //   this.saveDB();
    // }));
    this.wegLogicData['frageLastenLoeschenDritte2'] = [];
    this.wegLogicData['frageLastenLoeschenDritte2'][0] = null;
    console.log(this.wegLogicData['frageLastenLoeschenDritte2']);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
      panelClass: 'custom-snackBar',
    });
  }

  ngAfterViewInit() {
    // this.fokuskauf();
    window.scrollTo(0, 0);
  }

  private async fokuskauf() {
    let a = document.getElementById('kaufpreis');
    if (a) {
      a.focus();
      await this.delay(10);
      a.blur();
    }
  }

  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  get buyersPrivateFormArray(): UntypedFormArray {
    return this.EPForm.get('buyersPrivate') as UntypedFormArray;
  }

  get buyersCompanyFormArray(): UntypedFormArray {
    return this.EPForm.get('buyersCompany') as UntypedFormArray;
  }

  get rightsPrivateFormArray(): UntypedFormArray {
    return this.RightForm.get('rightsPrivate') as UntypedFormArray;
  }

  get rightsCompanyFormArray(): UntypedFormArray {
    return this.RightForm.get('rightsCompany') as UntypedFormArray;
  }

  faInfoCircle = faInfo;
  faArrowRight = faArrowRight;
  faSave = faSave;
  faUniversity = faQuestion;

  kaeuferanteileOption;
  contractOption;
  public schenkung: boolean;
  public miet: boolean;
  public noCompSeller: boolean;
  public kaeuferanteileBOOL: boolean;
  public NoVorschau: boolean;
  public LSBOOL: boolean[];
  public mietdauerjahre = true;
  breakpoint = (window.innerWidth <= 768) ? 1 : 2;

  @ViewChild(WEGlogicImmoDetailComponent) immodetailComp: WEGlogicImmoDetailComponent;
  @ViewChild(WEGlogicImmoDetailComponentMietWeg) immodetailCompMietWeg: WEGlogicImmoDetailComponentMietWeg;
  @ViewChild(ImmoDetailLogicComponent) LSimmodetailcomp: ImmoDetailLogicComponent;
  @ViewChild(SonstigeRechteLogicComponent) SonstigeRechteWEG: SonstigeRechteLogicComponent;

  public kaeuferatneileBOOL: boolean;
  public wegLogicData = {
    kaufpreisOhneParkUndInventar: undefined,
    bemessungsgrundlagejahr: undefined,
    HauptmietzinsWohnungEUR: undefined,
    HauptmietzinsParkplaetzeEUR: undefined,
    GesamtHauptmietzinsEURmitUSt: undefined,
    UStWohnungEUR: undefined,
    UStParkplaetzeEUR: undefined,
    BAHauptmietzinsWohnungEUR: undefined,
    BAHauptmietzinsParkplaetzeEUR: undefined,
    BAGesamtHauptmietzinsEURmitUSt: undefined,
    BAUStWohnungEUR: undefined,
    BAUStParkplaetzeEUR: undefined,
    datumRestoreUebergabe: undefined,
    datumRestoreBindung: undefined,
    erstzulassungRestore: undefined,
    kaufpreis: undefined,
    kaufpreisbrutto: undefined,
    kaufpreisnetto: undefined,
    ust: undefined,
    inventar: "Nein",
    inventarWert: undefined,
    uebertragung: undefined,
    uebertragungCLfdNr: new Array(1),
    uebertragungEzClnr: new Array(1),
    lastenLoeschen: undefined,
    lastenLoeschenCLfdNr: new Array(1),
    lastenloeschenEzClnr: new Array(1),
    lastenloeschenGrundbuch: new Array(1),
    lastenloeschenType: new Array(1),
    ausserbuecherlicheRechte: undefined,
    uebergabeDatum: undefined,
    umfangGewaehrleistung: undefined,
    KVzwischenAngehoerigen: undefined,
    bewilligungVor08051945: undefined,
    sonstigeRechteChecked: {},
    sonstigeRechte: {},
    lssonstigeRechte: undefined,
    lsl2sonstigeRechte: undefined,
    lsl3sonstigeRechte: undefined,
    lsl4sonstigeRechte: undefined,
    ausnahmeIEST: undefined,
    ausserbuecherlicheRechteDetail: [],
    immodetail: undefined,
    l2immodetail: undefined,
    l3immodetail: undefined,
    l4immodetail: undefined,
    parkingPlaces: [],
    admin: {
      faggVerzicht: undefined,
      hinweiseVertrag: undefined,
      datenschutz: undefined,
      agb: undefined
    },
    EP: undefined,
    EPHelper: undefined,
    EPPartner: undefined,
    attachment: undefined,
    attachmentName: undefined,
    bindungDatum: undefined,
    EPCompany: undefined,
    EPPrivate: undefined,
    EPList: undefined,
    Gegenleistung: undefined,
    Anrechnung: undefined,
    frageLastenLoeschenDritte: undefined,
    LastenLoeschenBeteiligte: [],
    LastenLoeschenDritteBeteiligte: undefined,
    lastenloeschenKG: undefined,
    fragebauflaeche: undefined,
    fragebauerwartungsflaeche: undefined,
    fragefreiflaeche: undefined,
    fragelufflaeche: undefined,
    fragebauflaeche1: undefined,
    fragebauerwartungsflaeche1: undefined,
    fragefreiflaeche1: undefined,
    fragelufflaeche1: undefined,
    sub: undefined,
    l2sub: undefined,
    l3sub: undefined,
    l4sub: undefined,
    kaufpreisInWorten: undefined,
    vertragsPreisBrutto: undefined,
    kaufpreisinEUR: undefined,
    vertragsPreisBruttoinEUR: undefined,
    inventarWertinEUR: undefined,
    oeffentlicheFoerderungen: undefined,
    mietdauerinjahre: undefined,
    mietdauerinmonate: undefined,
    mietObjType: undefined,
    mehrals2VermietbareObj: undefined,
    mietRaeume: undefined,
    umsatzsteuerverpflichtung: undefined,
    betriebskostenType: undefined,
    monatlicheBetriebskosten: undefined,
    kautionType: undefined,
    kaution: undefined,
    verpflichtungenMieter: undefined,
    werKostenVertragserrichtung: undefined,
    sonstigeRechteWEG: undefined,
    kautionEUR: undefined,
    monatlicheBetriebskostenEUR: undefined,
    fragevermittlungsprovision: undefined,
    vermittlungsprovision: {
      wer: undefined,
      makler: undefined,
      verkaeuferin: undefined,
      verkaeuferinEUR: undefined,
      kaeuferin: undefined,
      kaeuferinEUR: undefined,
    },
    strasse: undefined,
    ort: undefined,
    plz: undefined,
    nutzflaeche: undefined,
    kategorieA: undefined,
    kategorieB: undefined,
    kategorieC: undefined,
    kategorieD: undefined,
    kategorieHelper: undefined,
    raeumung6monate: 'Nein',
    zusatzfragebj45: undefined,
    denkmalschutz: undefined,
    mietuebertragungsumfang: undefined,
    lagezuschlag: undefined,
    lagebeschreibung: undefined,
    parkplatz: undefined,
    gefoerdertewohnung: undefined,
    gewerblich: undefined,
    gebuehr: undefined,
    bemessungsgrundlage: undefined,
    kuendigungJaNein: undefined,
    kuendigung: undefined,
    kuendigunginmonaten: undefined,
    kuendigungsfrist: undefined,
    lwbebaut: undefined,
    bemessungsgrundlage1: undefined,
    ratenJaNein: undefined,
    ratenAnzahl: undefined,
    ratenHoehe: undefined,
    ersteRateDatum: undefined,
    marke: undefined,
    type: undefined,
    kmstand: undefined,
    erstzulassung: undefined,
    gewaehrleistung: undefined,
    mechanischerZustand: undefined,
    karosserieZustand: undefined,
    lackZustand: undefined,
    innenraumUndSontigesZustand: undefined,
    elektronikZustand: undefined,
    ersteRateDatumFormated: undefined,
    uebergabedatumsave: undefined,
    bindungdatumsave: undefined,
    erstzulassungsave: undefined,
    befristung: undefined,
    indexjaehrlich: undefined,
    indexwert: undefined,
  };

  public primeType = undefined;

  private vertragsKosten;

  private inventar;
  public inventarOptionen: string[] = ['Ja', 'Nein'];

  public anrechnungOptionen: string[] = ['Ja', 'Nein'];

  public mechanischerZustandOptionen: Variant[] = [
    {
      value: '1',
      viewValue: 'Einwandfrei ohne Verschleißerscheinungen. Planmäßig gewartet.',
      toolTip: '',
    },
    {
      value: '2',
      viewValue: 'Geringe Verschleißerscheinungen. Kein Reparaturbedarf. Kleinere Einstellarbeiten oder Inspektionen erforderlich.',
      toolTip: '',
    },
    {
      value: '3',
      viewValue: 'Mittlerem Kilometerstand entsprechende Reparaturen oder Wartungsarbeiten erforderlich.',
      toolTip: '',
    },
    {
      value: '4',
      viewValue: 'Größere Reparaturen oder Überholungsarbeiten erforderlich. Verkehrssicherheit nicht gegeben. Nicht fahrbereit.',
      toolTip: '',
    },
  ];
  public karosserieZustandOptionen: Variant[] = [
    {
      value: '1',
      viewValue: 'Gänzlich unbeschädigt. Keine Beulen. Keine Kratzer. Keine Roststellen.',
      toolTip: '',
    },
    {
      value: '2',
      viewValue: 'Kleine Beulen oder Kratzer. Geringe Steinschlagschäden.',
      toolTip: '',
    },
    {
      value: '3',
      viewValue: 'Beulen oder Kratzer. Leichte Blechschäden. Diverse Roststellen. Frühere Unfallschäden behoben, aber Spuren sichtbar. Unpassendes Zubehör montiert.',
      toolTip: '',
    },
    {
      value: '4',
      viewValue: 'Große Unfallschäden. Starke Durchrostungen. Beschädigung an tragenden Teilen. Verkehrssicherheit nicht gegeben.',
      toolTip: '',
    },
  ];
  public lackZustandOptionen: Variant[] = [
    {
      value: '1',
      viewValue: 'Originallack neuwertig konserviert. Hochglanz ohne Flecken oder Kratzspuren.',
      toolTip: '',
    },
    {
      value: '2',
      viewValue: 'Originallack oder gute Neulackierung. Kleine Kratzer oder Mattstellen im Decklack. Vereinzelte Steinschlagschäden ausgebessert.',
      toolTip: '',
    },
    {
      value: '3',
      viewValue: 'Matter korrodierter Lack oder schlechte Lackierung. Ausbesserungen erforderlich. Rohstellen, erhebliche Steinschlagschäden.',
      toolTip: '',
    },
    {
      value: '4',
      viewValue: 'Neulackierung notwendig. Große Roststellen oder Rostflecken. Diverse farbfalsche Nachlackierungen.',
      toolTip: '',
    },
  ];
  public innenraumUndSontigesZustandOptionen: Variant[] = [
    {
      value: '1',
      viewValue: 'Reifenabnützung bis 40%. Original-Dimension. Original-Schließsystem und Betriebsanleitung vorhanden. Keine Abnützungsspuren.',
      toolTip: '',
    },
    {
      value: '2',
      viewValue: 'Reifenabnützung bis 60%. Original-Dimension. Original-Schließsystem und Betriebsanleitung vorhanden. Geringe Abnützungsspuren.',
      toolTip: '',
    },
    {
      value: '3',
      viewValue: 'Reifenabnützung bis 80%. Funktionsfähiges Schließsystem und Betriebsanleitung vorhanden. Deutliche Abnützungsspuren. Spuren von Wassereintritt. Originalradio fehlt.',
      toolTip: '',
    },
    {
      value: '4',
      viewValue: 'Reifenabnützung bis 100%. Unpassende Dimension oder strak einseitig abgefahren. Spuren von Gewaltwirkung. Schließsystem unvollständig. Reparatur erforderlich. Beschädigung durch Wassereintritt.',
      toolTip: '',
    },
  ];
  public elektronikZustandOptionen: Variant[] = [
    {
      value: '1',
      viewValue: 'Einwandfrei ohne Störungen.',
      toolTip: '',
    },
    {
      value: '2',
      viewValue: 'Akkumulator für den Antrieb innerhalb der Garantiezeit und Komfortelektronik funktionstüchtig.',
      toolTip: '',
    },
    {
      value: '3',
      viewValue: 'Akkumulator für den Antrieb oder Komfortelektronik mit eingeschränkter Leistungsfähigkeit.',
      toolTip: '',
    },
    {
      value: '4',
      viewValue: 'Sicherheitsrelevante Bauteile defekt.',
      toolTip: '',
    },
  ];

  public uebertragsumfangArten = [
    {
      value: 'gesamte Liegenschaft',
      label : 'gesamte Liegenschaft'
    },
    {
      value: 'einzelne Grundstücke',
      label : 'Teilfläche lt. Lageplan (ist dem Mietvertrag beizulegen)'
    }
  ];

  public mietKategorieOptionen: Variant[] = [
    {
      value: 'A',
      viewValue: 'Kategorie A',
      toolTip: '',
    },
    {
      value: 'B',
      viewValue: 'Kategorie B',
      toolTip: '',
    },
    {
      value: 'C',
      viewValue: 'Kategorie C',
      toolTip: '',
    },
    {
      value: 'D',
      viewValue: 'Kategorie D',
      toolTip: '',
    },
  ]

  public WerProvisionOptionen: Variant[] = [
    {
      value: 'beides',
      viewValue: 'Verkäufer- und käuferseitig',
      toolTip: 'Lorem ipsum...'
    },
    {
      value: 'verkaeufer',
      viewValue: 'Verkäuferseitig',
      toolTip: 'Lorem ipsum...'
    },
    {
      value: 'kaeufer',
      viewValue: 'Käuferseitig',
      toolTip: 'Lorem ipsum...'
    },
  ]

  private uebertragung;
  public uebertragungArten: string[] = [
    'Geldlastenfrei',
    'Verbindlichkeiten werden mitübernommen'
  ];


  mietObjTypeOptionen: Variant[] = [
    {
      value: 'wohnung',
      viewValue: 'Wohnung',
      toolTip: 'Lorem ipsum...'
    },
    {
      value: 'wohnung',
      viewValue: 'geförderte Wohnung',
      toolTip: 'Lorem Ispum...'
    },
    {
      value: 'geschaeftsraum',
      viewValue: 'Geschäftsraum',
      toolTip: 'Lorem ipsum...'
    },
    {
      value: 'unternehmenspacht',
      viewValue: 'Unternehmenspacht',
      toolTip: 'Lorem ipsum...'
    },
    {
      value: 'dienstwohnung',
      viewValue: 'Dienstwohnung',
      toolTip: 'Lorem ipsum...'
    },
    {
      value: 'zweitwohnsitz',
      viewValue: 'Wohnung für Zweitwohnsitz',
      toolTip: 'Lorem ipsum...'
    },
    {
      value: 'parkplatz',
      viewValue: 'Parkplatz oder Garage',
      toolTip: 'Lorem Ispum...'
    }
  ];

  vorkaufsrechtOptionen: Variant[] = [
    {
      value: '1072abgb',
      viewValue: 'Vorkaufsrecht gem. § 1072 ABGB',
      toolTip: 'Lorem ipsum...'
    },
    {
      value: 'alle',
      viewValue: 'Vorkaufsrecht für alle Veräußerungsarten',
      toolTip: 'Lorem ipsum...'
    },
  ];


  vorkaufsrechtPreisOptionen: Variant[] = [
    {
      value: 'schaetzpreis',
      viewValue: 'Schätzpreis zum Eintritt der Veräußerung',
      toolTip: 'Lorem ipsum...'
    },
    {
      value: 'fixpreis',
      viewValue: 'Fixer Preis eingeben',
      toolTip: 'Lorem ipsum...'
    },
  ];

  wiederkaufsrechtPreisOptionen: Variant[] = [
    {
      value: 'schaetzpreis',
      viewValue: 'Schätzpreis zum Eintritt der Veräußerung',
      toolTip: 'Lorem ipsum...'
    },
    {
      value: 'fixpreis',
      viewValue: 'Fixer Preis eingeben',
      toolTip: 'Lorem ipsum...'
    }
  ];

  belastungsUndVeraeusserungsverbotOptionen: Variant[] = [
    {
      value: 'belastungUndVerauesserung',
      viewValue: 'Belastungs- und Veräusserungsverbot',
      toolTip: 'Lorem ipsum...'
    },
    {
      value: 'belastung',
      viewValue: 'Belastungsverbot',
      toolTip: 'Lorem ipsum...'
    },
    {
      value: 'veraeusserung',
      viewValue: 'Veräusserungsverbot',
      toolTip: 'Lorem ipsum...'
    }
  ];

  naheAngehorigeOptionen: Variant[] = [
    {
      value: 'Ja',
      viewValue: 'Ja',
      toolTip: 'Lorem ipsum...'
    },
    {
      value: 'Nein',
      viewValue: 'Nein',
      toolTip: 'Lorem ipsum...'
    }
  ];

  public wohnrechtOptionen: Variant[] = [
    {
      value: 'gesamte',
      viewValue: 'Das Wohnrecht umfasst den gesamten Kaufgegenstand',
      toolTip: 'Lorem ipsum...'
    },
    {
      value: 'teile',
      viewValue: 'Das Wohnrecht umfasst nur gewisse Teile des Kaufgegenstandes',
      toolTip: 'Lorem ipsum...'
    },
  ];

  public nutzungsRechtOptionen: Variant[] = [
    {
      value: 'gesamte',
      viewValue: 'Das Nutzungsrecht umfasst den gesamten Kaufgegenstand',
      toolTip: 'Lorem ipsum...'
    },
    {
      value: 'teile',
      viewValue: 'Das Nutzungsrecht umfasst nur gewisse Teile des Kaufgegenstandes',
      toolTip: 'Lorem ipsum...'
    },
  ];

  public lastenloeschenTypeOptionen: Variant[] = [
    {
      value: 'einverleibung',
      viewValue: 'Einverleibung der Löschung (Berechtigter verzichtet)',
      toolTip: 'Lorem ipsum...'
    },
    {
      value: 'loeschung',
      viewValue: 'Löschung (Berechtigter verstorben)',
      toolTip: 'Lorem ipsum...'
    }
  ]

  public fruchtgenussrechtOptionen: Variant[] = [
    {
      value: 'gesamte',
      viewValue: 'Das Fruchtgenussrecht umfasst den gesamten Kaufgegenstand',
      toolTip: 'Lorem ipsum...'
    },
    {
      value: 'teile',
      viewValue: 'Das Fruchgenussrecht umfasst nur gewisse Teile des Kaufgegenstandes',
      toolTip: 'Lorem ipsum...'
    }
  ];

  private rightPersonList;
  private rightCompanyList;


  public lastenLoeschenOptionen: string[] = ['Ja', 'Nein'];

  public widmungOptionen: string[] = ['Ja', 'Nein'];

  public GegenleistungOptionen: string[] = ['Ja', 'Nein'];

  public ausserbuecherlicheRechteOptionen: string[] = ['Ja', 'Nein'];

  private ausserbuecherlicheRechteOptionenExtended: Variant[] = [
    {
      value: 'mietverhaeltnis',
      viewValue: 'Mietverhältnis wird übernommen',
      toolTip: 'Lorem ipsum...'
    },
    {
      value: 'dienstbarkeit',
      viewValue:
        'Dienstbarkeit, welche nicht im Grundbuch eingetragen ist, wird übernommen',
      toolTip: 'Lorem ipsum...'
    },
    {
      value: 'sonstigeLasten',
      viewValue:
        'Sonstige Lasten, welche nicht im Grundbuch eingetragen sind, werden übernommen',
      toolTip: 'Lorem ipsum...'
    }
  ];

  private umfangGewaehrleistung;
  public umfangGewaehrleistungOptionen: string[] = [
    'Neutral',
    'Käuferfreundlich',
    'Verkäuferfreundlich',
    'Verbrauchervertrag (B2C)'
  ];

  private KVzwischenAngehoerigen;
  KVzwischenAngehoerigenOptionen: string[] = ['Ja', 'Nein'];
  EPPartnerOptionen: string[] = ['Ja', 'Nein'];
  public KVzwischenAngehoerigenVisible: boolean;
  JaNeinOptionen: string[] = ['Ja', 'Nein'];

  private bewilligungVor08051945;
  bewilligungVor08051945Optionen: string[] = ['Ja', 'Nein', 'Weiss nicht'];
  MietBewilligungVor08051945Optionen: string[] = ['Ja', 'Nein'];

  flaechenWidmungOptionen2: string[] = [
    'Baufläche',
    'Bauerwartungsfläche',
    'Land- & Forstwirtschaft oder sonstige Freifläche',
    'gemischt'
  ];

  flaechenWidmungOptionen: Variant[] = [
    {
      value: 'Baufläche',
      viewValue: 'Baufläche',
      toolTip: ''
    },
    {
      value: 'Bauerwartungsfläche',
      viewValue: 'Bauerwartungsfläche',
      toolTip: ''
    },
    {
      value: 'Land- & Forstwirtschaft oder sonstige Freifläche',
      viewValue: 'Land- & Forstwirtschaft oder sonstige Freifläche',
      toolTip: ''
    },
    {
      value: 'gemischt',
      viewValue: 'Gemischte Widmung',
      toolTip: ''
    },
  ];

  EPOptionen: Variant[] = [
    {
      value: 'gesamte Wohnung oder Geschäftsräumlichkeit',
      viewValue: 'gesamte Wohnungseigentumseinheit (Top) wird übertragen',
      toolTip: ''
    },
    {
      value: 'halbes Top',
      viewValue: 'halbe Wohnungseigentumseinheit (Top) wird übertragen',
      toolTip: ''
    },
  ];

  EPHelperOptionen: Variant[] = [
    {
      value: 'haelfteeigentuemer',
      viewValue: 'Hälfteeigentümer',
      toolTip: ''
    },
    {
      value: 'eigentuemeruebertraghalbeanteile',
      viewValue: 'Alleineigentümer',
      toolTip: ''
    }
  ];

  frageSonstigeRechteOptionen: string[] = ['Ja', 'Nein'];
  private frageSonstigeRechte;

  sonstigeRechte;
  private sonstigeRechteOptionen: Variant[] = [
    {
      value: 'wohnrecht',
      viewValue: 'Wohnrecht',
      toolTip: 'Unbefristetes Wohnrecht in der Wohnung'
    },
    {
      value: 'fruchtgenussrecht',
      viewValue: 'Fruchtgenussrecht',
      toolTip: 'Lorem ipsum dolor....'
    },
    {
      value: 'belastungUndVeraeusserungsverbot',
      viewValue: 'Belastungs- und Veräußerungsverbot',
      toolTip: 'Lorem ipsum dolor....'
    },
    {
      value: 'belastungsverbot',
      viewValue: 'Belastungsverbot',
      toolTip: 'Lorem ipsum dolor....'
    },
    {
      value: 'veraeußerungsverbot',
      viewValue: 'Veräußerungsverbot',
      toolTip: 'Lorem ipsum dolor....'
    },
    {
      value: 'vorkaufsrecht',
      viewValue: 'Vorkaufsrecht',
      toolTip: 'Lorem ipsum dolor....'
    },
    {
      value: 'wiederkaufsrecht',
      viewValue: 'Wiederkaufsrecht',
      toolTip: 'Lorem ipsum dolor....'
    },
    {
      value: 'dienstbarkeitsrecht',
      viewValue: 'Dienstbarkeitsrecht',
      toolTip: 'Lorem ipsum dolor....'
    }
  ];

  private ausnahmeIEST;
  public ausnahmeIESTOptionen: Variant[] = [
    {
      value: 'keineBefreiung',
      viewValue: 'Keine Befreiung',
      toolTip: ''
    },
    {
      value: 'herstellerbefreiung',
      viewValue: 'Herstellerbefreiung',
      toolTip: ''
    },
    {
      value: 'verkaeuferKoerperschaft',
      viewValue: 'Verkäufer ist eine Körperschaft',
      toolTip: ''
    }
  ];

  public ausnahmeIESTOptionenPrivat: Variant[] = [
    {
      value: 'keineBefreiung',
      viewValue: 'Keine Befreiung',
      toolTip: ''
    },
    {
      value: 'hauptWohnsitzBefreiung2',
      viewValue: 'Hauptwohnsitzbefreiung 2 Jahre',
      toolTip: ''
    },
    {
      value: 'hauptWohnsitzBefreiung5aus10',
      viewValue: 'Hauptwohnsitzbefreiung 5 aus 10 Jahre',
      toolTip: ''
    },
    {
      value: 'herstellerbefreiung',
      viewValue: 'Herstellerbefreiung',
      toolTip: ''
    },
  ];

  public contractData: any;

  // EPKäuferdaten

  faTimes = faTimes;
  EPForm: UntypedFormGroup;
  RightForm: UntypedFormGroup;
  selectedBuyerType: string;
  contractPartnerTypes: Variant[] = [
    {
      value: 'person',
      viewValue: 'Privatperson',
      toolTip: 'Natürliche Einzelperson'
    },
    {
      value: 'company',
      viewValue: 'Firma',
      toolTip: 'Firma oder juristische Person'
    }
  ];

  mietBetriebskostenOptionen: Variant[] = [
    {
      value: 'akonto',
      viewValue: 'Betriebskostenakonto (Jahresabrechnung)',
      toolTip: 'Lorem Ipsum'
    },
    {
      value: 'fixbetrag',
      viewValue: 'Betriebskostenfixbetrag (keine Abrechnung)',
      toolTip: 'Lorem Ipsum'
    }
  ];

  mietBetriebskostenOptionenSpezial: Variant[] = [
    {
      value: 'akonto',
      viewValue: 'Betriebskostenakonto (Jahresabrechnung)',
      toolTip: 'Lorem Ipsum'
    },
    {
      value: 'fixbetrag',
      viewValue: 'Betriebskostenfixbetrag (keine Abrechnung)',
      toolTip: 'Lorem Ipsum'
    },
    {
      value: 'keine',
      viewValue: 'keine Betriebskosten',
      toolTip: 'Lorem Ipsum'
    },
  ];

  kautionOptionen: Variant[] = [
    {
      value: 'keine',
      viewValue: 'keine',
      toolTip: '',
    },
    {
      value: 'bar',
      viewValue: 'bar',
      toolTip: 'Lorem Ipsum'
    },
    {
      value: 'bankgarantie',
      viewValue: 'Bankgarantie',
      toolTip: 'Lorem Ipsum'
    },
    {
      value: 'sparbuch',
      viewValue: 'Sparbuch',
      toolTip: 'Lorem Ipsum'
    },
    {
      value: 'behoerdengarantie',
      viewValue: 'Kautionsgarantie',
      toolTip: 'Lorem Ipsum'
    }
  ];

  verpflichtungMieterOptionenPark: Variant[] = [
    {
      value: 'schnee',
      viewValue: 'Schneeräumung',
      toolTip: 'Lorem Ipsum'
    }
  ];

  verpflichtungMieterOptionen: Variant[] = [
    {
      value: 'schnee',
      viewValue: 'Schneeräumung',
      toolTip: 'Lorem Ipsum'
    },
    {
      value: 'rasen',
      viewValue: 'Rasenmähen',
      toolTip: 'Lorem Ipsum'
    },
    {
      value: 'garten',
      viewValue: 'Gartenpflege',
      toolTip: 'Lorem Ipsum'
    },
    {
      value: 'reparatur',
      viewValue: 'Reparatur von Geräten',
      toolTip: 'Lorem Ipsum'
    },
    {
      value: 'gastherme',
      viewValue: 'Wartung der Gastherme (wenn keine Hausverwaltung)',
      toolTip: 'Lorem I6+psum'
    },
    {
      value: 'stiegenhaus',
      viewValue: 'Reinigung Stiegenhaus',
      toolTip: ''
    }
  ];

  verpflichtungMieterOptionenSpezi: Variant[] = [
    {
      value: 'schnee',
      viewValue: 'Schneeräumung',
      toolTip: 'Lorem Ipsum'
    },
    {
      value: 'rasen',
      viewValue: 'Rasenmähen',
      toolTip: 'Lorem Ipsum'
    },
    {
      value: 'garten',
      viewValue: 'Gartenpflege',
      toolTip: 'Lorem Ipsum'
    },
    {
      value: 'stiegenhaus',
      viewValue: 'Reinigung Stiegenhaus',
      toolTip: ''
    }
  ];

  MieterVermieterOptionen: Variant[] = [
    {
      value: 'kaeufer',
      viewValue: 'Mieter',
      toolTip: 'Lorem Ipsum'
    },
    {
      value: 'verkaeufer',
      viewValue: 'Vermieter',
      toolTip: 'Lorem Ipsum'
    },
    {
      value: 'haelfte',
      viewValue: 'je zur Hälfte',
      toolTip: 'Lorem Ipsum'
    }
  ];

  MieterVermieterOptionenKauf: Variant[] = [
    {
      value: 'verkaeufer',
      viewValue: 'Verkäuferseite',
      toolTip: 'Lorem Ipsum'
    },
    {
      value: 'kaeufer',
      viewValue: 'Käuferseite',
      toolTip: 'Lorem Ipsum'
    },
    {
      value: 'haelfte',
      viewValue: 'je zur Hälfte',
      toolTip: 'Lorem Ipsum'
    }
  ];

  MieterVermieterOptionenSchenk: Variant[] = [
    {
      value: 'kaeufer',
      viewValue: 'Geschenknehmerseite',
      toolTip: 'Lorem Ipsum'
    },
    {
      value: 'verkaeufer',
      viewValue: 'Geschenkgeberseite',
      toolTip: 'Lorem Ipsum'
    },
    {
      value: 'haelfte',
      viewValue: 'je zur Hälfte',
      toolTip: 'Lorem Ipsum'
    }
  ];

  genDateFromString(a: string): Date {
    if (a) {
      const date = new Date();
      const b = a.split('.');
      date.setFullYear(parseInt(b[2]), parseInt(b[1]) - 1, parseInt(b[0]));
      return date;
    }
    else return null;
  }

  ratenDatumChange(a) {
    if (a) {
      const date = this.genDateFromString(this.datepipe.transform(this.wegLogicData.datumRestoreUebergabe, 'dd.MM.yyyy'));
      const b = this.wegLogicData.ratenAnzahl as number;
      date.setMonth(date.getMonth() - b);
      this.wegLogicData.ersteRateDatum = this.datepipe.transform(date.toDateString(), 'dd.MM.yyyy');
      this.wegLogicData.ersteRateDatumFormated = this.datepipe.transform(date.toDateString(), 'yyyy-MM-dd');
    }
  }

  public ratenchange() {
    if (this.wegLogicData.ratenAnzahl && this.wegLogicData.kaufpreis) {
      const k = this.transformCurrencyToNumber(this.wegLogicData.kaufpreis);
      const a = this.wegLogicData.ratenAnzahl as number;
      if (a > 0 && k > 0) {
        this.wegLogicData.ratenHoehe = k / a;
        this.wegLogicData.ratenHoehe = this.currencyPipe.transform(this.wegLogicData.ratenHoehe, 'EUR');
      }
      else this.wegLogicData.ratenHoehe = undefined;
    }
    else this.wegLogicData.ratenHoehe = undefined;
  }

  togglemietdauerJodM() {
    this.mietdauerjahre = !this.mietdauerjahre;
    if (this.mietdauerjahre) {
      this.wegLogicData.mietdauerinmonate = undefined;
    }
    else {
      this.wegLogicData.mietdauerinjahre = undefined;
    }
  }

  public setNaheAngehorigeOptionen() {

    if (this.rightPersonList.length > 1) {

      this.naheAngehorigeOptionen = [
        {
          value: 'Ja',
          viewValue: 'Ja',
          toolTip: 'Lorem ipsum...'
        },
        {
          value: 'Nein',
          viewValue: 'Nein',
          toolTip: 'Lorem ipsum...'
        },
        {
          value: 'teilweise',
          viewValue: 'teilweise',
          toolTip: 'Lorem ipsum...'
        },
      ];
    }

  }

  public UStSatzMiet = 0;
  mietzinschange(event, sender) {
    if (sender == 'art') {
      if (this.wegLogicData.mietObjType == 'parkplatz') {
        console.log('Parkplatz');
        this.wegLogicData.parkplatz = true;
      }
      else this.wegLogicData.parkplatz = false;

      if (this.wegLogicData.mietObjType == 'wohnung') {
        if (event.source.selected.id == 'geförderte Wohnung') {
          this.wegLogicData.gefoerdertewohnung = true;
        }
        else this.wegLogicData.gefoerdertewohnung = false;
      }
    }

    let ustwohn = 0;
    let ustpark = 0;
    let wohn = 0;
    let park = 0;
    let gesamt = 0;
    let bawohn = 0;
    let bagesamt = 0;
    let baustwohn = 0;

    if (this.wegLogicData.mietObjType == 'geschaeftsraum' || this.wegLogicData.mietObjType == 'unternehmenspacht' || this.wegLogicData.mietObjType == 'parkplatz') {
      this.UStSatzMiet = 0.2;
    }
    else {
      this.UStSatzMiet = 0.1;
    }

    if (this.contractOption == 'WEG') {
      wohn = this.transformCurrencyToNumber(this.wegLogicData.HauptmietzinsWohnungEUR);
      console.log('for park');
      console.log(this.wegLogicData.HauptmietzinsParkplaetzeEUR);
      park = this.transformCurrencyToNumber(this.wegLogicData.HauptmietzinsParkplaetzeEUR);
      if(!park || park == undefined) {
        park = 0;
      }
    }
    else if (this.contractOption == 'BBL') {
      wohn = this.transformCurrencyToNumber(this.wegLogicData.HauptmietzinsWohnungEUR);
      park = 0;
    }

    if (this.wegLogicData.umsatzsteuerverpflichtung == 'Ja') {
      ustwohn = this.UStSatzMiet * wohn;
      ustpark = 0.2 * park;
    }
    else {
      ustwohn = 0;
      ustpark = 0;
    }

    gesamt = ustpark + ustwohn + park + wohn;
    console.log('gesamt');
    console.log(gesamt);
    console.log(ustpark);
    console.log(ustwohn);
    console.log(park);
    console.log(this.wegLogicData.HauptmietzinsParkplaetzeEUR);
    console.log(wohn);

    // if (this.wegLogicData.denkmalschutz == 'Nein'
    //   && this.wegLogicData.mehrals2VermietbareObj == 'Ja'
    //   && this.wegLogicData.bewilligungVor08051945 == 'Ja'
    //   && (this.wegLogicData.raeumung6monate == 'Nein' || this.wegLogicData.kategorieHelper == 'C')) {
      //BefristungsAbschlag

      if (this.contractOption == 'WEG') {
        bawohn = wohn / 0.75;

        if (this.wegLogicData.umsatzsteuerverpflichtung == 'Ja') {
          baustwohn = this.UStSatzMiet * bawohn;
        }
        else if (this.wegLogicData.umsatzsteuerverpflichtung == 'Nein') {
          baustwohn = 0;
        }

        bagesamt = baustwohn + ustpark + bawohn + park;

        this.wegLogicData.BAHauptmietzinsParkplaetzeEUR = this.looseCurrencyPipe.transform(park, 'EUR');
        this.wegLogicData.BAHauptmietzinsWohnungEUR = this.looseCurrencyPipe.transform(bawohn, 'EUR');
        this.wegLogicData.BAUStParkplaetzeEUR = this.looseCurrencyPipe.transform(ustpark, 'EUR');
        this.wegLogicData.BAUStWohnungEUR = this.looseCurrencyPipe.transform(baustwohn, 'EUR');
        this.wegLogicData.BAGesamtHauptmietzinsEURmitUSt = this.looseCurrencyPipe.transform(bagesamt, 'EUR');
      }
      if (this.contractOption == 'BBL' && this.wegLogicData.zusatzfragebj45 == 'Ja') {
        bawohn = bawohn / 0.75;

        if (this.wegLogicData.umsatzsteuerverpflichtung == 'Ja') {
          baustwohn = this.UStSatzMiet * bawohn;
        }
        else {
          baustwohn = 0;
        }

        bagesamt = baustwohn + ustpark + bawohn + park;

        this.wegLogicData.BAHauptmietzinsParkplaetzeEUR = this.looseCurrencyPipe.transform(park, 'EUR');
        this.wegLogicData.BAHauptmietzinsWohnungEUR = this.looseCurrencyPipe.transform(bawohn, 'EUR');
        this.wegLogicData.BAUStParkplaetzeEUR = this.looseCurrencyPipe.transform(ustpark, 'EUR');
        this.wegLogicData.BAUStWohnungEUR = this.looseCurrencyPipe.transform(baustwohn, 'EUR');
        this.wegLogicData.BAGesamtHauptmietzinsEURmitUSt = this.looseCurrencyPipe.transform(bagesamt, 'EUR');
      }

    // }

    this.wegLogicData.HauptmietzinsParkplaetzeEUR = this.looseCurrencyPipe.transform(park, 'EUR');
    this.wegLogicData.HauptmietzinsWohnungEUR = this.looseCurrencyPipe.transform(wohn, 'EUR');
    this.wegLogicData.UStParkplaetzeEUR = this.looseCurrencyPipe.transform(ustpark, 'EUR');
    this.wegLogicData.UStWohnungEUR = this.looseCurrencyPipe.transform(ustwohn, 'EUR');
    this.wegLogicData.GesamtHauptmietzinsEURmitUSt = this.looseCurrencyPipe.transform(gesamt, 'EUR');
  }

  private adduebertragungCLfdNr() {
    this.wegLogicData.uebertragungCLfdNr.push('');
  }

  private adduebertragungEzClnr() {
    this.wegLogicData.uebertragungEzClnr.push('');
    this.wegLogicData.uebertragungCLfdNr.push('');
  }

  private addlastenLoeschenCLfdNr() {
    this.wegLogicData['frageLastenLoeschenDritte2'].push(null);
    // console.log(this.wegLogicData.frageLastenLoeschenDritte2);
    this.wegLogicData.lastenLoeschenCLfdNr.push('');
    this.wegLogicData.lastenloeschenGrundbuch.push('');
    this.wegLogicData.lastenloeschenType.push('');
    this.LastenLoeschenPushNewBeteiligte();
  }

  private addlastenLoeschenEzCLfdNr() {
    this.wegLogicData.lastenLoeschenCLfdNr.push('');
    this.wegLogicData.lastenloeschenEzClnr.push('');
    this.wegLogicData.lastenloeschenGrundbuch.push('');
    this.wegLogicData.lastenloeschenType.push('');
    this.LastenLoeschenPushNewBeteiligte();
  }

  private removeuebertragungCLfdNr(i) {
    this.wegLogicData.uebertragungCLfdNr.splice(i, 1);
  }

  private removeuebertragungEzClnr(i) {
    this.wegLogicData.uebertragungCLfdNr.splice(i, 1);
    this.wegLogicData.uebertragungEzClnr.splice(i, 1);
  }

  private removelastenLoeschenCLfdNr(i) {
    this.wegLogicData['frageLastenLoeschenDritte2'].splice(i, 1);
    this.wegLogicData.lastenLoeschenCLfdNr.splice(i, 1);
    this.wegLogicData.lastenloeschenGrundbuch.splice(i, 1);
    this.wegLogicData.lastenloeschenType.splice(i, 1);
    this.wegLogicData.LastenLoeschenBeteiligte.splice(i, 1);
  }

  private removelastenLoeschenEzCLfdNr(i) {
    this.wegLogicData.lastenLoeschenCLfdNr.splice(i, 1);
    this.wegLogicData.lastenloeschenEzClnr.splice(i, 1);
    this.wegLogicData.lastenloeschenGrundbuch.splice(i, 1);
    this.wegLogicData.lastenloeschenType.splice(i, 1);
    this.wegLogicData.LastenLoeschenBeteiligte.splice(i, 1);
  }

  private getSonstigeRechteWEGData() {
    console.log(this.SonstigeRechteWEG)
    return this.SonstigeRechteWEG.getData();
  }

  private getSonstigeRechteWEGValid() {
    return this.SonstigeRechteWEG.getValidation();
  }

  ngOnDestroy() {
    //console.log('timer isch raus');
    // this.timerSub.unsubscribe();
    if (!this.nextClicked) this.saveDB();
  }

  ngOnInit() {
    // this.contractDataService.Page='kauf';
    // this.contractDataService.changePage({ page: 'kauf'});
    this.contractDataService.State = 2;
    this.nextClicked = false;

    this.primeType = this.contractDataService.getContractPrimeType();
    this.contractData = this.contractDataService.getContractData();
    this.contractOption = this.contractData.contactData.contractOption;
    this.kaeuferanteileOption = this.contractData.contactData.kaeuferanteile;


    this.contractData.primeType = this.primeType;
    this.primeType = this.contractData.primeType;

    let object = localStorage.getItem('object');
    if(object) {
      let obj = JSON.parse(object);
      if(obj) {
        console.log(obj);
        this.wegLogicData['ort'] = obj.ort;
        this.wegLogicData['plz'] = obj.plz;
        this.wegLogicData['strasse'] = obj.address;

        console.log(this.wegLogicData);

      }
    }

    if (this.contractData.primeType === 'schenkung') this.schenkung = true;
    else this.schenkung = false;

    if (this.contractData.primeType === 'miet') this.miet = true;
    else this.miet = false;

    if (
      this.contractData.masterData.buyersPrivate.length > 1 ||
      this.contractData.masterData.sellersPrivate.length > 1
    ) {
      this.KVzwischenAngehoerigenOptionen = ['Ja', 'Nein', 'teilweise'];
    }

    if (this.contractData.masterData.buyersPrivate.length === 0 && this.contractData.masterData.sellersPrivate.length === 0) {
      if (this.contractData.masterData.sellersEstate.length > 0) {
        this.KVzwischenAngehoerigenVisible = true;
      } else {
        this.KVzwischenAngehoerigenVisible = false;
      }
    } else {
      this.KVzwischenAngehoerigenVisible = true;
    }

    this.vertragsKosten = this.wegLogicData.kaufpreis * 0.008 * 1.2;

    const contractWegLogicData = this.contractDataService.getContractWEGLogicData();
    if (contractWegLogicData) {
      console.log(contractWegLogicData);
      this.fillFormWithData(contractWegLogicData);
    }

    // if(this.contractData) {
    //   console.log("Yes");
    //   console.log(this.contractData);
    //   // this.wegLogicData.admin = this.contractData.admin;
    // }

    this.initEPData();
    this.initRightData();
    this.initLastenLoeschenData();
    this.rightPersonList = this.contractData.masterData.sellersPrivate.concat(this.contractData.masterData.buyersPrivate);
    this.setNaheAngehorigeOptionen();

    this.rightCompanyList = this.contractData.masterData.sellersCompanyRightList.concat(this.contractData.masterData.buyersCompany);
    this.initSonstigeRechte();

    if (this.kaeuferanteileOption) {
      if (this.kaeuferanteileOption.valueOf() == "Nein") {
        this.kaeuferanteileBOOL = true;
      }
      else {
        this.kaeuferanteileBOOL = false;
      }
    }
    else {
      this.kaeuferanteileBOOL = false;
    }

    this.setLSBOOL();
    this.LastenLoeschenPushNewBeteiligte();
    this.refreshLastenLoeschenOwners(false);

    if (this.miet) {
      if (this.contractData.masterData.sellersCompany.length == 0) this.noCompSeller = true;
      else this.noCompSeller = false;
    }

    if (this.wegLogicData.bindungdatumsave) this.wegLogicData.datumRestoreBindung = new Date(this.wegLogicData.bindungdatumsave);
    if (this.wegLogicData.erstzulassungsave) this.wegLogicData.erstzulassungRestore = new Date(this.wegLogicData.erstzulassungsave);
    if (this.wegLogicData.uebergabedatumsave) this.wegLogicData.datumRestoreUebergabe = new Date(this.wegLogicData.uebergabedatumsave);

    if(this.wegLogicData.datumRestoreBindung)
      this.wegLogicData.datumRestoreBindung = this.convertToISO8601(this.wegLogicData.datumRestoreBindung);
  }

  convertToISO8601(dateString) {
    const [day, month, year] = dateString.split('.');
    const isoString = `${year}-${month}-${day}T00:00:00.000Z`;
    return isoString;
  }

  LastenLoeschenPushNewBeteiligte() {
    const data = {
      personArray: [],
      companyArray: []
    }
    this.wegLogicData.LastenLoeschenBeteiligte.push(data);
  }

  initLastenLoeschenData() {
    this.LastenLoeschenForm = this.formBuilder.group({
      LastenLoeschenPrivate: this.formBuilder.array([]),
      LastenLoeschenCompany: this.formBuilder.array([])
    });
  }

  setLSBOOL() {
    if (this.contractData.contactData.contractOption === 'BBL' || this.contractData.contactData.contractOption === 'UBL') {
      const a = parseInt(this.contractData.contactData.AnzahlLS);

      switch (a) {
        case 1:
          {
            this.LSBOOL = [true, false, false, false];
            break;
          }
        case 2:
          {
            this.LSBOOL = [true, true, false, false];
            break;
          }
        case 3:
          {
            this.LSBOOL = [true, true, true, false];
            break;
          }
        case 4:
          {
            this.LSBOOL = [true, true, true, true];
            break;
          }
      }
    }
    else this.LSBOOL = [true, false, false, false];
    console.log('Setting LS Bool', this.LSBOOL);
    this.contractDataService.setLSBOOL();
  }

  initSonstigeRechte() {
    this.sonstigeRechteOptionen.forEach(e => {
      const temp = {
        text: undefined,
        person: undefined
      };
      this.wegLogicData.sonstigeRechte[e.value] = temp;
    });
  }

  fillFormWithData(contractWegLogicData: any) {
    this.wegLogicData = { ...contractWegLogicData };
  }

  removeTextFromAusserbuecherlicheRechteDetail(event: MatCheckboxChange, index: number) {
    if (!event.checked) {
      this.wegLogicData.ausserbuecherlicheRechteDetail[index] = '';
    }
  }

  addRightOwnerComplex(buyerType): void {
    switch (buyerType) {
      case 'person': {
        this.rightsPrivateFormArray.push(this.initBuyerPrivateEmpty());
        console.log('Added Buyer');
        break;
      }
      case 'company': {
        this.rightsCompanyFormArray.push(this.initBuyerCompanyEmpty());
      }
    }
  }

  private shimEP(): void {
    if (this.wegLogicData.EP == 'halbes Top'
      && this.wegLogicData.EPHelper == 'eigentuemeruebertraghalbeanteile'
      && this.contractData.contactData.contractOption == 'WEG') {
      this.wegLogicData.EP = 'eigentuemeruebertraghalbeanteile';
      this.wegLogicData.EPPartner = undefined;
    }
  }

  private setMietKategorie(): void {
    if (this.wegLogicData.kategorieHelper) {
      switch (this.wegLogicData.kategorieHelper) {
        case 'A':
          this.wegLogicData.kategorieA = true;
          this.wegLogicData.kategorieB = false;
          this.wegLogicData.kategorieC = false;
          this.wegLogicData.kategorieD = false;
          break;
        case 'B':
          this.wegLogicData.kategorieA = false;
          this.wegLogicData.kategorieB = true;
          this.wegLogicData.kategorieC = false;
          this.wegLogicData.kategorieD = false;
          break;
        case 'C':
          this.wegLogicData.kategorieA = false;
          this.wegLogicData.kategorieB = false;
          this.wegLogicData.kategorieC = true;
          this.wegLogicData.kategorieD = false;
          break;

        case 'D':
          this.wegLogicData.kategorieA = false;
          this.wegLogicData.kategorieB = false;
          this.wegLogicData.kategorieC = false;
          this.wegLogicData.kategorieD = true;
          break;
      }
    }
  }

  saveDB() {
    try {
      this.persistFormData();
      // this.shimShare();

      if ((this.contractData.contactData.contractOption === "BBL" || this.contractData.contactData.contractOption === "UBL") && !this.miet) {
        if (this.LSBOOL[0]) {
          this.wegLogicData.lssonstigeRechte = this.LSimmodetailcomp.getSonstigeRechte();
          if (!this.wegLogicData.lssonstigeRechte.belastungsUndVeraeusserungsverbot.naheAngehoerige) {
            this.wegLogicData.lssonstigeRechte.belastungsUndVeraeusserungsverbot.naheAngehoerige = 'Nein';
          }
        }

        if (this.LSBOOL[1]) {
          this.wegLogicData.lsl2sonstigeRechte = this.LSimmodetailcomp.getl2SonstigeRechte();
          if (!this.wegLogicData.lsl2sonstigeRechte.belastungsUndVeraeusserungsverbot.naheAngehoerige) {
            this.wegLogicData.lsl2sonstigeRechte.belastungsUndVeraeusserungsverbot.naheAngehoerige = 'Nein';
          }
        }

        if (this.LSBOOL[2]) {
          this.wegLogicData.lsl3sonstigeRechte = this.LSimmodetailcomp.getl3SonstigeRechte();
          if (!this.wegLogicData.lsl3sonstigeRechte.belastungsUndVeraeusserungsverbot.naheAngehoerige) {
            this.wegLogicData.lsl3sonstigeRechte.belastungsUndVeraeusserungsverbot.naheAngehoerige = 'Nein';
          }
        }

        if (this.LSBOOL[3]) {
          this.wegLogicData.lsl4sonstigeRechte = this.LSimmodetailcomp.getl4SonstigeRechte();
          if (!this.wegLogicData.lsl4sonstigeRechte.belastungsUndVeraeusserungsverbot.naheAngehoerige) {
            this.wegLogicData.lsl4sonstigeRechte.belastungsUndVeraeusserungsverbot.naheAngehoerige = 'Nein';
          }
        }
      }
      else if (this.contractData.contactData.contractOption === "WEG" && !this.miet) {
        this.wegLogicData.sonstigeRechteWEG = this.getSonstigeRechteWEGData();
      }

      console.log(this.contractData.anteildata);
      this.contractDataService.setContractWEGLogicData(this.wegLogicData, this.contractData.anteildata);
      this.contractDataService.setAnteildata2(this.contractData.anteildata);
      console.log('Leaving Save db');

      // if (this.contractDataService.saveFromPage('weglogic')) this.openSnackBar('Die Daten wurden erfolgreich gespeichert!', 'schließen');
      // else console.log('speichern hat nicht geklappt')
      // this.openSnackBar('Das Speichern hat leider nicht geklappt!', 'schließen');

      // GURB
      // if (this.contractOption == 'UBL' || this.contractOption == 'BBL')
      // this.LSimmodetailcomp.resetShares();

    } catch (error) {
      console.log(error);
      console.log('speichern hat nicht geklappt') //this.openSnackBar('Das Speichern hat leider nicht geklappt!', 'schließen');
    }

    // const dialogRef = this.SaveDlg.open(SaveDlgComponent);
    // dialogRef.afterClosed().subscribe(
    //   data => {
    //     if (data) {

    //     }
    //     else {
    //       console.log("Abbruch, Korrekturen!");
    //     }
    //   }
    // );

    // JUNE22
    // if (this.wegLogicData.bindungdatumsave) this.wegLogicData.datumRestoreBindung = new Date(this.wegLogicData.bindungdatumsave);
    // if (this.wegLogicData.erstzulassungsave) this.wegLogicData.erstzulassungRestore = new Date(this.wegLogicData.erstzulassungsave);
    // if (this.wegLogicData.uebergabedatumsave) this.wegLogicData.datumRestoreUebergabe = new Date(this.wegLogicData.uebergabedatumsave);
  }

  shimWegKaufpreis() {
    if (this.primeType == undefined && this.contractOption == 'WEG') {
      let newval = 0;
      // 8261 no need
      if(this.wegLogicData.parkingPlaces) {
        this.wegLogicData.parkingPlaces.forEach(element => {
          newval += this.transformCurrencyToNumber(element.kaufpreisinEUR);
        });

        if (this.wegLogicData.inventar == 'Ja') {
          newval += this.transformCurrencyToNumber(this.wegLogicData.inventarWertinEUR);
        }

        this.wegLogicData.kaufpreisOhneParkUndInventar = this.currencyPipe.transform(this.transformCurrencyToNumber(this.wegLogicData.kaufpreis) - newval, 'EUR');
      }
    }
  }

  private scrollToFirstInvalidControl() {
    let firstInvalidControl: HTMLElement;
    firstInvalidControl = this.el.nativeElement.querySelector("form .ng-invalid");
    if (firstInvalidControl) {
      window.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
        behavior: "smooth"
      });
    }
  }

  private jQueryInvalidControl() {
    let firstInvalidControl: HTMLElement;
    firstInvalidControl = $("mat-form-field .ng-invalid");

    if(firstInvalidControl && $(firstInvalidControl).length) {

      let input =  $(firstInvalidControl[0]).find('input');
      console.log(input);
      console.log(input.val());

      if(input.val() != '') {
        console.log('parking', $("[formcontrolname='parkingPlaceCnt']").val());
        if($("[formcontrolname='parkingPlaceCnt']").val() == 0)
        {
          return true;
        } else {
          $([document.documentElement, document.body]).animate({
              scrollTop: $(firstInvalidControl[0]).offset().top
          }, 1000);
          return false;
        }

      } else {
        $([document.documentElement, document.body]).animate({
            scrollTop: $(firstInvalidControl[0]).offset().top
        }, 1000);
        return false;
      }

    } else {
      return true;
    }
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 15;
    return controlEl ? controlEl.getBoundingClientRect().top + window.scrollY - labelOffset : 0;
  }

  gotoBack() {
    this.persistFormData();
    this.router.navigate(['/personaldata']);
  }

  gotoNext(Form, FormIsValid: boolean) {
    this.submitted = true;
    this.saveDB();

    this.scrollToFirstInvalidControl();
    if (!(this.wegLogicData.admin.agb && this.wegLogicData.admin.datenschutz && this.wegLogicData.admin.faggVerzicht)) {
      this.openSnackBar('Bitte alles ausfüllen und jeweils eine Option auswählen.', 'schließen');
      return;
    }

    let valid: boolean;

    if (this.contractData.primeType != 'kfz') {
      if (!this.wegLogicData.KVzwischenAngehoerigen) {
        this.wegLogicData.KVzwischenAngehoerigen = 'Nein';
      }

      if (!this.wegLogicData.Anrechnung) {
        this.wegLogicData.Anrechnung = 'Nein';
      }

      if (!this.wegLogicData.denkmalschutz) {
        this.wegLogicData.denkmalschutz = 'Nein';
      }

      this.setMietKategorie();

      //Sonstige Rechte
      if ((this.contractData.contactData.contractOption === "BBL" || this.contractData.contactData.contractOption === "UBL") && !this.miet) {
        if (this.LSBOOL[0]) {
          this.wegLogicData.lssonstigeRechte = this.LSimmodetailcomp.getSonstigeRechte();
          if (!this.wegLogicData.lssonstigeRechte.belastungsUndVeraeusserungsverbot.naheAngehoerige) {
            this.wegLogicData.lssonstigeRechte.belastungsUndVeraeusserungsverbot.naheAngehoerige = 'Nein';
          }
        }

        if (this.LSBOOL[1]) {
          this.wegLogicData.lsl2sonstigeRechte = this.LSimmodetailcomp.getl2SonstigeRechte();
          if (!this.wegLogicData.lsl2sonstigeRechte.belastungsUndVeraeusserungsverbot.naheAngehoerige) {
            this.wegLogicData.lsl2sonstigeRechte.belastungsUndVeraeusserungsverbot.naheAngehoerige = 'Nein';
          }
        }

        if (this.LSBOOL[2]) {
          this.wegLogicData.lsl3sonstigeRechte = this.LSimmodetailcomp.getl3SonstigeRechte();
          if (!this.wegLogicData.lsl3sonstigeRechte.belastungsUndVeraeusserungsverbot.naheAngehoerige) {
            this.wegLogicData.lsl3sonstigeRechte.belastungsUndVeraeusserungsverbot.naheAngehoerige = 'Nein';
          }
        }

        if (this.LSBOOL[3]) {
          this.wegLogicData.lsl4sonstigeRechte = this.LSimmodetailcomp.getl4SonstigeRechte();
          if (!this.wegLogicData.lsl4sonstigeRechte.belastungsUndVeraeusserungsverbot.naheAngehoerige) {
            this.wegLogicData.lsl4sonstigeRechte.belastungsUndVeraeusserungsverbot.naheAngehoerige = 'Nein';
          }
        }

        try {
          this.setVerkaeuferanteile();
          this.setKaeuferanteile(this.contractData.contactData.kaeuferanteile);
        } catch (error) {
          this.openSnackBar(error, 'schließen');
          return;
        }
        valid = FormIsValid && this.LSimmodetailcomp.getSonstigeRechteValidation();
      }
      else if (this.contractData.contactData.contractOption === "WEG" && !this.miet) {
        this.wegLogicData.sonstigeRechteWEG = this.getSonstigeRechteWEGData();
        valid = FormIsValid && this.getSonstigeRechteWEGValid();
      }
      else valid = FormIsValid;

      console.log("valid");
      console.log(valid);

      if (this.miet) {
        this.wegLogicData.sonstigeRechteWEG = 'Nein';
        this.wegLogicData.lssonstigeRechte = 'Nein';
        this.wegLogicData.lsl2sonstigeRechte = 'Nein';
        this.wegLogicData.lsl3sonstigeRechte = 'Nein';
        this.wegLogicData.lsl4sonstigeRechte = 'Nein';

        if ((this.wegLogicData.mehrals2VermietbareObj == 'Ja' && this.wegLogicData.bewilligungVor08051945 == 'Ja')
          || (this.wegLogicData.mehrals2VermietbareObj == 'Ja' && this.wegLogicData.bewilligungVor08051945 == 'Nein' && this.wegLogicData.oeffentlicheFoerderungen == 'Ja')) {
          this.wegLogicData.betriebskostenType = 'akonto';
        }
        console.log('this.wegLogicData');
        console.log(this.wegLogicData);
        console.log(this.wegLogicData.monatlicheBetriebskosten);
        this.wegLogicData.bemessungsgrundlage = this.transformCurrencyToNumber(this.wegLogicData.GesamtHauptmietzinsEURmitUSt) + this.transformCurrencyToNumber(this.wegLogicData.monatlicheBetriebskosten);
        this.wegLogicData.bemessungsgrundlagejahr = this.wegLogicData.bemessungsgrundlage * 12;

        if (this.wegLogicData.mietdauerinmonate) {
          this.wegLogicData.bemessungsgrundlage1 = this.wegLogicData.bemessungsgrundlagejahr * this.wegLogicData.mietdauerinmonate / 12;
        }

        if (this.wegLogicData.mietdauerinjahre) {
          this.wegLogicData.bemessungsgrundlage1 = this.wegLogicData.bemessungsgrundlagejahr * this.wegLogicData.mietdauerinjahre;
        }

        this.wegLogicData.gebuehr = this.wegLogicData.bemessungsgrundlage1 * 0.01;
        this.wegLogicData.bemessungsgrundlage = this.looseCurrencyPipe.transform(this.wegLogicData.bemessungsgrundlage, 'EUR');
        this.wegLogicData.bemessungsgrundlagejahr = this.looseCurrencyPipe.transform(this.wegLogicData.bemessungsgrundlagejahr, 'EUR');
        this.wegLogicData.bemessungsgrundlage1 = this.looseCurrencyPipe.transform(this.wegLogicData.bemessungsgrundlage1, 'EUR');
        this.wegLogicData.gebuehr = this.looseCurrencyPipe.transform(this.wegLogicData.gebuehr, 'EUR');
      }

      if (!this.wegLogicData.EP) {
        this.wegLogicData.EP = 'gesamte Wohnung oder Geschäftsräumlichkeit';
      }

      this.wegLogicData.EPList = this.EPForm.value;

      if (this.contractData.contactData.contractOption === 'UBL' || (this.contractData.contactData.contractOption === 'BBL' && this.LSBOOL[1])) {
        if (this.wegLogicData.fragebauflaeche1) this.wegLogicData.fragebauflaeche = 'Ja';
        else this.wegLogicData.fragebauflaeche = 'Nein';

        if (this.wegLogicData.fragebauerwartungsflaeche1) this.wegLogicData.fragebauerwartungsflaeche = 'Ja';
        else this.wegLogicData.fragebauerwartungsflaeche = 'Nein';

        if (this.wegLogicData.fragefreiflaeche1) this.wegLogicData.fragefreiflaeche = 'Ja';
        else this.wegLogicData.fragefreiflaeche = 'Nein';

        if (this.wegLogicData.fragelufflaeche1) this.wegLogicData.fragelufflaeche = 'Ja';
        else this.wegLogicData.fragelufflaeche = 'Nein';

        if ((!(this.wegLogicData.fragebauflaeche1 || this.wegLogicData.fragelufflaeche1
          || this.wegLogicData.fragefreiflaeche1 || this.wegLogicData.fragebauerwartungsflaeche1)) && !this.miet) {
          valid = false;
        }
      }

      // GURB
      if (this.primeType === 'miet') {
        console.log('MIET');
        var business = ['geschaeftsraum','unternehmenspacht','dienstwohnung','sonstigeflaeche','lwpachtvertrag'];
        var privatee = ['wohnung','zweitwohnsitz','parkplatz'];

        if(business.indexOf(this.wegLogicData.mietObjType) !== -1) {
          // Business because buyer company buyer has been selected on master data
          console.log('BUSINESS');
          this.wegLogicData.kaufpreisnetto = 189;
        } else {
          // Private, because there is no company buyer selected on master data
          console.log('BUYER');
          this.wegLogicData.kaufpreisnetto = 99;
        }
      }
      else if (this.primeType === 'schenkung') {
        console.log('schenkung');
        this.wegLogicData.kaufpreisnetto = 1.800;
      }
      else {
        console.log('KAUF');
        console.log(this.wegLogicData.kaufpreis);
        if (this.wegLogicData.kaufpreis != undefined) {
          if (this.transformCurrencyToNumber(this.wegLogicData.kaufpreis) > 400000) {
            this.wegLogicData.kaufpreisnetto = this.transformCurrencyToNumber(this.wegLogicData.kaufpreis) * 0.005;
          }
          else {
            this.wegLogicData.kaufpreisnetto = 2000;
          }
        }
      }

      if (this.wegLogicData.kaufpreisnetto !== undefined) {
        this.wegLogicData.kaufpreisbrutto = this.wegLogicData.kaufpreisnetto * 1.2;
        this.wegLogicData.ust = this.wegLogicData.kaufpreisbrutto - this.wegLogicData.kaufpreisnetto;

        this.wegLogicData.ust = this.currencyPipe.transform(this.wegLogicData.ust, 'EUR');
        this.wegLogicData.kaufpreisnetto = this.currencyPipe.transform(this.wegLogicData.kaufpreisnetto, 'EUR');
        this.wegLogicData.kaufpreisbrutto = this.currencyPipe.transform(this.wegLogicData.kaufpreisbrutto, 'EUR');
      }

      if (this.contractData.contactData.contractOption === 'UBL' || this.contractData.contactData.contractOption === 'BBL') {
        console.log(this.LSimmodetailcomp.getValid());
        valid = valid && this.LSimmodetailcomp.getValid();
      }

      if (this.contractData.contactData.contractOption === 'WEG') {
        if(this.immodetailComp)
        valid = valid && this.immodetailComp.getValid();

        if(this.immodetailCompMietWeg)
        valid = valid && this.immodetailCompMietWeg.getValid();
      }
    }

    if (this.contractData.primeType == 'kfz') {
      valid = FormIsValid;
    }

    valid = this.jQueryInvalidControl();

    console.log("valid");
    console.log(valid);


    if (valid) {
      this.openVorschau();
    }
    else {
      this.scrollToFirstInvalidControl();
      this.openSnackBar('Bitte alles ausfüllen und jeweils eine Option auswählen.', 'schließen');
    }
    // this.router.navigate(['/finish']);
  }


  private mkm(a) {
    console.log(a);
  }

  private shimPost() {
    this.dateHelper();

    if (this.wegLogicData.kaufpreis) {
      const kPInW = this.priceInWords(this.wegLogicData.kaufpreis);
      this.wegLogicData.kaufpreisInWorten = this.titleCase(kPInW).trim();
    }

    if (this.contractData.contactData.contractOption === 'WEG') {
      this.fractionHelperWEG();
      this.shimBLNR();
    }

    if (
      this.contractData.contactData.contractOption === 'BBL' ||
      this.contractData.contactData.contractOption === 'UBL'
    ) {
      if (this.LSBOOL[0]) {
        this.fractionHelperUBBLLS1();
        this.shimUBBLAnteileLS1();
      }
      if (this.LSBOOL[1]) {
        this.fractionHelperUBBLLS2();
        this.shimUBBLAnteileLS2();
      }
      if (this.LSBOOL[2]) {
        this.fractionHelperUBBLLS3();
        this.shimUBBLAnteileLS3();
      }
      if (this.LSBOOL[3]) {
        this.fractionHelperUBBLLS4();
        this.shimUBBLAnteileLS4();
      }
    }

    this.shimKG();
    this.metaDataHelper();
    console.log('Posting data');
    console.log(this.contractData);

    this.setGrundstuecksnummer();

    // this.shimEstate();
    this.shimSonstigeRechte();
    this.shimLastenLoeschen();
    this.shimDritte();
  }

  private setGrundstuecksnummer() {
    if(this.immodetailComp)
    this.wegLogicData.immodetail = this.immodetailComp.immodetail.value;

    if(this.immodetailCompMietWeg) {
      let immo = this.immodetailCompMietWeg.immodetail.value;
      console.log(immo);

      this.wegLogicData.immodetail = immo;
    }

    if(this.LSimmodetailcomp)
    this.wegLogicData.immodetail = this.LSimmodetailcomp.immodata.get('immodetail').value;

    console.log('fkhdsjjfsdfs f ****');
    console.log(this.wegLogicData.immodetail);

    if (this.LSBOOL[0]) {
      this.wegLogicData.immodetail.grundstuecksnummer = undefined;
      this.wegLogicData.immodetail.grundstuecksnummer = [];

      this.wegLogicData.immodetail.grundstuecksnummerAr.forEach(gst => {
        this.wegLogicData.immodetail.grundstuecksnummer.push(gst.grundstuecksnummer);
      });
      this.shimPrivDateLS1();
    }

    if (this.LSBOOL[1]) {
      this.wegLogicData.l2immodetail.l2grundstuecksnummer = undefined;
      this.wegLogicData.l2immodetail.l2grundstuecksnummer = [];

      this.wegLogicData.l2immodetail.l2grundstuecksnummerAr.forEach(gst => {
        this.wegLogicData.l2immodetail.l2grundstuecksnummer.push(gst.l2grundstuecksnummer);
      });

      this.shimPrivDateLS2();
    }

    if (this.LSBOOL[2]) {
      this.wegLogicData.l3immodetail.l3grundstuecksnummer = undefined;
      this.wegLogicData.l3immodetail.l3grundstuecksnummer = [];

      this.wegLogicData.l3immodetail.l3grundstuecksnummerAr.forEach(gst => {
        this.wegLogicData.l3immodetail.l3grundstuecksnummer.push(gst.l3grundstuecksnummer);
      });

      this.shimPrivDateLS3();
    }

    if (this.LSBOOL[3]) {
      this.wegLogicData.l4immodetail.l4grundstuecksnummer = undefined;
      this.wegLogicData.l4immodetail.l4grundstuecksnummer = [];

      this.wegLogicData.l4immodetail.l4grundstuecksnummerAr.forEach(gst => {
        this.wegLogicData.l4immodetail.l4grundstuecksnummer.push(gst.l4grundstuecksnummer);
      });

      this.shimPrivDateLS4();
    }
    this.contractDataService.setContractWEGLogicData(this.wegLogicData, this.contractData.anteildata);
  }

  //#region Helper für SHIM
  private shimDritte() {
    this.contractData.masterData.dritte.rightPersonList.forEach(element => {
      console.log('Convert dritter');
      const a = element.formatedBirthday;
      element.birthday = this.datepipe.transform(a as string, 'yyyy-MM-dd');
    });
  }

  // private shimEstate() {
  //   if (this.contractData.contactData.estate) {
  //     console.log("shimEstate");
  //     this.contractData.anteildata.l1.seller.sellersCompany.forEach(element => {
  //       if (element.estate) {
  //         console.log('Convert Estate Seller');
  //         const a = element.companiesRegisterNumber;
  //         element.companiesRegisterNumber = this.datepipe.transform(a as string, 'yyyy-MM-dd');
  //         const b = element.companyName;
  //         const c = element.address;
  //         element.companyName = 'Verlassenschaft nach ' + b;
  //         element.address = 'zuletzt wohnhaft in ' + c;
  //       }
  //       else console.log('NOT Estate');
  //     });

  //     if (this.LSBOOL[1]) {
  //       this.contractData.anteildata.l2.seller.sellersCompany.forEach(element => {
  //         if (element.estate) {
  //           console.log('Convert Estate Seller');
  //           const a = element.companiesRegisterNumber;
  //           element.companiesRegisterNumber = this.datepipe.transform(a as string, 'yyyy-MM-dd');
  //           const b = element.companyName;
  //           const c = element.address;
  //           element.companyName = 'Verlassenschaft nach ' + b;
  //           element.address = 'zuletzt wohnhaft in ' + c;
  //         }
  //         else console.log('NOT Estate');
  //       });
  //     }

  //     if (this.LSBOOL[2]) {
  //       this.contractData.anteildata.l3.seller.sellersCompany.forEach(element => {
  //         if (element.estate) {
  //           console.log('Convert Estate Seller');
  //           const a = element.companiesRegisterNumber;
  //           element.companiesRegisterNumber = this.datepipe.transform(a as string, 'yyyy-MM-dd');
  //           const b = element.companyName;
  //           const c = element.address;
  //           element.companyName = 'Verlassenschaft nach ' + b;
  //           element.address = 'zuletzt wohnhaft in ' + c;
  //         }
  //         else console.log('NOT Estate');
  //       });
  //     }

  //     if (this.LSBOOL[3]) {
  //       this.contractData.anteildata.l4.seller.sellersCompany.forEach(element => {
  //         if (element.estate) {
  //           console.log('Convert Estate Seller');
  //           const a = element.companiesRegisterNumber;
  //           element.companiesRegisterNumber = this.datepipe.transform(a as string, 'yyyy-MM-dd');
  //           const b = element.companyName;
  //           const c = element.address;
  //           element.companyName = 'Verlassenschaft nach ' + b;
  //           element.address = 'zuletzt wohnhaft in ' + c;
  //         }
  //         else console.log('NOT estate');
  //       });
  //     }
  //   }
  //   else console.log('N0 Estate');
  // }

  private titleCase(str) {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }

  // Linus hat im Word Booleans für vorkaufsrecht, Wiederkaufts und BuV -> deshalb muss es auf die folgende Struktur angepasst werden
  private shimSonstigeRechte() {

    if (this.miet) return;
    if (this.contractOption == "WEG") {
      if (this.wegLogicData.sonstigeRechteWEG) {
        this.wegLogicData.sub = {
          belastungsundveraeusserungsverbot: undefined,
          belastungsverbot: undefined,
          veraeusserungsverbot: undefined,
          vorkaufsrecht: {},
          vorkaufsrechtfueralleveraeusserungsarten: undefined,
          wiederkaufsrecht: {}
        };

        if (this.wegLogicData.sonstigeRechteWEG.belastungsUndVeraeusserungsverbot.option === 'belastungUndVerauesserung') {
          this.wegLogicData.sub.belastungsundveraeusserungsverbot = true;
        }

        if (this.wegLogicData.sonstigeRechteWEG.belastungsUndVeraeusserungsverbot.option === 'belastung') {
          this.wegLogicData.sub.belastungsverbot = true;
        }

        if (this.wegLogicData.sonstigeRechteWEG.belastungsUndVeraeusserungsverbot.option === 'veraeusserung') {
          this.wegLogicData.sub.veraeusserungsverbot = true;
        }

        if (this.wegLogicData.sonstigeRechteWEG.vorkaufsrecht.option === '1072abgb') {
          this.wegLogicData.sub.vorkaufsrecht = true;
        }

        if (this.wegLogicData.sonstigeRechteWEG.vorkaufsrecht.option === 'alle') {
          this.wegLogicData.sub.vorkaufsrechtfueralleveraeusserungsarten = true;
        }

        if (this.wegLogicData.sonstigeRechteWEG.vorkaufsrecht.preisoption === 'fixpreis') {
          this.wegLogicData.sub.vorkaufsrecht.fixpreis = true;
        }

        if (this.wegLogicData.sonstigeRechteWEG.vorkaufsrecht.preisoption === 'schaetzpreis') {
          this.wegLogicData.sub.vorkaufsrecht.schaetzpreis = true;
        }

        if (this.wegLogicData.sonstigeRechteWEG.wiederkaufsrecht.preisoption === 'fixpreis') {
          this.wegLogicData.sub.wiederkaufsrecht.fixpreis = true;
        }

        if (this.wegLogicData.sonstigeRechteWEG.wiederkaufsrecht.preisoption === 'schaetzpreis') {
          this.wegLogicData.sub.wiederkaufsrecht.schaetzpreis = true;
        }

        if (this.wegLogicData.sonstigeRechteWEG.vorkaufsrecht.preisinEUR) {
          const v = this.wegLogicData.sonstigeRechteWEG.vorkaufsrecht.preisinEUR;
          try {
            this.wegLogicData.sonstigeRechteWEG.vorkaufsrecht.preisinEUR = this.currencyPipe.transform(v, 'EUR');
          }
          catch(e) {
            console.log('Already');
          }
        }

        if (this.wegLogicData.sonstigeRechteWEG.wiederkaufsrecht.preisinEUR) {
          const w = this.wegLogicData.sonstigeRechteWEG.wiederkaufsrecht.preisinEUR;
          try {
            this.wegLogicData.sonstigeRechteWEG.wiederkaufsrecht.preisinEUR = this.currencyPipe.transform(w, 'EUR');
          }
          catch(e) {
            console.log('Already');
          }
        }
      }
    }
    if (this.contractOption == "UBL" || this.contractOption == "BBL") {
      if (this.LSBOOL[0] && this.wegLogicData.lssonstigeRechte) {
        this.wegLogicData.sub = {
          belastungsundveraeusserungsverbot: undefined,
          belastungsverbot: undefined,
          veraeusserungsverbot: undefined,
          vorkaufsrecht: {},
          vorkaufsrechtfueralleveraeusserungsarten: undefined,
          wiederkaufsrecht: {}
        };

        if (this.wegLogicData.lssonstigeRechte.belastungsUndVeraeusserungsverbot.option === 'belastungUndVerauesserung') {
          this.wegLogicData.sub.belastungsundveraeusserungsverbot = true;
        }

        if (this.wegLogicData.lssonstigeRechte.belastungsUndVeraeusserungsverbot.option === 'belastung') {
          this.wegLogicData.sub.belastungsverbot = true;
        }

        if (this.wegLogicData.lssonstigeRechte.belastungsUndVeraeusserungsverbot.option === 'veraeusserung') {
          this.wegLogicData.sub.veraeusserungsverbot = true;
        }

        if (this.wegLogicData.lssonstigeRechte.vorkaufsrecht.option === '1072abgb') {
          this.wegLogicData.sub.vorkaufsrecht = true;
        }

        if (this.wegLogicData.lssonstigeRechte.vorkaufsrecht.option === 'alle') {
          this.wegLogicData.sub.vorkaufsrechtfueralleveraeusserungsarten = true;
        }

        if (this.wegLogicData.lssonstigeRechte.vorkaufsrecht.preisoption === 'fixpreis') {
          this.wegLogicData.sub.vorkaufsrecht.fixpreis = true;
        }

        if (this.wegLogicData.lssonstigeRechte.vorkaufsrecht.preisoption === 'schaetzpreis') {
          this.wegLogicData.sub.vorkaufsrecht.schaetzpreis = true;
        }

        if (this.wegLogicData.lssonstigeRechte.wiederkaufsrecht.preisoption === 'fixpreis') {
          this.wegLogicData.sub.wiederkaufsrecht.fixpreis = true;
        }

        if (this.wegLogicData.lssonstigeRechte.wiederkaufsrecht.preisoption === 'schaetzpreis') {
          this.wegLogicData.sub.wiederkaufsrecht.schaetzpreis = true;
        }

        if (this.wegLogicData.lssonstigeRechte.vorkaufsrecht.preisinEUR) {
          const v = this.wegLogicData.lssonstigeRechte.vorkaufsrecht.preisinEUR;
          this.wegLogicData.lssonstigeRechte.vorkaufsrecht.preisinEUR = this.currencyPipe.transform(v, 'EUR');
        }

        if (this.wegLogicData.lssonstigeRechte.wiederkaufsrecht.preisinEUR) {
          const w = this.wegLogicData.lssonstigeRechte.wiederkaufsrecht.preisinEUR;
          this.wegLogicData.lssonstigeRechte.wiederkaufsrecht.preisinEUR = this.currencyPipe.transform(w, 'EUR');
        }
      }

      if (this.LSBOOL[1] && this.wegLogicData.lsl2sonstigeRechte) {
        this.wegLogicData.l2sub = {
          belastungsundveraeusserungsverbot: undefined,
          belastungsverbot: undefined,
          veraeusserungsverbot: undefined,
          vorkaufsrecht: {},
          vorkaufsrechtfueralleveraeusserungsarten: undefined,
          wiederkaufsrecht: {}
        };

        if (this.wegLogicData.lsl2sonstigeRechte.belastungsUndVeraeusserungsverbot.option === 'belastungUndVerauesserung') {
          this.wegLogicData.l2sub.belastungsundveraeusserungsverbot = true;
        }

        if (this.wegLogicData.lsl2sonstigeRechte.belastungsUndVeraeusserungsverbot.option === 'belastung') {
          this.wegLogicData.l2sub.belastungsverbot = true;
        }

        if (this.wegLogicData.lsl2sonstigeRechte.belastungsUndVeraeusserungsverbot.option === 'veraeusserung') {
          this.wegLogicData.l2sub.veraeusserungsverbot = true;
        }

        if (this.wegLogicData.lsl2sonstigeRechte.vorkaufsrecht.option === '1072abgb') {
          this.wegLogicData.l2sub.vorkaufsrecht = true;
        }

        if (this.wegLogicData.lsl2sonstigeRechte.vorkaufsrecht.option === 'alle') {
          this.wegLogicData.l2sub.vorkaufsrechtfueralleveraeusserungsarten = true;
        }

        if (this.wegLogicData.lsl2sonstigeRechte.vorkaufsrecht.preisoption === 'fixpreis') {
          this.wegLogicData.l2sub.vorkaufsrecht.fixpreis = true;
        }

        if (this.wegLogicData.lsl2sonstigeRechte.vorkaufsrecht.preisoption === 'schaetzpreis') {
          this.wegLogicData.l2sub.vorkaufsrecht.schaetzpreis = true;
        }

        if (this.wegLogicData.lsl2sonstigeRechte.wiederkaufsrecht.preisoption === 'fixpreis') {
          this.wegLogicData.l2sub.wiederkaufsrecht.fixpreis = true;
        }

        if (this.wegLogicData.lsl2sonstigeRechte.wiederkaufsrecht.preisoption === 'schaetzpreis') {
          this.wegLogicData.l2sub.wiederkaufsrecht.schaetzpreis = true;
        }

        if (this.wegLogicData.lsl2sonstigeRechte.vorkaufsrecht.preisinEUR) {
          const v = this.wegLogicData.lsl2sonstigeRechte.vorkaufsrecht.preisinEUR;
          this.wegLogicData.lsl2sonstigeRechte.vorkaufsrecht.preisinEUR = this.currencyPipe.transform(v, 'EUR');
        }

        if (this.wegLogicData.lsl2sonstigeRechte.wiederkaufsrecht.preisinEUR) {
          const w = this.wegLogicData.lsl2sonstigeRechte.wiederkaufsrecht.preisinEUR;
          this.wegLogicData.lsl2sonstigeRechte.wiederkaufsrecht.preisinEUR = this.currencyPipe.transform(w, 'EUR');
        }
      }

      if (this.LSBOOL[2] && this.wegLogicData.lsl3sonstigeRechte) {
        this.wegLogicData.l3sub = {
          belastungsundveraeusserungsverbot: undefined,
          belastungsverbot: undefined,
          veraeusserungsverbot: undefined,
          vorkaufsrecht: {},
          vorkaufsrechtfueralleveraeusserungsarten: undefined,
          wiederkaufsrecht: {}
          };

        if (this.wegLogicData.lsl3sonstigeRechte.belastungsUndVeraeusserungsverbot.option === 'belastungUndVerauesserung') {
          this.wegLogicData.l3sub.belastungsundveraeusserungsverbot = true;
        }

        if (this.wegLogicData.lsl3sonstigeRechte.belastungsUndVeraeusserungsverbot.option === 'belastung') {
          this.wegLogicData.l3sub.belastungsverbot = true;
        }

        if (this.wegLogicData.lsl3sonstigeRechte.belastungsUndVeraeusserungsverbot.option === 'veraeusserung') {
          this.wegLogicData.l3sub.veraeusserungsverbot = true;
        }

        if (this.wegLogicData.lsl3sonstigeRechte.vorkaufsrecht.option === '1072abgb') {
          this.wegLogicData.l3sub.vorkaufsrecht = true;
        }

        if (this.wegLogicData.lsl3sonstigeRechte.vorkaufsrecht.option === 'alle') {
          this.wegLogicData.l3sub.vorkaufsrechtfueralleveraeusserungsarten = true;
        }

        if (this.wegLogicData.lsl3sonstigeRechte.vorkaufsrecht.preisoption === 'fixpreis') {
          this.wegLogicData.l3sub.vorkaufsrecht.fixpreis = true;
        }

        if (this.wegLogicData.lsl3sonstigeRechte.vorkaufsrecht.preisoption === 'schaetzpreis') {
          this.wegLogicData.l3sub.vorkaufsrecht.schaetzpreis = true;
        }

        if (this.wegLogicData.lsl3sonstigeRechte.wiederkaufsrecht.preisoption === 'fixpreis') {
          this.wegLogicData.l3sub.wiederkaufsrecht.fixpreis = true;
        }

        if (this.wegLogicData.lsl3sonstigeRechte.wiederkaufsrecht.preisoption === 'schaetzpreis') {
          this.wegLogicData.l3sub.wiederkaufsrecht.schaetzpreis = true;
        }

        if (this.wegLogicData.lsl3sonstigeRechte.vorkaufsrecht.preisinEUR) {
          const v = this.wegLogicData.lsl3sonstigeRechte.vorkaufsrecht.preisinEUR;
          this.wegLogicData.lsl3sonstigeRechte.vorkaufsrecht.preisinEUR = this.currencyPipe.transform(v, 'EUR');
        }

        if (this.wegLogicData.lsl3sonstigeRechte.wiederkaufsrecht.preisinEUR) {
          const w = this.wegLogicData.lsl3sonstigeRechte.wiederkaufsrecht.preisinEUR;
          this.wegLogicData.lsl3sonstigeRechte.wiederkaufsrecht.preisinEUR = this.currencyPipe.transform(w, 'EUR');
        }
      }

      if (this.LSBOOL[3] && this.wegLogicData.lsl4sonstigeRechte) {
        this.wegLogicData.l4sub = {
          belastungsundveraeusserungsverbot: undefined,
          belastungsverbot: undefined,
          veraeusserungsverbot: undefined,
          vorkaufsrecht: {},
          vorkaufsrechtfueralleveraeusserungsarten: undefined,
          wiederkaufsrecht: {}
        };

        if (this.wegLogicData.lsl4sonstigeRechte.belastungsUndVeraeusserungsverbot.option === 'belastungUndVerauesserung') {
          this.wegLogicData.l4sub.belastungsundveraeusserungsverbot = true;
        }

        if (this.wegLogicData.lsl4sonstigeRechte.belastungsUndVeraeusserungsverbot.option === 'belastung') {
          this.wegLogicData.l4sub.belastungsverbot = true;
        }

        if (this.wegLogicData.lsl4sonstigeRechte.belastungsUndVeraeusserungsverbot.option === 'veraeusserung') {
          this.wegLogicData.l4sub.veraeusserungsverbot = true;
        }

        if (this.wegLogicData.lsl4sonstigeRechte.vorkaufsrecht.option === '1072abgb') {
          this.wegLogicData.l4sub.vorkaufsrecht = true;
        }

        if (this.wegLogicData.lsl4sonstigeRechte.vorkaufsrecht.option === 'alle') {
          this.wegLogicData.l4sub.vorkaufsrechtfueralleveraeusserungsarten = true;
        }

        if (this.wegLogicData.lsl4sonstigeRechte.vorkaufsrecht.preisoption === 'fixpreis') {
          this.wegLogicData.l4sub.vorkaufsrecht.fixpreis = true;
        }

        if (this.wegLogicData.lsl4sonstigeRechte.vorkaufsrecht.preisoption === 'schaetzpreis') {
          this.wegLogicData.l4sub.vorkaufsrecht.schaetzpreis = true;
        }

        if (this.wegLogicData.lsl4sonstigeRechte.wiederkaufsrecht.preisoption === 'fixpreis') {
          this.wegLogicData.l4sub.wiederkaufsrecht.fixpreis = true;
        }

        if (this.wegLogicData.lsl4sonstigeRechte.wiederkaufsrecht.preisoption === 'schaetzpreis') {
          this.wegLogicData.l4sub.wiederkaufsrecht.schaetzpreis = true;
        }

        if (this.wegLogicData.lsl4sonstigeRechte.vorkaufsrecht.preisinEUR) {
          const v = this.wegLogicData.lsl4sonstigeRechte.vorkaufsrecht.preisinEUR;
          this.wegLogicData.lsl4sonstigeRechte.vorkaufsrecht.preisinEUR = this.currencyPipe.transform(v, 'EUR');
        }

        if (this.wegLogicData.lsl4sonstigeRechte.wiederkaufsrecht.preisinEUR) {
          const w = this.wegLogicData.lsl4sonstigeRechte.wiederkaufsrecht.preisinEUR;
          this.wegLogicData.lsl4sonstigeRechte.wiederkaufsrecht.preisinEUR = this.currencyPipe.transform(w, 'EUR');
        }
      }
    }
  }


  private dateHelper() {
    if (this.contractData.masterData.buyersPrivate) {
      this.contractData.masterData.buyersPrivate.forEach(element => {
        const a = element.formatedBirthday;
        element.birthday = this.datepipe.transform(a as string, 'yyyy-MM-dd');
      });
    }

    if (this.wegLogicData.EPList.buyersPrivate) {
      if (this.wegLogicData.EPList.buyersPrivate) {
        this.wegLogicData.EPList.buyersPrivate.forEach(element => {
          const a = element.formatedBirthday;
          element.birthday = this.datepipe.transform(a as string, 'yyyy-MM-dd');
        });
      }
    }

    if (!this.miet) {
      // Array zum bzgl Datum drüberiterieren
      const RechteArten = ['wohnrecht', 'fruchtgenussrecht', 'belastungsUndVeraeusserungsverbot', 'vorkaufsrecht', 'wiederkaufsrecht'];
      RechteArten.forEach(right => {
        if (this.LSBOOL[0] && this.wegLogicData.lssonstigeRechte) {
          if (this.wegLogicData.lssonstigeRechte[right].personArray) {
            this.wegLogicData.lssonstigeRechte[right].personArray.forEach(element => {
              const a = element.formatedBirthday;
              element.birthday = this.datepipe.transform(a as string, 'yyyy-MM-dd');
            });
          }

          // if (this.wegLogicData.lssonstigeRechte[right].companyArray) {
          //   console.log('Transform buyersPrivate');
          //   this.wegLogicData.lssonstigeRechte[right].companyArray.forEach(element => {
          //     console.log('Convert Private BUyer');
          //     const a = element.formatedBirthday;
          //     element.birthday = this.datepipe.transform(a as string, 'yyyy-MM-dd');
          //   });
          // }
        }

        if (this.LSBOOL[1] && this.wegLogicData.lsl2sonstigeRechte) {
          if (this.wegLogicData.lsl2sonstigeRechte[right].personArray) {
            console.log('Transform buyersPrivate');
            this.wegLogicData.lsl2sonstigeRechte[right].personArray.forEach(element => {
              console.log('Convert Private BUyer');
              const a = element.formatedBirthday;
              element.birthday = this.datepipe.transform(a as string, 'yyyy-MM-dd');
            });
          }

          // if (this.wegLogicData.lsl2sonstigeRechte[right].companyArray) {
          //   console.log('Transform buyersPrivate');
          //   this.wegLogicData.lsl2sonstigeRechte[right].companyArray.forEach(element => {
          //     console.log('Convert Private BUyer');
          //     const a = element.formatedBirthday;
          //     element.birthday = this.datepipe.transform(a as string, 'yyyy-MM-dd');
          //   });
          // }
        }

        if (this.LSBOOL[2] && this.wegLogicData.lsl3sonstigeRechte) {
          if (this.wegLogicData.lsl3sonstigeRechte[right].personArray) {
            console.log('Transform buyersPrivate');
            this.wegLogicData.lsl3sonstigeRechte[right].personArray.forEach(element => {
              console.log('Convert Private BUyer');
              const a = element.formatedBirthday;
              element.birthday = this.datepipe.transform(a as string, 'yyyy-MM-dd');
            });
          }

          // if (this.wegLogicData.lsl3sonstigeRechte[right].companyArray) {
          //   console.log('Transform buyersPrivate');
          //   this.wegLogicData.lsl3sonstigeRechte[right].companyArray.forEach(element => {
          //     console.log('Convert Private BUyer');
          //     const a = element.formatedBirthday;
          //     element.birthday = this.datepipe.transform(a as string, 'yyyy-MM-dd');
          //   });
          // }
        }

        if (this.LSBOOL[3] && this.wegLogicData.lsl4sonstigeRechte) {
          if (this.wegLogicData.lsl4sonstigeRechte[right].personArray) {
            this.wegLogicData.lsl4sonstigeRechte[right].personArray.forEach(element => {
              const a = element.formatedBirthday;
              element.birthday = this.datepipe.transform(a as string, 'yyyy-MM-dd');
            });
          }

          // if (this.wegLogicData.lsl4sonstigeRechte[right].companyArray) {
          //   console.log('Transform buyersPrivate');
          //   this.wegLogicData.lsl4sonstigeRechte[right].companyArray.forEach(element => {
          //     console.log('Convert Private BUyer');
          //     const a = element.formatedBirthday;
          //     element.birthday = this.datepipe.transform(a as string, 'yyyy-MM-dd');
          //   });
          // }
        }

        if (this.wegLogicData.sonstigeRechteWEG) {
          if (this.wegLogicData.sonstigeRechteWEG[right].personArray) {
            this.wegLogicData.sonstigeRechteWEG[right].personArray.forEach(element => {
              const a = element.formatedBirthday;
              element.birthday = this.datepipe.transform(a as string, 'yyyy-MM-dd');
            });
          }
        }
      });

      if (this.wegLogicData.sonstigeRechteWEG) {
        if (this.wegLogicData.sonstigeRechteWEG.rightPersonArray) {
          this.wegLogicData.sonstigeRechteWEG.rightPersonArray.forEach(element => {
            const a = element.formatedBirthday;
            element.birthday = this.datepipe.transform(a as string, 'yyyy-MM-dd');
          });
        }
      }

      if (this.contractData.masterData.sellersPrivate) {
        this.contractData.masterData.sellersPrivate.forEach(element => {
          const a = element.formatedBirthday;
          element.birthday = this.datepipe.transform(a as string, 'yyyy-MM-dd');
        });
      }
    }

    // JUNE22
    if (this.wegLogicData.datumRestoreUebergabe) {
      const b = this.wegLogicData.datumRestoreUebergabe;
      this.wegLogicData.datumRestoreUebergabe = this.datepipe.transform(b, 'dd.MM.yyyy');
      this.wegLogicData.uebergabeDatum = this.datepipe.transform(b, 'dd.MM.yyyy');
      this.wegLogicData.uebergabedatumsave = (b as Date).toJSON();
    }
    // if (this.wegLogicData.datumRestoreBindung) {
    //   const c = this.wegLogicData.datumRestoreBindung;
    //   this.wegLogicData.datumRestoreBindung = this.datepipe.transform(c, 'dd.MM.yyyy');
    //   this.wegLogicData.bindungDatum = this.datepipe.transform(c, 'yyyy-MM-dd');
    //   this.wegLogicData.bindungdatumsave = (c as Date).toJSON();
    // }
  }

  private shimBLNR() {
    if (this.wegLogicData.immodetail.bLaufendeNrAr[0]) {
      this.wegLogicData.immodetail.bLaufendeNr = this.wegLogicData.immodetail.bLaufendeNrAr[0].bLaufendeNr;
    }

    if (this.wegLogicData.immodetail.bLaufendeNrAr[1]) {
      this.wegLogicData.immodetail.bLaufendeNr2 = this.wegLogicData.immodetail.bLaufendeNrAr[1].bLaufendeNr;
    }
  }

  private shimUBBLAnteileLS1() {
    let tempCounter = 0;
    const alleanteile = [];
    const alleblnr = [];
    this.contractData.anteildata.l1.seller.sellersPrivate.forEach((seller) => {
      const anteile = [];
      const blnr = [];

      let anteilTemp;
      let blnrTemp;

      if (tempCounter === 0) {
        anteilTemp = 'anteil';
        blnrTemp = 'bLaufendeNr';
      } else {
        anteilTemp = 'anteil' + tempCounter;
        blnrTemp = 'bLaufendeNr' + tempCounter;
      }

      tempCounter++;
      seller.shareAr.forEach(item => {
        anteile.push(item.anteil);
        blnr.push(item.BLNR);
        alleanteile.push(item.anteil);
        alleblnr.push(item.BLNR);
      });

      this.wegLogicData.immodetail[anteilTemp] = anteile;
      this.wegLogicData.immodetail[blnrTemp] = blnr;
    });

    this.contractData.anteildata.l1.seller.sellersCompany.forEach((seller) => {
      const anteile = [];
      const blnr = [];

      let anteilTemp;
      let blnrTemp;

      if (tempCounter === 0) {
        anteilTemp = 'anteil';
        blnrTemp = 'bLaufendeNr';
      } else {
        anteilTemp = 'anteil' + tempCounter;
        blnrTemp = 'bLaufendeNr' + tempCounter;
      }

      tempCounter++;

      seller.shareAr.forEach(item => {
        anteile.push(item.anteil);
        blnr.push(item.BLNR);
        alleanteile.push(item.anteil);
        alleblnr.push(item.BLNR);
      });

      this.wegLogicData.immodetail[anteilTemp] = anteile;
      this.wegLogicData.immodetail[blnrTemp] = blnr;
    });
    this.wegLogicData.immodetail.alleAnteile = alleanteile;
    this.wegLogicData.immodetail.alleBlnr = alleblnr;
  }

  private shimUBBLAnteileLS2() {
    let tempCounter = 0;
    const alleanteile = [];
    const alleblnr = [];
    this.contractData.anteildata.l2.seller.sellersPrivate.forEach((seller) => {
      const anteile = [];
      const blnr = [];

      let anteilTemp;
      let blnrTemp;

      if (tempCounter === 0) {
        anteilTemp = 'anteil';
        blnrTemp = 'bLaufendeNr';
      } else {
        anteilTemp = 'anteil' + tempCounter;
        blnrTemp = 'bLaufendeNr' + tempCounter;
      }

      tempCounter++;


      seller.shareAr.forEach(item => {
        anteile.push(item.anteil);
        blnr.push(item.BLNR);
        alleanteile.push(item.anteil);
        alleblnr.push(item.BLNR);
      });

      this.wegLogicData.l2immodetail[anteilTemp] = anteile;
      this.wegLogicData.l2immodetail[blnrTemp] = blnr;
    });

    this.contractData.anteildata.l2.seller.sellersCompany.forEach((seller) => {
      const anteile = [];
      const blnr    = [];

      let anteilTemp;
      let blnrTemp;

      if (tempCounter === 0) {
        anteilTemp = 'anteil';
        blnrTemp = 'bLaufendeNr';
      } else {
        anteilTemp = 'anteil' + tempCounter;
        blnrTemp = 'bLaufendeNr' + tempCounter;
      }
      tempCounter++;

      seller.shareAr.forEach(item => {
        anteile.push(item.anteil);
        blnr.push(item.BLNR);
        alleanteile.push(item.anteil);
        alleblnr.push(item.BLNR);
      });

      this.wegLogicData.l2immodetail[anteilTemp] = anteile;
      this.wegLogicData.l2immodetail[blnrTemp] = blnr;
    });
    this.wegLogicData.l2immodetail.alleAnteile = alleanteile;
    this.wegLogicData.l2immodetail.alleBlnr = alleblnr;
  }

  private shimUBBLAnteileLS3() {
    let tempCounter = 0;
    const alleanteile = [];
    const alleblnr = [];
    this.contractData.anteildata.l3.seller.sellersPrivate.forEach((seller) => {
      const anteile = [];
      const blnr = [];
      let anteilTemp;
      let blnrTemp;

      if (tempCounter === 0) {
        anteilTemp = 'anteil';
        blnrTemp = 'bLaufendeNr';
      } else {
        anteilTemp = 'anteil' + tempCounter;
        blnrTemp = 'bLaufendeNr' + tempCounter;
      }

      tempCounter++;

      seller.shareAr.forEach(item => {
        anteile.push(item.anteil);
        blnr.push(item.BLNR);
        alleanteile.push(item.anteil);
        alleblnr.push(item.BLNR);
      });

      this.wegLogicData.l3immodetail[anteilTemp] = anteile;
      this.wegLogicData.l3immodetail[blnrTemp] = blnr;
    });

    this.contractData.anteildata.l3.seller.sellersCompany.forEach((seller) => {
      const anteile = [];
      const blnr = [];

      let anteilTemp;
      let blnrTemp;

      if (tempCounter === 0) {
        anteilTemp = 'anteil';
        blnrTemp = 'bLaufendeNr';
      } else {
        anteilTemp = 'anteil' + tempCounter;
        blnrTemp = 'bLaufendeNr' + tempCounter;
      }

      tempCounter++;

      seller.shareAr.forEach(item => {
        anteile.push(item.anteil);
        blnr.push(item.BLNR);
        alleanteile.push(item.anteil);
        alleblnr.push(item.BLNR);
      });

      this.wegLogicData.l3immodetail[anteilTemp] = anteile;
      this.wegLogicData.l3immodetail[blnrTemp] = blnr;
    });
    this.wegLogicData.l3immodetail.alleAnteile = alleanteile;
    this.wegLogicData.l3immodetail.alleBlnr = alleblnr;
  }

  private shimUBBLAnteileLS4() {
    let tempCounter = 0;
    const alleanteile = [];
    const alleblnr = [];
    this.contractData.anteildata.l4.seller.sellersPrivate.forEach((seller) => {
      const anteile = [];
      const blnr = [];

      let anteilTemp;
      let blnrTemp;

      if (tempCounter === 0) {
        anteilTemp = 'anteil';
        blnrTemp = 'bLaufendeNr';
      } else {
        anteilTemp = 'anteil' + tempCounter;
        blnrTemp = 'bLaufendeNr' + tempCounter;
      }
      tempCounter++;

      seller.shareAr.forEach(item => {
        anteile.push(item.anteil);
        blnr.push(item.BLNR);
        alleanteile.push(item.anteil);
        alleblnr.push(item.BLNR);
      });

      this.wegLogicData.l4immodetail[anteilTemp] = anteile;
      this.wegLogicData.l4immodetail[blnrTemp] = blnr;
    });

    this.contractData.anteildata.l4.seller.sellersCompany.forEach((seller) => {
      const anteile = [];
      const blnr = [];

      let anteilTemp;
      let blnrTemp;

      if (tempCounter === 0) {
        anteilTemp = 'anteil';
        blnrTemp = 'bLaufendeNr';
      } else {
        anteilTemp = 'anteil' + tempCounter;
        blnrTemp = 'bLaufendeNr' + tempCounter;
      }

      tempCounter++;

      seller.shareAr.forEach(item => {
        anteile.push(item.anteil);
        blnr.push(item.BLNR);
        alleanteile.push(item.anteil);
        alleblnr.push(item.BLNR);
      });


      this.wegLogicData.l4immodetail[anteilTemp] = anteile;
      this.wegLogicData.l4immodetail[blnrTemp] = blnr;
    });
    this.wegLogicData.l4immodetail.alleAnteile = alleanteile;
    this.wegLogicData.l4immodetail.alleBlnr = alleblnr;
  }

  private fractionHelperWEG() {
    const privateSellers = this.contractData.masterData.sellersPrivate.length;
    const corporateSellers = this.contractData.masterData.sellersCompany.length;
    const sellers = privateSellers + corporateSellers;

    const privateBuyers = this.contractData.masterData.buyersPrivate.length;
    const corporateBuyers = this.contractData.masterData.buyersCompany.length;
    let asdf;
    if (this.wegLogicData.EP == 'eigentuemeruebertraghalbeanteile') asdf = privateBuyers + corporateBuyers + 1;
    else asdf = privateBuyers + corporateBuyers;
    const buyers = asdf;

    const anteilAr = this.wegLogicData.immodetail.anteil.split('/');
    const anteilZaehler = parseInt(anteilAr[0]);
    let anteilNenner = parseInt(anteilAr[1]);
    // const calcAnteilTemp = sellers / buyers * (anteilZaehler / anteilNenner);
    const calcAnteilTemp = ((sellers / buyers) * anteilZaehler) / anteilNenner;
    const x = new Fraction(calcAnteilTemp);

    const multiplyFactor = anteilNenner / x.d;
    let calcAnteilFractionStringCounter = x.n * multiplyFactor;


    if ((calcAnteilFractionStringCounter * 2) % 2 !== 0) {
      calcAnteilFractionStringCounter = calcAnteilFractionStringCounter * 2;
      anteilNenner = anteilNenner * 2;
    }

    // this.wegLogicData.immodetail.calcAnteil = x.toFraction(true);

    this.wegLogicData.immodetail.calcAnteil = calcAnteilFractionStringCounter.toString() + '/' + anteilNenner;
    console.log(
      this.wegLogicData.immodetail.calcAnteil + 'Is Anteil Number: '
    );

    console.log('VOR CALCANTEIL');
    // Fix 1 zu 1/1
    if (this.wegLogicData.immodetail.calcAnteil === '1') {
      this.wegLogicData.immodetail.calcAnteil = '1/1';
      console.log('FIXE Calcanteil: ' + this.wegLogicData.immodetail.calcAnteil);
    }

    if (this.wegLogicData.immodetail.calc1Anteil === '1') {
      this.wegLogicData.immodetail.calc1Anteil = '1/1';
      console.log('FIXE Calcanteil: ' + this.wegLogicData.immodetail.calc1Anteil);
    }

    // 8261 Done in contract service for miet & weg
    if (this.contractData.contactData.contractOption === 'WEG' && this.contractData.primeType != 'miet') {
      this.wegLogicData.parkingPlaces.forEach(
        (parkingPlace, index) => {
          const anteilArP = parkingPlace.pAnteil.split('/');
          const anteilZaehlerP = parseInt(anteilArP[0]);
          let anteilNennerP = parseInt(anteilArP[1]);

          const calcAnteilTempP =
            ((sellers / buyers) * anteilZaehlerP) / anteilNennerP;
          const y = new Fraction(calcAnteilTempP);

          const multiplyFactorP = anteilNenner / y.d;
          let calcAnteilFractionStringCounterP = y.n * multiplyFactorP;

          if ((calcAnteilFractionStringCounterP * 2) % 2 !== 0) {
            calcAnteilFractionStringCounterP = calcAnteilFractionStringCounterP * 2;
            anteilNennerP = anteilNennerP * 2;
          }
          this.wegLogicData.parkingPlaces[index].pCalcAnteil = calcAnteilFractionStringCounterP.toString() + '/' + anteilNennerP;
        });

      // Fix 1 zu 1/1
      this.wegLogicData.parkingPlaces.forEach(
        (parkingPlace, index) => {
          if (this.wegLogicData.parkingPlaces[index].pCalcAnteil === '1') {
            this.wegLogicData.parkingPlaces[index].pCalcAnteil = '1/1';
          }
          const m = this.wegLogicData.parkingPlaces[index].kaufpreisinEUR;
          this.wegLogicData.parkingPlaces[index].kaufpreisRestore = m;
          try {
            this.wegLogicData.parkingPlaces[index].kaufpreisinEUR = this.currencyPipe.transform(m, 'EUR');
          } catch(err) {
            console.log('err');
          }
        });
    }
  }

  private fractionHelperUBBLLS1() {
    const privateSellers = this.contractData.masterData.sellersPrivate;
    const corporateSellers = this.contractData.masterData.sellersCompany;
    let sellers = 0;

    if (privateSellers && corporateSellers) {
      sellers = privateSellers.length + corporateSellers.length;
    }

    if (privateSellers && !corporateSellers) {
      sellers = privateSellers.length;
    }

    if (!privateSellers && corporateSellers) {
      sellers = corporateSellers.length;
    }

    const privateBuyers = this.contractData.masterData.buyersPrivate;
    const corporateBuyers = this.contractData.masterData.buyersCompany;
    let buyers = 0;

    if (privateBuyers && corporateBuyers) {
      buyers = privateBuyers.length + corporateBuyers.length;
    }

    if (privateBuyers && !corporateBuyers) {
      buyers = privateBuyers.length;
    }

    if (!privateBuyers && corporateBuyers) {
      buyers = corporateBuyers.length;
    }

    const tempAr = [];

    console.log(this.contractData.anteildata);
    this.contractData.anteildata.l1.seller.sellersPrivate.forEach(seller => {
      seller.shareAr.forEach((anteil, index) => {
        const anteilAr = anteil.anteil.split('/');
        const anteilZaehler = parseInt(anteilAr[0]);
        const anteilNenner = parseInt(anteilAr[1]);
        const einzelanteil = anteilZaehler / anteilNenner;

        const calcAnteilTemp =
          ((sellers / buyers) * anteilZaehler) / anteilNenner;
        // const y = new Fraction(calcAnteilTemp);
        tempAr.push(einzelanteil);
      });
    });

    this.contractData.anteildata.l1.seller.sellersCompany.forEach(seller => {
      seller.shareAr.forEach((anteil, index) => {
        const anteilAr = anteil.anteil.split('/');
        const anteilZaehler = parseInt(anteilAr[0]);
        const anteilNenner = parseInt(anteilAr[1]);
        const einzelanteil = anteilZaehler / anteilNenner;

        const calcAnteilTemp =
          ((sellers / buyers) * anteilZaehler) / anteilNenner;
        // const y = new Fraction(calcAnteilTemp);
        tempAr.push(einzelanteil);
      });
    });

    let alleAnteileZusammen = 0;

    tempAr.forEach(anteil => {
      alleAnteileZusammen += anteil;
    });

    const x = alleAnteileZusammen / buyers;

    const z = new Fraction(x);
    this.wegLogicData.immodetail.calc1Anteil = z.toFraction(true);

    if (this.wegLogicData.immodetail.calc1Anteil === '1') {
      this.wegLogicData.immodetail.calc1Anteil = '1/1';
    }
  }

  private fractionHelperUBBLLS2() {
    const privateSellers = this.contractData.masterData.sellersPrivate;
    const corporateSellers = this.contractData.masterData.sellersCompany;
    let sellers = 0;

    if (privateSellers && corporateSellers) {
      sellers = privateSellers.length + corporateSellers.length;
    }

    if (privateSellers && !corporateSellers) {
      sellers = privateSellers.length;
    }

    if (!privateSellers && corporateSellers) {
      sellers = corporateSellers.length;
    }

    const privateBuyers = this.contractData.masterData.buyersPrivate;
    const corporateBuyers = this.contractData.masterData.buyersCompany;
    let buyers = 0;

    if (privateBuyers && corporateBuyers) {
      buyers = privateBuyers.length + corporateBuyers.length;
    }

    if (privateBuyers && !corporateBuyers) {
      buyers = privateBuyers.length;
    }

    if (!privateBuyers && corporateBuyers) {
      buyers = corporateBuyers.length;
    }

    const tempAr = [];
    this.contractData.anteildata.l2.seller.sellersPrivate.forEach(seller => {
      seller.shareAr.forEach((anteil, index) => {
        const anteilAr = anteil.anteil.split('/');
        const anteilZaehler = parseInt(anteilAr[0]);
        const anteilNenner = parseInt(anteilAr[1]);

        const einzelanteil = anteilZaehler / anteilNenner;

        const calcAnteilTemp =
          ((sellers / buyers) * anteilZaehler) / anteilNenner;
        // const y = new Fraction(calcAnteilTemp);
        tempAr.push(einzelanteil);
      });
    });


    this.contractData.anteildata.l2.seller.sellersCompany.forEach(seller => {
      seller.shareAr.forEach((anteil, index) => {
        const anteilAr = anteil.anteil.split('/');
        const anteilZaehler = parseInt(anteilAr[0]);
        const anteilNenner = parseInt(anteilAr[1]);
        const einzelanteil = anteilZaehler / anteilNenner;
        const calcAnteilTemp =
          ((sellers / buyers) * anteilZaehler) / anteilNenner;
        // const y = new Fraction(calcAnteilTemp);
        tempAr.push(einzelanteil);
      });
    });

    let alleAnteileZusammen = 0;
    tempAr.forEach(anteil => {
      alleAnteileZusammen += anteil;
    });

    const x = alleAnteileZusammen / buyers;
    const z = new Fraction(x);
    this.wegLogicData.l2immodetail.calc1Anteil = z.toFraction(true);

    if (this.wegLogicData.l2immodetail.calc1Anteil === '1') {
      this.wegLogicData.l2immodetail.calc1Anteil = '1/1';
    }
  }

  private fractionHelperUBBLLS3() {
    const privateSellers = this.contractData.masterData.sellersPrivate;
    const corporateSellers = this.contractData.masterData.sellersCompany;
    let sellers = 0;

    if (privateSellers && corporateSellers) {
      sellers = privateSellers.length + corporateSellers.length;
    }

    if (privateSellers && !corporateSellers) {
      sellers = privateSellers.length;
    }

    if (!privateSellers && corporateSellers) {
      sellers = corporateSellers.length;
    }

    const privateBuyers = this.contractData.masterData.buyersPrivate;
    const corporateBuyers = this.contractData.masterData.buyersCompany;
    let buyers = 0;

    if (privateBuyers && corporateBuyers) {
      buyers = privateBuyers.length + corporateBuyers.length;
    }

    if (privateBuyers && !corporateBuyers) {
      buyers = privateBuyers.length;
    }

    if (!privateBuyers && corporateBuyers) {
      buyers = corporateBuyers.length;
    }

    const tempAr = [];


    this.contractData.anteildata.l3.seller.sellersPrivate.forEach(seller => {
      seller.shareAr.forEach((anteil, index) => {

        const anteilAr = anteil.anteil.split('/');
        const anteilZaehler = parseInt(anteilAr[0]);
        const anteilNenner = parseInt(anteilAr[1]);

        const einzelanteil = anteilZaehler / anteilNenner;

        const calcAnteilTemp =
          ((sellers / buyers) * anteilZaehler) / anteilNenner;
        // const y = new Fraction(calcAnteilTemp);
        tempAr.push(einzelanteil);
      });
    });


    this.contractData.anteildata.l3.seller.sellersCompany.forEach(seller => {
      seller.shareAr.forEach((anteil, index) => {
        const anteilAr = anteil.anteil.split('/');
        const anteilZaehler = parseInt(anteilAr[0]);
        const anteilNenner = parseInt(anteilAr[1]);

        const einzelanteil = anteilZaehler / anteilNenner;

        const calcAnteilTemp =
          ((sellers / buyers) * anteilZaehler) / anteilNenner;
        // const y = new Fraction(calcAnteilTemp);
        tempAr.push(einzelanteil);
      });
    });

    let alleAnteileZusammen = 0;

    tempAr.forEach(anteil => {
      alleAnteileZusammen += anteil;
    });

    const x = alleAnteileZusammen / buyers;

    const z = new Fraction(x);
    this.wegLogicData.l3immodetail.calc1Anteil = z.toFraction(true);

    if (this.wegLogicData.l3immodetail.calc1Anteil === '1') {
      this.wegLogicData.l3immodetail.calc1Anteil = '1/1';
    }
  }

  private fractionHelperUBBLLS4() {
    const privateSellers = this.contractData.masterData.sellersPrivate;
    const corporateSellers = this.contractData.masterData.sellersCompany;
    let sellers = 0;

    if (privateSellers && corporateSellers) {
      sellers = privateSellers.length + corporateSellers.length;
    }

    if (privateSellers && !corporateSellers) {
      sellers = privateSellers.length;
    }

    if (!privateSellers && corporateSellers) {
      sellers = corporateSellers.length;
    }

    const privateBuyers = this.contractData.masterData.buyersPrivate;
    const corporateBuyers = this.contractData.masterData.buyersCompany;
    let buyers = 0;

    if (privateBuyers && corporateBuyers) {
      buyers = privateBuyers.length + corporateBuyers.length;
    }

    if (privateBuyers && !corporateBuyers) {
      buyers = privateBuyers.length;
    }

    if (!privateBuyers && corporateBuyers) {
      buyers = corporateBuyers.length;
    }

    const tempAr = [];


    this.contractData.anteildata.l4.seller.sellersPrivate.forEach(seller => {
      seller.shareAr.forEach((anteil, index) => {

        const anteilAr = anteil.anteil.split('/');
        const anteilZaehler = parseInt(anteilAr[0]);
        const anteilNenner = parseInt(anteilAr[1]);

        const einzelanteil = anteilZaehler / anteilNenner;

        const calcAnteilTemp =
          ((sellers / buyers) * anteilZaehler) / anteilNenner;
        const y = new Fraction(calcAnteilTemp);

        tempAr.push(einzelanteil);
      });
    });



    this.contractData.anteildata.l4.seller.sellersCompany.forEach(seller => {
      seller.shareAr.forEach((anteil, index) => {

        const anteilAr = anteil.anteil.split('/');
        const anteilZaehler = parseInt(anteilAr[0]);
        const anteilNenner = parseInt(anteilAr[1]);

        const einzelanteil = anteilZaehler / anteilNenner;

        const calcAnteilTemp =
          ((sellers / buyers) * anteilZaehler) / anteilNenner;
        const y = new Fraction(calcAnteilTemp);

        tempAr.push(einzelanteil);
      });
    });

    let alleAnteileZusammen = 0;

    tempAr.forEach(anteil => {
      alleAnteileZusammen += anteil;
    });

    const x = alleAnteileZusammen / buyers;

    const z = new Fraction(x);
    this.wegLogicData.l4immodetail.calc1Anteil = z.toFraction(true);

    if (this.wegLogicData.l4immodetail.calc1Anteil === '1') {
      this.wegLogicData.l4immodetail.calc1Anteil = '1/1';
    }
  }

  private metaDataHelper() {
    console.error('XXXXXXXXXXXXXXXXXXX');

    this.wegLogicData.vertragsPreisBrutto = this.transformCurrencyToNumber(this.wegLogicData.kaufpreis) * 0.008 * 1.2;

    // Mindestvertragspreis festlegen
    if (this.wegLogicData.vertragsPreisBrutto < 3000) {
      this.wegLogicData.vertragsPreisBrutto = 3000;
    }

    this.wegLogicData.kaufpreisinEUR = this.looseCurrencyPipe.transform(this.wegLogicData.kaufpreis, 'EUR');
    this.wegLogicData.inventarWertinEUR = this.looseCurrencyPipe.transform(this.wegLogicData.inventarWert, 'EUR');
    this.wegLogicData.vertragsPreisBruttoinEUR = this.looseCurrencyPipe.transform(this.wegLogicData.vertragsPreisBrutto, 'EUR');
    this.wegLogicData.vermittlungsprovision.verkaeuferinEUR
      = this.looseCurrencyPipe.transform(this.wegLogicData.vermittlungsprovision.verkaeuferin, 'EUR');
    this.wegLogicData.vermittlungsprovision.kaeuferinEUR
      = this.looseCurrencyPipe.transform(this.wegLogicData.vermittlungsprovision.kaeuferin, 'EUR');

    if (this.miet) {
      this.wegLogicData.kautionEUR = this.looseCurrencyPipe.transform(this.wegLogicData.kaution, 'EUR');
      this.wegLogicData.monatlicheBetriebskostenEUR = this.looseCurrencyPipe.transform(this.wegLogicData.monatlicheBetriebskosten, 'EUR');
    }
  }

  private priceInWords(zahlEin) {

    console.log('ZahlEin Number: ' + isNumber(zahlEin));
    const tempZahl = this.transformCurrencyToNumber(zahlEin);

    const zahl = parseInt(tempZahl);

    const sonderzahlen = [];
    sonderzahlen[11] = 'elf';
    sonderzahlen[12] = 'zwölf';
    sonderzahlen[16] = 'sechzehn';
    sonderzahlen[17] = 'siebzehn';

    const zahlen = [];
    zahlen[1] = 'ein';
    zahlen[2] = 'zwei';
    zahlen[3] = 'drei';
    zahlen[4] = 'vier';
    zahlen[5] = 'fünf';
    zahlen[6] = 'sechs';
    zahlen[7] = 'sieben';
    zahlen[8] = 'acht';
    zahlen[9] = 'neun';
    zahlen[10] = 'zehn';
    zahlen[20] = 'zwanzig';
    zahlen[30] = 'dreißig';
    zahlen[40] = 'vierzig';
    zahlen[50] = 'fünfzig';
    zahlen[60] = 'sechzig';
    zahlen[70] = 'siebzig';
    zahlen[80] = 'achtzig';
    zahlen[90] = 'neunzig';

    const einheiten = ['', 'tausend', 'Million', 'Milliarde', 'Billion'];
    let trennschritte = 1000;
    let zahlinworten = '';

    if (zahl == 0) {
      zahlinworten = 'null';
    }

    for (let i = 0; i < Math.ceil(zahl.toString().length / 3); i++) {
      if (i > einheiten.length - 1) {
        return 'Zahl nicht unterstützt';
      }

      let zahlenblock;
      if (i == 0) {
        zahlenblock = zahl % trennschritte;
      } else {
        zahlenblock = ((zahl % trennschritte) - (zahl % (trennschritte / 1000))) / (trennschritte / 1000);
      }

      const einer = zahlenblock % 10;
      const zehn = zahlenblock % 100;
      const hunderter = (zahlenblock - (zahlenblock % 100)) / 100;
      const einheitenendung = einheiten[i].substr(einheiten[i].length - 1, 1);

      if (zahlenblock > 0) {
        if (zahlenblock > 1 && einheitenendung == 'n') {
          zahlinworten = ' ' + einheiten[i] + 'en ' + zahlinworten;
        } else if (zahlenblock > 1 && einheitenendung == 'e') {
          zahlinworten = ' ' + einheiten[i] + 'n ' + zahlinworten;
        } else if (zahlenblock > 0 && i == 1) {
          zahlinworten = einheiten[i] + zahlinworten;
        } else {
          zahlinworten = ' ' + einheiten[i] + ' ' + zahlinworten;
        }
      }

      if (zehn > 0) {
        if (zehn == 1 && i == 0) {
          zahlinworten = 'eins' + zahlinworten;
        } else if (zehn == 1 && i == 1) {
          zahlinworten = 'ein' + zahlinworten;
        } else if (zehn == 1 && i > 1) {
          zahlinworten = 'eine' + zahlinworten;
        } else if (sonderzahlen[zehn]) {
          zahlinworten = sonderzahlen[zehn] + zahlinworten;
        } else {
          if (zehn > 9) {
            zahlinworten = zahlen[zehn - einer] + zahlinworten;
          }
          if (zehn > 20 && einer > 0) {
            zahlinworten = 'und' + zahlinworten;
          }
          if (einer > 0) {
            zahlinworten = zahlen[einer] + zahlinworten;
          }
        }
      }

      if (hunderter > 0) {
        zahlinworten = zahlen[hunderter] + 'hundert' + zahlinworten;
      }

      trennschritte *= 1000;
    }
    return zahlinworten;
  }

  private shimKG() {
    if (this.LSBOOL[3]) {
      this.contractData.anteildata.l1.KGName = this.wegLogicData.immodetail.katastralGemeinde.KGName;
      this.contractData.anteildata.l1.KGNr = this.wegLogicData.immodetail.katastralGemeinde.KGNr;
      this.contractData.anteildata.l2.KGName = this.wegLogicData.l2immodetail.l2katastralGemeinde.KGName;
      this.contractData.anteildata.l2.KGNr = this.wegLogicData.l2immodetail.l2katastralGemeinde.KGNr;
      this.contractData.anteildata.l3.KGName = this.wegLogicData.l3immodetail.l3katastralGemeinde.KGName;
      this.contractData.anteildata.l3.KGNr = this.wegLogicData.l3immodetail.l3katastralGemeinde.KGNr;
      this.contractData.anteildata.l4.KGName = this.wegLogicData.l4immodetail.l4katastralGemeinde.KGName;
      this.contractData.anteildata.l4.KGNr = this.wegLogicData.l4immodetail.l4katastralGemeinde.KGNr;
      if (this.contractData.anteildata.l1.KGNr === this.contractData.anteildata.l4.KGNr
        && this.contractData.anteildata.l2.KGNr === this.contractData.anteildata.l1.KGNr
        && this.contractData.anteildata.l3.KGNr === this.contractData.anteildata.l1.KGNr) { this.contractData.anteildata.LSGleicheKGNr = true; }
      else { this.contractData.anteildata.LSGleicheKGNr = false; }
    }
    else if (this.LSBOOL[2]) {
      this.contractData.anteildata.l1.KGName = this.wegLogicData.immodetail.katastralGemeinde.KGName;
      this.contractData.anteildata.l1.KGNr = this.wegLogicData.immodetail.katastralGemeinde.KGNr;
      this.contractData.anteildata.l2.KGName = this.wegLogicData.l2immodetail.l2katastralGemeinde.KGName;
      this.contractData.anteildata.l2.KGNr = this.wegLogicData.l2immodetail.l2katastralGemeinde.KGNr;
      this.contractData.anteildata.l3.KGName = this.wegLogicData.l3immodetail.l3katastralGemeinde.KGName;
      this.contractData.anteildata.l3.KGNr = this.wegLogicData.l3immodetail.l3katastralGemeinde.KGNr;
      if (this.contractData.anteildata.l1.KGNr === this.contractData.anteildata.l3.KGNr
        && this.contractData.anteildata.l2.KGNr == this.contractData.anteildata.l3.KGNr) { this.contractData.anteildata.LSGleicheKGNr = true; }
      else { this.contractData.anteildata.LSGleicheKGNr = false; }
    }
    else if (this.LSBOOL[1]) {
      this.contractData.anteildata.l1.KGName = this.wegLogicData.immodetail.katastralGemeinde.KGName;
      this.contractData.anteildata.l1.KGNr = this.wegLogicData.immodetail.katastralGemeinde.KGNr;
      this.contractData.anteildata.l2.KGName = this.wegLogicData.l2immodetail.l2katastralGemeinde.KGName;
      this.contractData.anteildata.l2.KGNr = this.wegLogicData.l2immodetail.l2katastralGemeinde.KGNr;
      if (this.contractData.anteildata.l1.KGNr === this.contractData.anteildata.l2.KGNr) { this.contractData.anteildata.LSGleicheKGNr = true; }
      else { this.contractData.anteildata.LSGleicheKGNr = false; }
    }
    else {
      this.contractData.anteildata.l1.KGName = this.wegLogicData.immodetail.katastralGemeinde.KGName;
      this.contractData.anteildata.l1.KGNr = this.wegLogicData.immodetail.katastralGemeinde.KGNr;
      this.contractData.anteildata.LSGleicheKGNr = true;
    }
  }

  shimLastenLoeschen() {
    if (this.wegLogicData.lastenLoeschen === "Ja") {
      this.wegLogicData.LastenLoeschenBeteiligte.forEach(element => {
        element.personArray.forEach(item => {
          // const a = item.birthday;
          // item.birthday = this.datepipe.transform(a as string, 'yyyy-MM-dd');
        });
      });
    }
  }

  private shimPrivDateLS1() {
    if (this.contractData.anteildata.l1.seller.sellersPrivate) {
      console.log('Transform Seller Private');
      this.contractData.anteildata.l1.seller.sellersPrivate.forEach(element => {
        // const a = element.formatedBirthday;
        // element.birthday = this.datepipe.transform(a as string, 'yyyy-MM-dd');
      });
    }

    if (this.contractData.anteildata.l1.buyer.buyersPrivate) {
      console.log('Transform Buyer Private');
      this.contractData.anteildata.l1.buyer.buyersPrivate.forEach(element => {
        console.log('Convert Private Buyer');
        // const a = element.formatedBirthday;
        // element.birthday = this.datepipe.transform(a as string, 'yyyy-MM-dd');
      });
    }
  }

  private shimPrivDateLS2() {
    if (this.contractData.anteildata.l2.seller.sellersPrivate) {
      this.contractData.anteildata.l2.seller.sellersPrivate.forEach(element => {
        // const a = element.formatedBirthday;
        // element.birthday = this.datepipe.transform(a as string, 'yyyy-MM-dd');
      });
    }

    if (this.contractData.anteildata.l2.buyer.buyersPrivate) {
      this.contractData.anteildata.l2.buyer.buyersPrivate.forEach(element => {
        // const a = element.formatedBirthday;
        // element.birthday = this.datepipe.transform(a as string, 'yyyy-MM-dd');
      });
    }
  }

  private shimPrivDateLS3() {
    if (this.contractData.anteildata.l3.seller.sellersPrivate) {
      this.contractData.anteildata.l3.seller.sellersPrivate.forEach(element => {
        // const a = element.formatedBirthday;
        // element.birthday = this.datepipe.transform(a as string, 'yyyy-MM-dd');
      });
    }

    if (this.contractData.anteildata.l3.buyer.buyersPrivate) {
      this.contractData.anteildata.l3.buyer.buyersPrivate.forEach(element => {
        // const a = element.formatedBirthday;
        // element.birthday = this.datepipe.transform(a as string, 'yyyy-MM-dd');
      });
    }
  }

  private shimPrivDateLS4() {
    if (this.contractData.anteildata.l4.seller.sellersPrivate) {
      this.contractData.anteildata.l4.seller.sellersPrivate.forEach(element => {
        // const a = element.formatedBirthday;
        // element.birthday = this.datepipe.transform(a as string, 'yyyy-MM-dd');
      });
    }

    if (this.contractData.anteildata.l4.buyer.buyersPrivate) {
      this.contractData.anteildata.l4.buyer.buyersPrivate.forEach(element => {
        // const a = element.formatedBirthday;
        // element.birthday = this.datepipe.transform(a as string, 'yyyy-MM-dd');
      });
    }
  }

  private shimAnteilData() {
    if (this.LSBOOL[0]) {

      let KlengthPriv = this.contractData.anteildata.l1.buyer.buyersPrivate.length
      let KlengthComp = this.contractData.anteildata.l1.buyer.buyersCompany.length
      let lengthPriv = this.contractData.anteildata.l1.seller.sellersPrivate.length;
      let lengthComp = this.contractData.anteildata.l1.seller.sellersCompany.length;


      if (KlengthPriv == 0) this.contractData.anteildata.l1.buyer.buyersPrivate = undefined;
      else {
        const indexAr = new Array<number>(KlengthPriv);
        this.contractData.anteildata.l1.buyer.buyersPrivate.forEach((buyer, index) => {
          if (buyer.calcanteil == "-1") indexAr.push(index);
        });

        indexAr.forEach(index => {
          this.contractData.anteildata.l1.buyer.buyersPrivate.splice(index, 1);
        });
        if (this.contractData.anteildata.l1.buyer.buyersPrivate == 0) this.contractData.anteildata.l1.buyer.buyersPrivate = undefined;
      }

      if (lengthComp == 0) this.contractData.anteildata.l1.seller.sellersCompany = undefined;
      else {
        const indexAr = new Array<number>(lengthPriv);
        this.contractData.anteildata.l1.seller.sellersCompany.forEach((seller, index) => {
          if (seller.calcanteil == "-1") indexAr.push(index);
        });

        indexAr.forEach(index => {
          this.contractData.anteildata.l1.seller.sellersCompany.splice(index, 1);
        });
        if (this.contractData.anteildata.l1.seller.sellersCompany == 0) this.contractData.anteildata.l1.seller.sellersCompany = undefined;
      }

      if (lengthPriv == 0) this.contractData.anteildata.l1.seller.sellersPrivate = undefined;
      else {
        const indexAr = new Array<number>(lengthPriv);
        this.contractData.anteildata.l1.seller.sellersPrivate.forEach((seller, index) => {
          if (seller.calcanteil == "-1") indexAr.push(index);
        });

        indexAr.forEach(index => {
          this.contractData.anteildata.l1.seller.sellersPrivate.splice(index, 1);
        });
        if (this.contractData.anteildata.l1.seller.sellersPrivate == 0) this.contractData.anteildata.l1.seller.sellersPrivate = undefined;
      }

      if (KlengthComp == 0) this.contractData.anteildata.l1.buyer.buyersCompany = undefined;
      else {
        const indexAr = new Array<number>(KlengthComp);
        this.contractData.anteildata.l1.buyer.buyersCompany.forEach((buyer, index) => {
          if (buyer.calcanteil == "-1") indexAr.push(index);
        });

        indexAr.forEach(index => {
          this.contractData.anteildata.l1.buyer.buyersCompany.splice(index, 1);
        });
        if (this.contractData.anteildata.l1.buyer.buyersCompany == 0) this.contractData.anteildata.l1.buyer.buyersCompany = undefined;
      }

      if (this.contractData.anteildata.l1.buyer.buyersPrivate) KlengthPriv = this.contractData.anteildata.l1.buyer.buyersPrivate.length
      if (this.contractData.anteildata.l1.buyer.buyersCompany) KlengthComp = this.contractData.anteildata.l1.buyer.buyersCompany.length
      if (this.contractData.anteildata.l1.seller.sellersPrivate) lengthPriv = this.contractData.anteildata.l1.seller.sellersPrivate.length;
      if (this.contractData.anteildata.l1.seller.sellersCompany) lengthComp = this.contractData.anteildata.l1.seller.sellersCompany.length;

      this.contractData.anteildata.l1.seller.anzahl = lengthPriv + lengthComp;
      this.contractData.anteildata.l1.buyer.anzahl = KlengthPriv + KlengthComp;
    }
    else { this.contractData.anteildata.l1 = undefined; }

    if (this.LSBOOL[1]) {

      let KlengthPriv = this.contractData.anteildata.l2.buyer.buyersPrivate.length;
      const KlengthComp = this.contractData.anteildata.l2.buyer.buyersCompany.length;
      let lengthPriv = this.contractData.anteildata.l2.seller.sellersPrivate.length;
      let lengthComp = this.contractData.anteildata.l2.seller.sellersCompany.length;


      if (KlengthPriv == 0) this.contractData.anteildata.l2.buyer.buyersPrivate = undefined;
      else {
        const indexAr = new Array<number>(KlengthPriv);
        this.contractData.anteildata.l2.buyer.buyersPrivate.forEach((buyer, index) => {
          if (buyer.calcanteil == "-1") indexAr.push(index);
        });

        indexAr.forEach(index => {
          this.contractData.anteildata.l2.buyer.buyersPrivate.splice(index, 1);
        });
        if (this.contractData.anteildata.l2.buyer.buyersPrivate == 0) this.contractData.anteildata.l2.buyer.buyersPrivate = undefined;
      }

      if (lengthComp == 0) this.contractData.anteildata.l2.seller.sellersCompany = undefined;
      else {
        const indexAr = new Array<number>(lengthPriv);
        this.contractData.anteildata.l2.seller.sellersCompany.forEach((seller, index) => {
          if (seller.calcanteil == "-1") indexAr.push(index);
        });

        indexAr.forEach(index => {
          this.contractData.anteildata.l2.seller.sellersCompany.splice(index, 1);
        });
        if (this.contractData.anteildata.l2.seller.sellersCompany == 0) this.contractData.anteildata.l2.seller.sellersCompany = undefined;
      }

      if (lengthPriv == 0) this.contractData.anteildata.l2.seller.sellersPrivate = undefined;
      else {
        const indexAr = new Array<number>(lengthPriv);
        this.contractData.anteildata.l2.seller.sellersPrivate.forEach((seller, index) => {
          if (seller.calcanteil == "-1") indexAr.push(index);
        });

        indexAr.forEach(index => {
          this.contractData.anteildata.l2.seller.sellersPrivate.splice(index, 1);
        });
        if (this.contractData.anteildata.l2.seller.sellersPrivate == 0) this.contractData.anteildata.l2.seller.sellersPrivate = undefined;
      }

      if (KlengthComp == 0) this.contractData.anteildata.l2.buyer.buyersCompany = undefined;
      else {
        const indexAr = new Array<number>(KlengthComp);
        this.contractData.anteildata.l2.buyer.buyersCompany.forEach((buyer, index) => {
          if (buyer.calcanteil == "-1") indexAr.push(index);
        });

        indexAr.forEach(index => {
          this.contractData.anteildata.l2.buyer.buyersCompany.splice(index, 1);
        });
        if (this.contractData.anteildata.l2.buyer.buyersCompany == 0) this.contractData.anteildata.l2.buyer.buyersCompany = undefined;
      }

      if (this.contractData.anteildata.l2.buyer.buyersPrivate) KlengthPriv = this.contractData.anteildata.l2.buyer.buyersPrivate.length
      if (this.contractData.anteildata.l2.buyer.buyersCompany) lengthComp = this.contractData.anteildata.l2.buyer.buyersCompany.length
      if (this.contractData.anteildata.l2.seller.sellersPrivate) lengthPriv = this.contractData.anteildata.l2.seller.sellersPrivate.length;
      if (this.contractData.anteildata.l2.seller.sellersCompany) lengthComp = this.contractData.anteildata.l2.seller.sellersCompany.length;

      this.contractData.anteildata.l2.seller.anzahl = lengthPriv + lengthComp;
      this.contractData.anteildata.l2.buyer.anzahl = KlengthPriv + KlengthComp;
    }
    else { this.contractData.anteildata.l2 = undefined; }

    if (this.LSBOOL[2]) {

      let KlengthPriv = this.contractData.anteildata.l3.buyer.buyersPrivate.length
      let KlengthComp = this.contractData.anteildata.l3.buyer.buyersCompany.length
      let lengthPriv = this.contractData.anteildata.l3.seller.sellersPrivate.length;
      let lengthComp = this.contractData.anteildata.l3.seller.sellersCompany.length;


      if (KlengthPriv == 0) this.contractData.anteildata.l3.buyer.buyersPrivate = undefined;
      else {
        const indexAr = new Array<number>(KlengthPriv);
        this.contractData.anteildata.l3.buyer.buyersPrivate.forEach((buyer, index) => {
          if (buyer.calcanteil == "-1") indexAr.push(index);
        });

        indexAr.forEach(index => {
          this.contractData.anteildata.l3.buyer.buyersPrivate.splice(index, 1);
        });
        if (this.contractData.anteildata.l3.buyer.buyersPrivate == 0) this.contractData.anteildata.l3.buyer.buyersPrivate = undefined;
      }

      if (lengthComp == 0) this.contractData.anteildata.l3.seller.sellersCompany = undefined;
      else {
        const indexAr = new Array<number>(lengthPriv);
        this.contractData.anteildata.l3.seller.sellersCompany.forEach((seller, index) => {
          if (seller.calcanteil == "-1") indexAr.push(index);
        });

        indexAr.forEach(index => {
          this.contractData.anteildata.l3.seller.sellersCompany.splice(index, 1);
        });
        if (this.contractData.anteildata.l3.seller.sellersCompany == 0) this.contractData.anteildata.l3.seller.sellersCompany = undefined;
      }

      if (lengthPriv == 0) this.contractData.anteildata.l3.seller.sellersPrivate = undefined;
      else {
        const indexAr = new Array<number>(lengthPriv);
        this.contractData.anteildata.l3.seller.sellersPrivate.forEach((seller, index) => {
          if (seller.calcanteil == "-1") indexAr.push(index);
        });

        indexAr.forEach(index => {
          this.contractData.anteildata.l3.seller.sellersPrivate.splice(index, 1);
        });
        if (this.contractData.anteildata.l3.seller.sellersPrivate == 0) this.contractData.anteildata.l3.seller.sellersPrivate = undefined;
      }

      if (KlengthComp == 0) this.contractData.anteildata.l3.buyer.buyersCompany = undefined;
      else {
        const indexAr = new Array<number>(KlengthComp);
        this.contractData.anteildata.l3.buyer.buyersCompany.forEach((buyer, index) => {
          if (buyer.calcanteil == "-1") indexAr.push(index);
        });

        indexAr.forEach(index => {
          this.contractData.anteildata.l3.buyer.buyersCompany.splice(index, 1);
        });
        if (this.contractData.anteildata.l3.buyer.buyersCompany == 0) this.contractData.anteildata.l3.buyer.buyersCompany = undefined;
      }

      if (this.contractData.anteildata.l3.buyer.buyersPrivate) KlengthPriv = this.contractData.anteildata.l3.buyer.buyersPrivate.length
      if (this.contractData.anteildata.l3.buyer.buyersCompany) KlengthComp = this.contractData.anteildata.l3.buyer.buyersCompany.length
      if (this.contractData.anteildata.l3.seller.sellersPrivate) lengthPriv = this.contractData.anteildata.l3.seller.sellersPrivate.length;
      if (this.contractData.anteildata.l3.seller.sellersCompany) lengthComp = this.contractData.anteildata.l3.seller.sellersCompany.length;

      this.contractData.anteildata.l3.seller.anzahl = lengthPriv + lengthComp;
      this.contractData.anteildata.l3.buyer.anzahl = KlengthPriv + KlengthComp;
    }
    else { this.contractData.anteildata.l3 = undefined; }

    if (this.LSBOOL[3]) {

      let KlengthPriv = this.contractData.anteildata.l4.buyer.buyersPrivate.length
      let KlengthComp = this.contractData.anteildata.l4.buyer.buyersCompany.length
      let lengthPriv = this.contractData.anteildata.l4.seller.sellersPrivate.length;
      let lengthComp = this.contractData.anteildata.l4.seller.sellersCompany.length;


      if (KlengthPriv == 0) this.contractData.anteildata.l4.buyer.buyersPrivate = undefined;
      else {
        const indexAr = new Array<number>(KlengthPriv);
        this.contractData.anteildata.l4.buyer.buyersPrivate.forEach((buyer, index) => {
          if (buyer.calcanteil == "-1") indexAr.push(index);
        });

        indexAr.forEach(index => {
          this.contractData.anteildata.l4.buyer.buyersPrivate.splice(index, 1);
        });
        if (this.contractData.anteildata.l4.buyer.buyersPrivate == 0) this.contractData.anteildata.l4.buyer.buyersPrivate = undefined;
      }

      if (lengthComp == 0) this.contractData.anteildata.l4.seller.sellersCompany = undefined;
      else {
        const indexAr = new Array<number>(lengthPriv);
        this.contractData.anteildata.l4.seller.sellersCompany.forEach((seller, index) => {
          if (seller.calcanteil == "-1") indexAr.push(index);
        });

        indexAr.forEach(index => {
          this.contractData.anteildata.l4.seller.sellersCompany.splice(index, 1);
        });
        if (this.contractData.anteildata.l4.seller.sellersCompany == 0) this.contractData.anteildata.l4.seller.sellersCompany = undefined;
      }

      if (lengthPriv == 0) this.contractData.anteildata.l4.seller.sellersPrivate = undefined;
      else {
        const indexAr = new Array<number>(lengthPriv);
        this.contractData.anteildata.l4.seller.sellersPrivate.forEach((seller, index) => {
          if (seller.calcanteil == "-1") indexAr.push(index);
        });

        indexAr.forEach(index => {
          this.contractData.anteildata.l4.seller.sellersPrivate.splice(index, 1);
        });
        if (this.contractData.anteildata.l4.seller.sellersPrivate == 0) this.contractData.anteildata.l4.seller.sellersPrivate = undefined;
      }
      if (KlengthComp == 0) this.contractData.anteildata.l4.buyer.buyersCompany = undefined;
      else {
        const indexAr = new Array<number>(KlengthComp);
        this.contractData.anteildata.l4.buyer.buyersCompany.forEach((buyer, index) => {
          if (buyer.calcanteil == "-1") indexAr.push(index);
        });

        indexAr.forEach(index => {
          this.contractData.anteildata.l4.buyer.buyersCompany.splice(index, 1);
        });
        if (this.contractData.anteildata.l4.buyer.buyersCompany == 0) this.contractData.anteildata.l4.buyer.buyersCompany = undefined;
      }

      if (this.contractData.anteildata.l4.buyer.buyersPrivate) KlengthPriv = this.contractData.anteildata.l4.buyer.buyersPrivate.length
      if (this.contractData.anteildata.l4.buyer.buyersCompany) KlengthComp = this.contractData.anteildata.l4.buyer.buyersCompany.length
      if (this.contractData.anteildata.l4.seller.sellersPrivate) lengthPriv = this.contractData.anteildata.l4.seller.sellersPrivate.length;
      if (this.contractData.anteildata.l4.seller.sellersCompany) lengthComp = this.contractData.anteildata.l4.seller.sellersCompany.length;

      this.contractData.anteildata.l4.seller.anzahl = lengthPriv + lengthComp;
      this.contractData.anteildata.l4.buyer.anzahl = KlengthPriv + KlengthComp;
    }
    else { this.contractData.anteildata.l4 = undefined; }
  }

  private shimShare() {
    if (this.contractData.anteildata) {
      if (this.LSBOOL[0]) {
        this.contractData.anteildata.l1.seller.sellersPrivate.forEach(seller => {
          if (seller.shareArDev) seller.shareArDev = undefined;
        });

        this.contractData.anteildata.l1.seller.sellersCompany.forEach(seller => {
          if (seller.shareArDev) seller.shareArDev = undefined;
        });

        this.contractData.anteildata.l1.buyer.buyersPrivate.forEach(buyer => {
          if (buyer.shareArDev) buyer.shareArDev = undefined;
        });

        this.contractData.anteildata.l1.buyer.buyersCompany.forEach(buyer => {
          if (buyer.shareArDev) buyer.shareArDev = undefined;
        });
      }

      if (this.LSBOOL[1]) {
        this.contractData.anteildata.l2.seller.sellersPrivate.forEach(seller => {
          if (seller.shareArDev) seller.shareArDev = undefined;
        });

        this.contractData.anteildata.l2.seller.sellersCompany.forEach(seller => {
          if (seller.shareArDev) seller.shareArDev = undefined;
        });

        this.contractData.anteildata.l2.buyer.buyersPrivate.forEach(buyer => {
          if (buyer.shareArDev) buyer.shareArDev = undefined;
        });

        this.contractData.anteildata.l2.buyer.buyersCompany.forEach(buyer => {
          if (buyer.shareArDev) buyer.shareArDev = undefined;
        });
      }

      if (this.LSBOOL[2]) {
        this.contractData.anteildata.l3.seller.sellersPrivate.forEach(seller => {
          if (seller.shareArDev) seller.shareArDev = undefined;
        });

        this.contractData.anteildata.l3.seller.sellersCompany.forEach(seller => {
          if (seller.shareArDev) seller.shareArDev = undefined;
        });

        this.contractData.anteildata.l3.buyer.buyersPrivate.forEach(buyer => {
          if (buyer.shareArDev) buyer.shareArDev = undefined;
        });

        this.contractData.anteildata.l3.buyer.buyersCompany.forEach(buyer => {
          if (buyer.shareArDev) buyer.shareArDev = undefined;
        });
      }

      if (this.LSBOOL[3]) {
        this.contractData.anteildata.l4.seller.sellersPrivate.forEach(seller => {
          if (seller.shareArDev) seller.shareArDev = undefined;
        });

        this.contractData.anteildata.l4.seller.sellersCompany.forEach(seller => {
          if (seller.shareArDev) seller.shareArDev = undefined;
        });

        this.contractData.anteildata.l4.buyer.buyersPrivate.forEach(buyer => {
          if (buyer.shareArDev) buyer.shareArDev = undefined;
        });

        this.contractData.anteildata.l4.buyer.buyersCompany.forEach(buyer => {
          if (buyer.shareArDev) buyer.shareArDev = undefined;
        });
      }
    }
  }
  //#endregion

  private nextClicked = false;

  fixImmoShares() {
    console.log('L1');
    if(this.wegLogicData.immodetail) {

      if(this.wegLogicData.immodetail.anteil1 && this.wegLogicData.immodetail.anteil1.length && !this.wegLogicData.immodetail.anteil.length) {
        console.log('Anteil1 not empty, Anteil empty');
        this.wegLogicData.immodetail.anteil = this.wegLogicData.immodetail.anteil1;
        this.wegLogicData.immodetail.anteil1 = [];
      }

      // if(this.wegLogicData.immodetail.anteil2 && this.wegLogicData.immodetail.anteil2.length && !this.wegLogicData.immodetail.anteil1.length) {
      //   console.log('Anteil2 not empty, Anteil1 empty');
      //   this.wegLogicData.immodetail.anteil1 = this.wegLogicData.immodetail.anteil2;
      //   this.wegLogicData.immodetail.anteil2 = [];
      // }
      //
      // if(this.wegLogicData.immodetail.anteil3 && this.wegLogicData.immodetail.anteil3.length && !this.wegLogicData.immodetail.anteil2.length) {
      //   console.log('Anteil3 not empty, Anteil2 empty');
      //   this.wegLogicData.immodetail.anteil2 = this.wegLogicData.immodetail.anteil3;
      //   this.wegLogicData.immodetail.anteil3 = [];
      // }
      //
      // if(this.wegLogicData.immodetail.anteil4 && this.wegLogicData.immodetail.anteil4.length && !this.wegLogicData.immodetail.anteil3.length) {
      //   console.log('Anteil4 not empty, Anteil3 empty');
      //   this.wegLogicData.immodetail.anteil3 = this.wegLogicData.immodetail.anteil3;
      //   this.wegLogicData.immodetail.anteil4 = [];
      // }

      if(this.wegLogicData.immodetail.bLaufendeNr1 && this.wegLogicData.immodetail.bLaufendeNr1.length && !this.wegLogicData.immodetail.bLaufendeNr.length) {
        console.log('bLaufendeNr1 not empty, bLaufendeNr empty');
        this.wegLogicData.immodetail.bLaufendeNr = this.wegLogicData.immodetail.bLaufendeNr1;
        this.wegLogicData.immodetail.bLaufendeNr1 = [];
      }

      // if(this.wegLogicData.immodetail.bLaufendeNr2 && this.wegLogicData.immodetail.bLaufendeNr2.length && !this.wegLogicData.immodetail.bLaufendeNr1.length) {
      //   console.log('bLaufendeNr1 not empty, bLaufendeNr empty');
      //   this.wegLogicData.immodetail.bLaufendeNr1 = this.wegLogicData.immodetail.bLaufendeNr2;
      //   this.wegLogicData.immodetail.bLaufendeNr2 = [];
      // }
      //
      // if(this.wegLogicData.immodetail.bLaufendeNr3 && this.wegLogicData.immodetail.bLaufendeNr3.length && !this.wegLogicData.immodetail.bLaufendeNr2.length) {
      //   console.log('bLaufendeNr1 not empty, bLaufendeNr empty');
      //   this.wegLogicData.immodetail.bLaufendeNr2 = this.wegLogicData.immodetail.bLaufendeNr3;
      //   this.wegLogicData.immodetail.bLaufendeNr3 = [];
      // }
      //
      // if(this.wegLogicData.immodetail.bLaufendeNr4 && this.wegLogicData.immodetail.bLaufendeNr4.length && !this.wegLogicData.immodetail.bLaufendeNr3.length) {
      //   console.log('bLaufendeNr1 not empty, bLaufendeNr empty');
      //   this.wegLogicData.immodetail.bLaufendeNr3 = this.wegLogicData.immodetail.bLaufendeNr4;
      //   this.wegLogicData.immodetail.bLaufendeNr4 = [];
      // }

    }

    console.log('L2');
    if(this.wegLogicData.l2immodetail) {
      if(this.wegLogicData.l2immodetail.anteil1 && this.wegLogicData.l2immodetail.anteil1.length && !this.wegLogicData.l2immodetail.anteil.length) {
        console.log('Anteil1 not empty, Anteil empty');
        this.wegLogicData.l2immodetail.anteil = this.wegLogicData.l2immodetail.anteil1;
        this.wegLogicData.l2immodetail.anteil1 = [];
      }
      if(this.wegLogicData.l2immodetail.bLaufendeNr1 && this.wegLogicData.l2immodetail.bLaufendeNr1.length && !this.wegLogicData.l2immodetail.bLaufendeNr.length) {
        console.log('bLaufendeNr1 not empty, bLaufendeNr empty');
        this.wegLogicData.l2immodetail.bLaufendeNr = this.wegLogicData.l2immodetail.bLaufendeNr1;
        this.wegLogicData.l2immodetail.bLaufendeNr1 = [];
      }
    }

    console.log('L3');
    if(this.wegLogicData.l3immodetail) {
      if(this.wegLogicData.l3immodetail.anteil1 && this.wegLogicData.l3immodetail.anteil1.length && !this.wegLogicData.l3immodetail.anteil.length) {
        console.log('Anteil1 not empty, Anteil empty');
        this.wegLogicData.l3immodetail.anteil = this.wegLogicData.l3immodetail.anteil1;
        this.wegLogicData.l3immodetail.anteil1 = [];
      }
      if(this.wegLogicData.l3immodetail.bLaufendeNr1 && this.wegLogicData.l3immodetail.bLaufendeNr1.length && !this.wegLogicData.l3immodetail.bLaufendeNr.length) {
        console.log('bLaufendeNr1 not empty, bLaufendeNr empty');
        this.wegLogicData.l3immodetail.bLaufendeNr = this.wegLogicData.l3immodetail.bLaufendeNr1;
        this.wegLogicData.l3immodetail.bLaufendeNr1 = [];
      }
    }

    console.log('L4');
    if(this.wegLogicData.l4immodetail) {
      if(this.wegLogicData.l4immodetail.anteil1 && this.wegLogicData.l4immodetail.anteil1.length && !this.wegLogicData.l4immodetail.anteil.length) {
        console.log('Anteil1 not empty, Anteil empty');
        this.wegLogicData.l4immodetail.anteil = this.wegLogicData.l4immodetail.anteil1;
        this.wegLogicData.l4immodetail.anteil1 = [];
      }
      if(this.wegLogicData.l4immodetail.bLaufendeNr1 && this.wegLogicData.l4immodetail.bLaufendeNr1.length && !this.wegLogicData.l4immodetail.bLaufendeNr.length) {
        console.log('bLaufendeNr1 not empty, bLaufendeNr empty');
        this.wegLogicData.l4immodetail.bLaufendeNr = this.wegLogicData.l4immodetail.bLaufendeNr1;
        this.wegLogicData.l4immodetail.bLaufendeNr1 = [];
      }
    }
    console.log(this.wegLogicData);
  }

  openVorschau() {
    this.refreshLastenLoeschenOwners(false);
    this.NoVorschau = false;
    // this.timerSub.unsubscribe();

    // this.wegLogicData.parkingPlaces.forEach(
    //   (parkingPlace, index) => {
    //     const m = this.wegLogicData.parkingPlaces[index].kaufpreisinEUR;
    //     this.wegLogicData.parkingPlaces[index].kaufpreisinEUR = this.currencyPipe.transform(m, 'EUR');
    //   }
    // );

    this.shimEP();
    this.shimPost();
    this.shimWegKaufpreis();
    const dialogRef = this.VorschauDlg.open(DlgVorschauWegComponent);

    dialogRef.afterClosed().subscribe(
      data => {
        console.log(data);
        if (data.success) {
          this.contractDataService.changeLoading({ loading: true });
          console.log("POST!!!");
          // moved from goToNext
          if (this.contractData.primeType == 'miet') {
            if(this.wegLogicData.mietObjType == "wohnung" &&
             ((this.wegLogicData.mehrals2VermietbareObj == "Ja" && this.wegLogicData.bewilligungVor08051945 == "Ja") ||
             (this.wegLogicData.mehrals2VermietbareObj == "Ja" && this.wegLogicData.bewilligungVor08051945 == "Nein" && this.wegLogicData.oeffentlicheFoerderungen == "Ja"))) {
               this.wegLogicData.indexjaehrlich = undefined;
               this.wegLogicData.indexwert = undefined;
             }
          }

          if (this.contractData.primeType == 'kfz') {
            //shimkfz
            const c = this.transformCurrencyToNumber(this.wegLogicData.kmstand);
            const a = this.currencyPipe.transform(c, 'EUR');
            const b = a.length - 5;
            this.wegLogicData.kmstand = a.substring(0, b);
            //shim Daten
            if (this.wegLogicData.datumRestoreUebergabe) {
              const b = this.wegLogicData.datumRestoreUebergabe;
              console.log(b);
              this.wegLogicData.datumRestoreUebergabe = this.datepipe.transform(b, 'dd.MM.yyyy');
              this.wegLogicData.uebergabeDatum = this.datepipe.transform(b, 'dd.MM.yyyy');
              this.wegLogicData.uebergabedatumsave = (b as Date).toJSON();
            }
            if (this.wegLogicData.erstzulassungRestore) {
              const b = this.wegLogicData.erstzulassungRestore;
              this.wegLogicData.erstzulassungRestore = this.datepipe.transform(b, 'dd.MM.yyyy');
              this.wegLogicData.erstzulassung = this.datepipe.transform(b, 'dd.MM.yyyy');
              this.wegLogicData.erstzulassungsave = (b as Date).toJSON();
            }
            //shim Money
          }

          if (this.contractData.primeType != 'kfz') {
            this.persistFormData();
            this.ShimCLNR();
            console.log('Fixing Private Masterdata');
            this.fixPrivateMasterdata();
          }

          this.fixImmoShares();

          if (this.wegLogicData.datumRestoreBindung) {
            const c = this.wegLogicData.datumRestoreBindung;
            console.log(c);
            this.wegLogicData.datumRestoreBindung = this.datepipe.transform(c, 'dd.MM.yyyy');
          }

          // this.contractDataService.setContractWEGLogicData(this.wegLogicData);
          this.contractDataService.setContractWEGLogicData(this.wegLogicData, this.contractData.anteildata);
          // if (this.contractData.primeType != 'kfz')
          // this.contractDataService.setAnteildata(this.contractData.anteildata);
          console.log('Opening Dialog..');
          console.log(this.contractDataService.getContractData());
          // moved from goToNext
          this.nextClicked = true;

          if(data.type == 'now') {
            this.contractDataService.generateInvoiceOther().subscribe((res : any) => {
              this.contractDataService.changeLoading({ loading: false });
              res = JSON.parse(res);
              console.log(res.invoice);
              localStorage.setItem('invoice', JSON.stringify(res.invoice));

              const dialogRef = this.dialog.open(StripePaymentComponent, {
                data: { invoice : res.invoice },
                width: '800px',
                disableClose : true
              });

              dialogRef.afterClosed().subscribe(
              data => {
                this.shared.deleteApi('payment-intent/' + res.invoice.id).subscribe((res : any) => {
                  localStorage.removeItem('invoice');
                });
              });
            }, err => {
              console.log(err);
            });
          } else {
            this.contractDataService.generateInvoiceOther().subscribe((res : any) => {
              res = JSON.parse(res);
              console.log(res.invoice);
              localStorage.setItem('invoice', JSON.stringify(res.invoice));

              this.contractDataService.postData().subscribe((res : any) => {
                this.contractDataService.clearContractDataAll();
                this.contractDataService.changeLoading({ loading: false });
                this.router.navigate(['/contracts']);
              }, err => {
                console.log(err);
              });


            }, err => {
              console.log(err);
            });
          }


        }
        else {
          this.NoVorschau = true;

          if (this.wegLogicData.bindungdatumsave) this.wegLogicData.datumRestoreBindung = new Date(this.wegLogicData.bindungdatumsave);
          if (this.wegLogicData.erstzulassungsave) this.wegLogicData.erstzulassungRestore = new Date(this.wegLogicData.erstzulassungsave);
          if (this.wegLogicData.uebergabedatumsave) this.wegLogicData.datumRestoreUebergabe = new Date(this.wegLogicData.uebergabedatumsave);

          //shim zurück bei WEG kaufpreise
          console.log('@@@@@ this.contractOption');
          console.log(this.contractOption);
          if (this.contractOption === 'WEG') {
            // 8261 no need
            // this.wegLogicData.parkingPlaces.forEach((parkingPlace, index) => { this.wegLogicData.parkingPlaces[index].kaufpreisinEUR = this.wegLogicData.parkingPlaces[index].kaufpreisRestore; });
          }
          // this.timerSub = interval(environment.savetime).subscribe((func => {
          //   this.saveDB();
          // }));
        }
      }
    );
  }

  private fixPrivateMasterdata() {
    if (this.contractData.masterData.sellersPrivate) {
      if (!(this.contractData.masterData.sellersPrivate.length === 0)) {
        if (!this.contractData.masterData.sellersPrivate[0].lastname) {
          this.contractData.masterData.sellersPrivate = undefined;
        }
      }
    }

    if (this.contractData.masterData.sellersCompany) {
      if (!(this.contractData.masterData.sellersCompany.length === 0)) {
        if (!this.contractData.masterData.sellersCompany[0].companyName) {
          this.contractData.masterData.sellersCompany = undefined;
        }
      }
    }

    if (this.contractData.masterData.buyersPrivate) {
      if (!(this.contractData.masterData.buyersPrivate.length === 0)) {
        if (!this.contractData.masterData.buyersPrivate[0].lastname) {
          this.contractData.masterData.buyersPrivate = undefined;
        }
      }
    }

    if (this.contractData.masterData.buyersCompany) {
      if (!(this.contractData.masterData.buyersCompany.length === 0)) {
        if (!this.contractData.masterData.buyersCompany[0].companyName) {
          this.contractData.masterData.buyersCompany = undefined;
        }
      }
    }


    // Selbes Zeug für Eigentümerpartner

    if (this.wegLogicData.EPList.buyersCompany) {
      if (!(this.wegLogicData.EPList.buyersCompany.length === 0)) {
        if (!this.wegLogicData.EPList.buyersCompany[0].companyName) {
          this.wegLogicData.EPList.buyersCompany = undefined;
        }
      }
    }

    if (this.wegLogicData.EPList.buyersPrivate) {
      if (!(this.wegLogicData.EPList.buyersPrivate.length === 0)) {
        if (!this.wegLogicData.EPList.buyersPrivate[0].lastname) {
          this.wegLogicData.EPList.buyersPrivate = undefined;
        }
      }
    }
  }

  private setAnteil(x) {
    let temp = 0;
    if (x.shareArDev) {
      const a = x.shareArDev as UntypedFormArray;
      a.controls.forEach(formgroup => {
        if (formgroup) {
          if (formgroup.value.anteil === '1') {
            temp++;
          }
          else {
            const tempar = formgroup.value.anteil.split('/');
            const frac = toNumber(tempar[0]) / toNumber(tempar[1]);
            if (frac >= 0 && frac <= 1) temp += frac;
            else throw new Error("Anteil muss zwischen 0 und 1 liegen");
          }
        }
      });
    }

    if (temp < 1 && temp > 0) x.calcanteil = new Fraction(temp).toFraction(true);
    else if (temp == 1) x.calcanteil = "1/1";
    else if (temp == 0) x.calcanteil = "-1";
    else throw new Error("Anteile zusammen müssen zwischen 0 und 1 liegen");

    return temp;
  }

  private setVerkaeuferanteile() {
    if (this.LSBOOL[0]) {
      let sum = 0;
      if (this.contractData.anteildata.l1.seller.sellersPrivate) this.contractData.anteildata.l1.seller.sellersPrivate.forEach(seller => { sum += this.setAnteil(seller); });
      if (this.contractData.anteildata.l1.seller.sellersCompany) this.contractData.anteildata.l1.seller.sellersCompany.forEach(seller => { sum += this.setAnteil(seller); });
      if (!(sum >= 0 && sum <= 1)) throw new Error("Anteile pro Liegenschaft müssen zusammen zwischen 0 und 1 liegen");
    }
    if (this.LSBOOL[1]) {
      let sum = 0;
      if (this.contractData.anteildata.l2.seller.sellersPrivate) this.contractData.anteildata.l2.seller.sellersPrivate.forEach(seller => { sum += this.setAnteil(seller); });
      if (this.contractData.anteildata.l2.seller.sellersCompany) this.contractData.anteildata.l2.seller.sellersCompany.forEach(seller => { sum += this.setAnteil(seller); });
      if (!(sum >= 0 && sum <= 1)) throw new Error("Anteile pro Liegenschaft müssen zusammen zwischen 0 und 1 liegen");
    }
    if (this.LSBOOL[2]) {
      let sum = 0;
      if (this.contractData.anteildata.l3.seller.sellersPrivate) this.contractData.anteildata.l3.seller.sellersPrivate.forEach(seller => { sum += this.setAnteil(seller); });
      if (this.contractData.anteildata.l3.seller.sellersCompany) this.contractData.anteildata.l3.seller.sellersCompany.forEach(seller => { sum += this.setAnteil(seller); });
      if (!(sum >= 0 && sum <= 1)) throw new Error("Anteile pro Liegenschaft müssen zusammen zwischen 0 und 1 liegen");
    }
    if (this.LSBOOL[3]) {
      let sum = 0;
      if (this.contractData.anteildata.l4.seller.sellersPrivate) this.contractData.anteildata.l4.seller.sellersPrivate.forEach(seller => { sum += this.setAnteil(seller); });
      if (this.contractData.anteildata.l4.seller.sellersCompany) this.contractData.anteildata.l4.seller.sellersCompany.forEach(seller => { sum += this.setAnteil(seller); });
      if (!(sum >= 0 && sum <= 1)) throw new Error("Anteile pro Liegenschaft müssen zusammen zwischen 0 und 1 liegen");
    }
  }

  private setKaeuferanteile(Option: string) {
    const privateBuyers = this.contractData.masterData.buyersPrivate.length;
    const companyBuyers = this.contractData.masterData.buyersCompany.length;
    const buyers = privateBuyers + companyBuyers;

    if (buyers > 1 && Option === 'Nein') //Ungleiche Teile
    {
      if (this.LSBOOL[0]) {
        let sum = 0;
        if (this.contractData.anteildata.l1.buyer.buyersPrivate) this.contractData.anteildata.l1.buyer.buyersPrivate.forEach(buyer => { sum += this.setAnteil(buyer); });
        if (this.contractData.anteildata.l1.buyer.buyersCompany) this.contractData.anteildata.l1.buyer.buyersCompany.forEach(buyer => { sum += this.setAnteil(buyer); });
        if (!(sum >= 0 && sum <= 1)) throw new Error("Anteile pro Liegenschaft müssen zusammen zwischen 0 und 1 liegen");
      }
      if (this.LSBOOL[1]) {
        let sum = 0;
        if (this.contractData.anteildata.l2.buyer.buyersPrivate) this.contractData.anteildata.l2.buyer.buyersPrivate.forEach(buyer => { sum += this.setAnteil(buyer); });
        if (this.contractData.anteildata.l2.buyer.buyersCompany) this.contractData.anteildata.l2.buyer.buyersCompany.forEach(buyer => { sum += this.setAnteil(buyer); });
        if (!(sum >= 0 && sum <= 1)) throw new Error("Anteile pro Liegenschaft müssen zusammen zwischen 0 und 1 liegen");
      }
      if (this.LSBOOL[2]) {
        let sum = 0;
        if (this.contractData.anteildata.l3.buyer.buyersPrivate) this.contractData.anteildata.l3.buyer.buyersPrivate.forEach(buyer => { sum += this.setAnteil(buyer); });
        if (this.contractData.anteildata.l3.buyer.buyersCompany) this.contractData.anteildata.l3.buyer.buyersCompany.forEach(buyer => { sum += this.setAnteil(buyer); });
        if (!(sum >= 0 && sum <= 1)) throw new Error("Anteile pro Liegenschaft müssen zusammen zwischen 0 und 1 liegen");
      }
      if (this.LSBOOL[3]) {
        let sum = 0;
        if (this.contractData.anteildata.l4.buyer.buyersPrivate) this.contractData.anteildata.l4.buyer.buyersPrivate.forEach(buyer => { sum += this.setAnteil(buyer); });
        if (this.contractData.anteildata.l4.buyer.buyersCompany) this.contractData.anteildata.l4.buyer.buyersCompany.forEach(buyer => { sum += this.setAnteil(buyer); });
        if (!(sum >= 0 && sum <= 1)) throw new Error("Anteile pro Liegenschaft müssen zusammen zwischen 0 und 1 liegen");
      }

    }
    if (buyers > 1 && Option === 'Ja') //Gleiche Teile
    {
      //Anteile pro Käufer = 1/Anzahl Käufer
      if (this.LSBOOL[0]) {
        if (this.contractData.anteildata.l1.buyer.buyersPrivate) this.contractData.anteildata.l1.buyer.buyersPrivate.forEach(buyer => { buyer.calcanteil = "1/" + buyers; });
        if (this.contractData.anteildata.l1.buyer.buyersCompany) this.contractData.anteildata.l1.buyer.buyersCompany.forEach(buyer => { buyer.calcanteil = "1/" + buyers; });
      }

      if (this.LSBOOL[1]) {
        if (this.contractData.anteildata.l2.buyer.buyersPrivate) this.contractData.anteildata.l2.buyer.buyersPrivate.forEach(buyer => { buyer.calcanteil = "1/" + buyers; });
        if (this.contractData.anteildata.l2.buyer.buyersCompany) this.contractData.anteildata.l2.buyer.buyersCompany.forEach(buyer => { buyer.calcanteil = "1/" + buyers; });
      }

      if (this.LSBOOL[2]) {
        if (this.contractData.anteildata.l3.buyer.buyersPrivate) this.contractData.anteildata.l3.buyer.buyersPrivate.forEach(buyer => { buyer.calcanteil = "1/" + buyers; });
        if (this.contractData.anteildata.l3.buyer.buyersCompany) this.contractData.anteildata.l3.buyer.buyersCompany.forEach(buyer => { buyer.calcanteil = "1/" + buyers; });
      }

      if (this.LSBOOL[3]) {
        if (this.contractData.anteildata.l4.buyer.buyersPrivate) this.contractData.anteildata.l4.buyer.buyersPrivate.forEach(buyer => { buyer.calcanteil = "1/" + buyers; });
        if (this.contractData.anteildata.l4.buyer.buyersCompany) this.contractData.anteildata.l4.buyer.buyersCompany.forEach(buyer => { buyer.calcanteil = "1/" + buyers; });
      }
    }
    if (buyers == 1) {
      //Anteil = 1/1
      if (privateBuyers == 1) {
        if (this.LSBOOL[0]) this.contractData.anteildata.l1.buyer.buyersPrivate[0].calcanteil = "1/1";
        if (this.LSBOOL[1]) this.contractData.anteildata.l2.buyer.buyersPrivate[0].calcanteil = "1/1";
        if (this.LSBOOL[2]) this.contractData.anteildata.l3.buyer.buyersPrivate[0].calcanteil = "1/1";
        if (this.LSBOOL[3]) this.contractData.anteildata.l4.buyer.buyersPrivate[0].calcanteil = "1/1";
      }
      else {
        if (this.LSBOOL[0]) this.contractData.anteildata.l1.buyer.buyersCompany[0].calcanteil = "1/1";
        if (this.LSBOOL[1]) this.contractData.anteildata.l2.buyer.buyersCompany[0].calcanteil = "1/1";
        if (this.LSBOOL[2]) this.contractData.anteildata.l3.buyer.buyersCompany[0].calcanteil = "1/1";
        if (this.LSBOOL[3]) this.contractData.anteildata.l4.buyer.buyersCompany[0].calcanteil = "1/1";
      }
    }
  }

  ShimCLNR() {
    console.log("ShimCLNR");
    if (this.wegLogicData.lastenLoeschen == 'Ja' && (this.contractOption === "BBL" || this.contractOption === "UBL")) {
      let j = this.wegLogicData.lastenLoeschenCLfdNr.length;
      this.contractData.anteildata.l1.lastenloeschenCLNR = [];
      this.contractData.anteildata.l1.lastenloeschenGrundbuch = [];
      this.contractData.anteildata.l1.lastenloeschenType = [];

      if (this.LSBOOL[1]) {
        this.contractData.anteildata.l2.lastenloeschenCLNR = [];
        this.contractData.anteildata.l2.lastenloeschenGrundbuch = [];
        this.contractData.anteildata.l2.lastenloeschenType = [];
      }

      if (this.LSBOOL[2]) {
        this.contractData.anteildata.l3.lastenloeschenCLNR = [];
        this.contractData.anteildata.l3.lastenloeschenGrundbuch = [];
        this.contractData.anteildata.l3.lastenloeschenType = [];
      }
      if (this.LSBOOL[3]) {
        this.contractData.anteildata.l4.lastenloeschenCLNR = [];
        this.contractData.anteildata.l4.lastenloeschenGrundbuch = [];
        this.contractData.anteildata.l4.lastenloeschenType = [];
      }

      this.wegLogicData.lastenloeschenKG = [];

      if (!this.LSBOOL[1]) {
        for (let i = 0; i < j; i++) {
          if (this.wegLogicData.lastenLoeschenCLfdNr[i]) this.contractData.anteildata.l1.lastenloeschenCLNR.push(this.wegLogicData.lastenLoeschenCLfdNr[i]);
          if (this.wegLogicData.lastenloeschenGrundbuch[i]) this.contractData.anteildata.l1.lastenloeschenGrundbuch.push(this.wegLogicData.lastenloeschenGrundbuch[i]);
          if (this.wegLogicData.lastenloeschenType[i]) this.contractData.anteildata.l1.lastenloeschenType.push(this.wegLogicData.lastenloeschenType[i]);
          this.wegLogicData.lastenloeschenKG.push(', Grundbuch ' + this.wegLogicData.immodetail.katastralGemeinde.KGNr + ' ' + this.wegLogicData.immodetail.katastralGemeinde.KGName);
        }
      }
      else {
        for (let i = 0; i < j; i++) {
          if (this.wegLogicData.lastenloeschenEzClnr[i] == this.wegLogicData.immodetail.einlageZahl) {
            if (this.wegLogicData.lastenLoeschenCLfdNr[i]) this.contractData.anteildata.l1.lastenloeschenCLNR.push(this.wegLogicData.lastenLoeschenCLfdNr[i]);
            if (this.wegLogicData.lastenloeschenGrundbuch[i]) this.contractData.anteildata.l1.lastenloeschenGrundbuch.push(this.wegLogicData.lastenloeschenGrundbuch[i]);
            if (this.wegLogicData.lastenloeschenType[i]) this.contractData.anteildata.l1.lastenloeschenType.push(this.wegLogicData.lastenloeschenType[i]);
            this.wegLogicData.lastenloeschenKG.push(', Grundbuch ' + this.wegLogicData.immodetail.katastralGemeinde.KGNr + ' ' + this.wegLogicData.immodetail.katastralGemeinde.KGName);
          }
          else if (this.LSBOOL[1] && this.wegLogicData.lastenloeschenEzClnr[i] == this.wegLogicData.l2immodetail.l2einlageZahl) {
            if (this.wegLogicData.lastenLoeschenCLfdNr[i]) this.contractData.anteildata.l2.lastenloeschenCLNR.push(this.wegLogicData.lastenLoeschenCLfdNr[i]);
            if (this.wegLogicData.lastenloeschenGrundbuch[i]) this.contractData.anteildata.l2.lastenloeschenGrundbuch.push(this.wegLogicData.lastenloeschenGrundbuch[i]);
            if (this.wegLogicData.lastenloeschenType[i]) this.contractData.anteildata.l2.lastenloeschenType.push(this.wegLogicData.lastenloeschenType[i]);
            this.wegLogicData.lastenloeschenKG.push(', Grundbuch ' + this.wegLogicData.l2immodetail.l2katastralGemeinde.KGNr + ' ' + this.wegLogicData.l2immodetail.l2katastralGemeinde.KGName);
          }
          else if (this.LSBOOL[2] && this.wegLogicData.lastenloeschenEzClnr[i] == this.wegLogicData.l3immodetail.l3einlageZahl) {
            if (this.wegLogicData.lastenLoeschenCLfdNr[i]) this.contractData.anteildata.l3.lastenloeschenCLNR.push(this.wegLogicData.lastenLoeschenCLfdNr[i]);
            if (this.wegLogicData.lastenloeschenGrundbuch[i]) this.contractData.anteildata.l3.lastenloeschenGrundbuch.push(this.wegLogicData.lastenloeschenGrundbuch[i]);
            if (this.wegLogicData.lastenloeschenType[i]) this.contractData.anteildata.l3.lastenloeschenType.push(this.wegLogicData.lastenloeschenType[i]);
            this.wegLogicData.lastenloeschenKG.push(', Grundbuch ' + this.wegLogicData.l3immodetail.l3katastralGemeinde.KGNr + ' ' + this.wegLogicData.l3immodetail.l3katastralGemeinde.KGName);
          }
          else if (this.LSBOOL[3] && this.wegLogicData.lastenloeschenEzClnr[i] == this.wegLogicData.l4immodetail.l4einlageZahl) {
            if (this.wegLogicData.lastenLoeschenCLfdNr[i]) this.contractData.anteildata.l4.lastenloeschenCLNR.push(this.wegLogicData.lastenLoeschenCLfdNr[i]);
            if (this.wegLogicData.lastenloeschenGrundbuch[i]) this.contractData.anteildata.l4.lastenloeschenGrundbuch.push(this.wegLogicData.lastenloeschenGrundbuch[i]);
            if (this.wegLogicData.lastenloeschenType[i]) this.contractData.anteildata.l4.lastenloeschenType.push(this.wegLogicData.lastenloeschenType[i]);
            this.wegLogicData.lastenloeschenKG.push(', Grundbuch ' + this.wegLogicData.l4immodetail.l4katastralGemeinde.KGNr + ' ' + this.wegLogicData.l4immodetail.l4katastralGemeinde.KGName);
          }
          else {
            this.contractData.anteildata.l1.lastenloeschenNichtZuordenbar = undefined;
            this.contractData.anteildata.l1.lastenloeschenNichtZuordenbar = true;
            if (this.wegLogicData.lastenLoeschenCLfdNr[i]) this.contractData.anteildata.l1.lastenloeschenCLNR.push(this.wegLogicData.lastenLoeschenCLfdNr[i]);
            if (this.wegLogicData.lastenloeschenGrundbuch[i]) this.contractData.anteildata.l1.lastenloeschenGrundbuch.push(this.wegLogicData.lastenloeschenGrundbuch[i]);
            if (this.wegLogicData.lastenloeschenType[i]) this.contractData.anteildata.l1.lastenloeschenType.push(this.wegLogicData.lastenloeschenType[i]);
            this.wegLogicData.lastenloeschenKG.push(', Grundbuch ' + this.wegLogicData.immodetail.katastralGemeinde.KGNr + ' ' + this.wegLogicData.immodetail.katastralGemeinde.KGName);
          }
          this.wegLogicData.lastenLoeschenCLfdNr[i] = this.wegLogicData.lastenLoeschenCLfdNr[i] + " in EZ " + this.wegLogicData.lastenloeschenEzClnr[i];
        }
      }
    }

    if (this.wegLogicData.uebertragung == 'Verbindlichkeiten werden mitübernommen' && this.LSBOOL[1]) {
      // FIX FOR GCCRM-7000, Point 7
      // for (let i = 0; i < j; i++) {
      //   this.wegLogicData.uebertragungCLfdNr[i] = this.wegLogicData.uebertragungCLfdNr[i] + " in EZ " + this.wegLogicData.uebertragungEzClnr[i];
      // }
    }
  }

  persistFormData() {
    console.log('in persistFormData');
    // const clone = JSON.parse(JSON.stringify(this.contractData.anteildata));
    // console.log(clone);

    if (this.contractData.contactData.contractOption === 'WEG') {
      if(this.immodetailComp) {
        this.wegLogicData.immodetail = this.immodetailComp.immodetail.value;
        this.wegLogicData.parkingPlaces = this.immodetailComp.parkingPlaces.value;
      } else {
        let immo = this.immodetailCompMietWeg.immodetail.value;
        console.log(immo);
        if(immo.einlageZahlObject && immo.einlageZahlObject.length) {
          immo['top']         = immo['einlageZahlObject'][0].top;
          immo['anteil']      = immo['einlageZahlObject'][0].anteil;
          immo['einlageZahl'] = immo['einlageZahlObject'][0].einlageZahl;
          immo['bLaufendeNrAr'] = immo['einlageZahlObject'][0].bLaufendeNrAr;
          immo['grundstuecksnummerAr'] = immo['einlageZahlObject'][0].grundstuecksnummerAr;
          if(immo['einlageZahlObject'][0]['parkingPlaceCnt']) {
            immo['parkingPlaceCnt']  = immo['einlageZahlObject'][0]['parkingPlaceCnt'];
          }
        }
        this.wegLogicData.immodetail = immo;
        // 8261 DONE on contract service
        // this.wegLogicData.parkingPlaces = this.immodetailCompMietWeg.parkingPlaces.value;
      }
    }

    if (this.contractData.contactData.contractOption === 'BBL' || this.contractData.contactData.contractOption === 'UBL') {
      if (this.kaeuferanteileBOOL) {
        if (this.LSBOOL[0]) {
          if (this.contractData.anteildata.l1.buyer.buyersPrivate)
          this.contractData.anteildata.l1.buyer.buyersPrivate.forEach((buyer, index) => {
            buyer.shareAr = [];
            buyer.shareAr = this.LSimmodetailcomp.contractData.anteildata.l1.buyer.buyersPrivate[index].shareArDev.value;
            //this.kaeuferanteilecomp.contractData.anteildata.l1.buyer.buyersPrivate[index].shareArDev = undefined;
          });

          if (this.contractData.anteildata.l1.buyer.buyersCompany) this.contractData.anteildata.l1.buyer.buyersCompany.forEach((buyer, index) => {
            buyer.shareAr = [];
            buyer.shareAr = this.LSimmodetailcomp.contractData.anteildata.l1.buyer.buyersCompany[index].shareArDev.value;
            //this.kaeuferanteilecomp.contractData.anteildata.l1.buyer.buyersCompany[index].shareArDev = undefined;
          });
        }
        if (this.LSBOOL[1]) {
          if (this.contractData.anteildata.l2.buyer.buyersPrivate) this.contractData.anteildata.l2.buyer.buyersPrivate.forEach((buyer, index) => {
            buyer.shareAr = [];
            buyer.shareAr = this.LSimmodetailcomp.contractData.anteildata.l2.buyer.buyersPrivate[index].shareArDev.value;
            //this.kaeuferanteilecomp.contractData.anteildata.l2.buyer.buyersPrivate[index].shareArDev = undefined;
          });

          if (this.contractData.anteildata.l2.buyer.buyersCompany) this.contractData.anteildata.l2.buyer.buyersCompany.forEach((buyer, index) => {
            buyer.shareAr = [];
            buyer.shareAr = this.LSimmodetailcomp.contractData.anteildata.l2.buyer.buyersCompany[index].shareArDev.value;
            //this.kaeuferanteilecomp.contractData.anteildata.l2.buyer.buyersCompany[index].shareArDev = undefined;
          });
        }
        if (this.LSBOOL[2]) {
          if (this.contractData.anteildata.l3.buyer.buyersPrivate) this.contractData.anteildata.l3.buyer.buyersPrivate.forEach((buyer, index) => {
            buyer.shareAr = [];
            buyer.shareAr = this.LSimmodetailcomp.contractData.anteildata.l3.buyer.buyersPrivate[index].shareArDev.value;
            //this.kaeuferanteilecomp.contractData.anteildata.l3.buyer.buyersPrivate[index].shareArDev = undefined;
          });

          if (this.contractData.anteildata.l3.buyer.buyersCompany) this.contractData.anteildata.l3.buyer.buyersCompany.forEach((buyer, index) => {
            buyer.shareAr = [];
            buyer.shareAr = this.LSimmodetailcomp.contractData.anteildata.l3.buyer.buyersCompany[index].shareArDev.value;
            //this.kaeuferanteilecomp.contractData.anteildata.l3.buyer.buyersCompany[index].shareArDev = undefined;
          });
        }
        if (this.LSBOOL[3]) {
          if (this.contractData.anteildata.l4.buyer.buyersPrivate) this.contractData.anteildata.l4.buyer.buyersPrivate.forEach((buyer, index) => {
            buyer.shareAr = [];
            buyer.shareAr = this.LSimmodetailcomp.contractData.anteildata.l4.buyer.buyersPrivate[index].shareArDev.value;
            //this.kaeuferanteilecomp.contractData.anteildata.l4.buyer.buyersPrivate[index].shareArDev = undefined;
          });

          if (this.contractData.anteildata.l4.buyer.buyersCompany) this.contractData.anteildata.l4.buyer.buyersCompany.forEach((buyer, index) => {
            buyer.shareAr = [];
            buyer.shareAr = this.LSimmodetailcomp.contractData.anteildata.l4.buyer.buyersCompany[index].shareArDev.value;
            //this.kaeuferanteilecomp.contractData.anteildata.l4.buyer.buyersCompany[index].shareArDev = undefined;
          });
        }
      }
      if (this.LSBOOL[0]) {
        this.wegLogicData.immodetail = this.LSimmodetailcomp.immodata.get('immodetail').value;

        if (this.contractData.anteildata.l1.seller.sellersPrivate) this.contractData.anteildata.l1.seller.sellersPrivate.forEach((seller, index) => {
          seller.shareAr = [];
          seller.shareAr = this.LSimmodetailcomp.contractData.anteildata.l1.seller.sellersPrivate[index].shareArDev.value;
          //this.LSimmodetailcomp.contractData.anteildata.l1.seller.sellersPrivate[index].shareArDev = undefined;
        });

        if (this.contractData.anteildata.l1.seller.sellersCompany) this.contractData.anteildata.l1.seller.sellersCompany.forEach((seller, index) => {
          seller.shareAr = [];
          seller.shareAr = this.LSimmodetailcomp.contractData.anteildata.l1.seller.sellersCompany[index].shareArDev.value;
          //this.LSimmodetailcomp.contractData.anteildata.l1.seller.sellersCompany[index].shareArDev = undefined;
        });
      }
      if (this.LSBOOL[1]) {
        this.wegLogicData.l2immodetail = this.LSimmodetailcomp.immodata.get('l2immodetail').value;

        if (this.contractData.anteildata.l2.seller.sellersPrivate) this.contractData.anteildata.l2.seller.sellersPrivate.forEach((seller, index) => {
          seller.shareAr = [];
          seller.shareAr = this.LSimmodetailcomp.contractData.anteildata.l2.seller.sellersPrivate[index].shareArDev.value;
          //this.LSimmodetailcomp.contractData.anteildata.l2.seller.sellersPrivate[index].shareArDev = undefined;
        });

        if (this.contractData.anteildata.l2.seller.sellersCompany) this.contractData.anteildata.l2.seller.sellersCompany.forEach((seller, index) => {
          seller.shareAr = [];
          seller.shareAr = this.LSimmodetailcomp.contractData.anteildata.l2.seller.sellersCompany[index].shareArDev.value;
          //this.LSimmodetailcomp.contractData.anteildata.l2.seller.sellersCompany[index].shareArDev = undefined;
        });
      }
      if (this.LSBOOL[2]) {
        this.wegLogicData.l3immodetail = this.LSimmodetailcomp.immodata.get('l3immodetail').value;

        if (this.contractData.anteildata.l3.seller.sellersPrivate) this.contractData.anteildata.l3.seller.sellersPrivate.forEach((seller, index) => {
          seller.shareAr = [];
          seller.shareAr = this.LSimmodetailcomp.contractData.anteildata.l3.seller.sellersPrivate[index].shareArDev.value;
          //this.LSimmodetailcomp.contractData.anteildata.l3.seller.sellersPrivate[index].shareArDev = undefined;
        });

        if (this.contractData.anteildata.l3.seller.sellersCompany) this.contractData.anteildata.l3.seller.sellersCompany.forEach((seller, index) => {
          seller.shareAr = [];
          seller.shareAr = this.LSimmodetailcomp.contractData.anteildata.l3.seller.sellersCompany[index].shareArDev.value;
          //this.LSimmodetailcomp.contractData.anteildata.l3.seller.sellersCompany[index].shareArDev = undefined;
        });
      }
      if (this.LSBOOL[3]) {
        this.wegLogicData.l4immodetail = this.LSimmodetailcomp.immodata.get('l4immodetail').value;

        if (this.contractData.anteildata.l4.seller.sellersPrivate) this.contractData.anteildata.l4.seller.sellersPrivate.forEach((seller, index) => {
          seller.shareAr = [];
          seller.shareAr = this.LSimmodetailcomp.contractData.anteildata.l4.seller.sellersPrivate[index].shareArDev.value;
          //this.LSimmodetailcomp.contractData.anteildata.l4.seller.sellersPrivate[index].shareArDev = undefined;
        });

        if (this.contractData.anteildata.l4.seller.sellersCompany) this.contractData.anteildata.l4.seller.sellersCompany.forEach((seller, index) => {
          seller.shareAr = [];
          seller.shareAr = this.LSimmodetailcomp.contractData.anteildata.l4.seller.sellersCompany[index].shareArDev.value;
          //this.LSimmodetailcomp.contractData.anteildata.l4.seller.sellersCompany[index].shareArDev = undefined;
        });
      }
    }

    if (!this.KVzwischenAngehoerigenVisible) {
      this.wegLogicData.KVzwischenAngehoerigen = 'Nein';
    }
    console.log(this.contractData.anteildata);
  }

  changeListener($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = () => {
      this.wegLogicData.attachment = myReader.result;
    };
    myReader.readAsDataURL(file);
  }


  // Hier kommen die Hilfen:
  openImmoSteuerDlg(): void {
    this.immoSteuerdialog.open(DlgImmoSteuerComponent);
  }

  openLoeschenSonstigerLasten(): void {
    this.sonstigeLastenDlg.open(DlgLoeschenSonstigerLasterComponent);
  }

  openGeldlastenDlg(): void {
    this.geldLastenDlg.open(DlgGeldlastenComponent);
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  transformCurrencyToNumber(input) {
    if (input && input !== undefined && input !== '') {
      if (typeof input == 'number') {
        input = input.toString();
        console.log(input);
      }
      const strInput = input.replaceAll('.', '');
      const strInput2 = strInput.replaceAll(',', '.');
      const toNumber = parseFloat(strInput2);
      return toNumber;
    }
    return input;
  }

  initEPData() {
    this.EPForm = this.formBuilder.group({
      buyersPrivate: this.formBuilder.array([]),
      buyersCompany: this.formBuilder.array([])
    });
  }

  initRightData() {
    this.RightForm = this.formBuilder.group({
      rightsPrivate: this.formBuilder.array([]),
      rightsCompany: this.formBuilder.array([])
    });
  }

  onSelectionBuyerTypeChanged(change: MatRadioChange) {
    switch (change.value) {
      case 'person': {
        this.buyersPrivateFormArray.push(this.initBuyerPrivateEmpty());
        this.buyersCompanyFormArray.controls.splice(0);
        break;
      }
      case 'company': {
        this.buyersCompanyFormArray.push(this.initBuyerCompanyEmpty());
        this.buyersPrivateFormArray.controls.splice(0);
        break;
      }
    }
  }

  addBuyer(): void {
    switch (this.selectedBuyerType) {
      case 'person': {
        this.buyersPrivateFormArray.push(this.initBuyerPrivateEmpty());
        break;
      }
      case 'company': {
        this.buyersCompanyFormArray.push(this.initBuyerCompanyEmpty());
      }
    }
  }

  LastenLoeschenForm: UntypedFormGroup;

  removeLastaenLoeschenCompany(index: number) {
    this.rightsCompanyFormArray.removeAt(index);
  }

  removeLastenLoeschentPrivate(index: number) {
    this.rightsPrivateFormArray.removeAt(index);
  }

  addLastenLoeschenOwnerComplex(buyerType): void {
    switch (buyerType) {
      case 'person': {
        this.LastenLoeschenPrivateFormArray.push(this.initBuyerPrivateEmpty());
        break;
      }
      case 'company': {
        this.LastenLoeschenCompanyFormArray.push(this.initBuyerCompanyEmpty());
      }
    }
  }

  refreshLastenLoeschenOwners(type: boolean) {
    if (this.contractData.primeType != 'kfz') {
      if (this.LastenLoeschenForm.valid) {
        this.contractData.masterData.dritte.rightPersonList = this.contractData.masterData.dritte.rightPersonList.concat(this.LastenLoeschenForm.value.LastenLoeschenPrivate);
        this.contractData.masterData.dritte.rightCompanyList = this.contractData.masterData.dritte.rightCompanyList.concat(this.LastenLoeschenForm.value.LastenLoeschenCompany);
        this.wegLogicData.LastenLoeschenDritteBeteiligte = {};
        this.wegLogicData.LastenLoeschenDritteBeteiligte.rightPersonList = this.LastenLoeschenForm.value.LastenLoeschenPrivate;
        this.wegLogicData.LastenLoeschenDritteBeteiligte.rightCompanyList = this.LastenLoeschenForm.value.LastenLoeschenCompany;
        // if (type) this.openSnackBar('Erfolgreich gespeichert.', 'schließen');
      }
      else {
        this.openSnackBar('Bitte alles ausfüllen um zu speichern.', 'schließen');
      }
    }
  }

  get LastenLoeschenPrivateFormArray(): UntypedFormArray {
    return this.LastenLoeschenForm.get('LastenLoeschenPrivate') as UntypedFormArray;
  }

  get LastenLoeschenCompanyFormArray(): UntypedFormArray {
    return this.LastenLoeschenForm.get('LastenLoeschenCompany') as UntypedFormArray;
  }

  private initBuyerPrivateEmpty(): UntypedFormGroup {
    return this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      formatedBirthday: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      postalCode: ['', Validators.required]
    });
  }

  private initBuyerCompanyEmpty(): UntypedFormGroup {
    return this.formBuilder.group({
      companyName: ['', Validators.required],
      companiesRegisterNumber: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      postalCode: ['', Validators.required]
    });
  }

  removeBuyerCompany(index: number) {
    this.buyersCompanyFormArray.removeAt(index);
  }

  removeBuyerPrivate(index: number) {
    this.buyersPrivateFormArray.removeAt(index);
  }

  removeLastenLoeschenCompany(index: number) {
    this.LastenLoeschenCompanyFormArray.removeAt(index);
  }

  removeLastenLoeschenPrivate(index: number) {
    this.LastenLoeschenPrivateFormArray.removeAt(index);
  }

  adsfBOOL: boolean;

  onlyOneBuyerAndSeller() {

    let bP = 0, bC = 0, sP = 0, sC = 0;
    if (this.contractData.masterData.buyersPrivate) {
      bP = this.contractData.masterData.buyersPrivate.length;
    }

    if (this.contractData.masterData.buyersCompany) {
      bC = this.contractData.masterData.buyersCompany.length;
    }

    if (this.contractData.masterData.sellersPrivate) {
      sP = this.contractData.masterData.sellersPrivate.length;
    }

    if (this.contractData.masterData.sellersCompany) {
      sC = this.contractData.masterData.sellersCompany.length;
    }

    const bL = bP + bC;
    const sL = sP + sC;

    if ((bL === 1) && (sL === 1)) {
      if (!this.adsfBOOL) {
        this.adsfBOOL = true;
      }
      return true;
    } else {
      if (!this.adsfBOOL) {
        this.adsfBOOL = true;
      }
      return false;
    }

  }
  onlyCompaniesAsBuyerAndSeller() {

    let bP, bC, sP, sC, sE;
    if (this.contractData.masterData.buyersPrivate) {
      bP = this.contractData.masterData.buyersPrivate.length;
    }

    if (this.contractData.masterData.buyersCompany) {
      bC = this.contractData.masterData.buyersCompany.length;
    }

    if (this.contractData.masterData.sellersPrivate) {
      sP = this.contractData.masterData.sellersPrivate.length;
    }

    if (this.contractData.masterData.sellersCompany) {
      sC = this.contractData.masterData.sellersCompany.length;
    }

    if (this.contractData.masterData.sellersEstate) {
      sE = this.contractData.masterData.sellersEstate.length;
    }


    if ((sP === 0 && bP === 0) && (sC >= 1 || bC >= 1)) {
      return true;
    } else {
      return false;
    }
  }

  onlyCompaniesAsSeller() {

    let bP, bC, sP, sC, sE;
    if (this.contractData.masterData.buyersPrivate) {
      bP = this.contractData.masterData.buyersPrivate.length;
    }

    if (this.contractData.masterData.buyersCompany) {
      bC = this.contractData.masterData.buyersCompany.length;
    }

    if (this.contractData.masterData.sellersPrivate) {
      sP = this.contractData.masterData.sellersPrivate.length;
    }

    if (this.contractData.masterData.sellersCompany) {
      sC = this.contractData.masterData.sellersCompany.length;
    }

    if (this.contractData.masterData.sellersEstate) {
      sE = this.contractData.masterData.sellersEstate.length;
    }

    if (sC >= 1 || sE >= 1) {
      return true;
    } else {
      return false;
    }
  }

  only2Sellers() {
    let bP, bC, sP, sC;
    if (this.contractData.masterData.buyersPrivate) {
      bP = this.contractData.masterData.buyersPrivate.length;
    }

    if (this.contractData.masterData.buyersCompany) {
      bC = this.contractData.masterData.buyersCompany.length;
    }

    if (this.contractData.masterData.sellersPrivate) {
      sP = this.contractData.masterData.sellersPrivate.length;
    }

    if (this.contractData.masterData.sellersCompany) {
      sC = this.contractData.masterData.sellersCompany.length;
    }


    if ((sP === 2 || sC === 2) || (sP === 1 && sC === 1)) {
      return true;
    } else {
      return false;
    }
  }

  checkEPListMaxOne() {
    if (this.wegLogicData.EPList) {
      if (this.wegLogicData.EPList.length < 1) {
        return true;
      }
    }
    return false;
  }

  public naheAngehorigeBuV() {
    // if(this.contractData.masterData.buyersPrivate.length )
  }

  openWidmungDlg(): void {
    this.WidmungDlg.open(DlgWidmungComponent);
  }

  bewillChange(e) {
    console.log(e);
    if(e.value == 'Nein') {
      this.wegLogicData.denkmalschutz = undefined;
    }
  }

}
