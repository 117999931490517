export const environment = {
  mode: 'live',
  production: true,
  savetime:10000,
  password: "!ens2020$",
  FrontEndUrl:"https://portal.vertragsexpert.com",
  NodeAppUrl:"https://portalapi.vertragsexpert.com",
  dburl:"https://vertragsexpert.com/wp-json/acf/v3/posts/",
  stripe_public_key:"pk_live_51PkMog1075NLVAfPOZ7yx1AErj7HS9B505r6Xt7RBILEa493zJV0fd77e77vsiqy06hdpJGdwMM4PjeJLLVa9G5400ugX2Kkvl"
};
