<!--
Datepicker: Jahr, Monat, Tag im Vertrag -> 2019-05-08 im Vertrag, im Userinterface normal österreichisch
-->
<form [formGroup]="parent">
  <div class="full-grid-tile">
    <mat-grid-list [cols]="breakpoint" [rowHeight]="rowHeight" class="two-cols">
      <mat-grid-tile>
        <mat-form-field appearance="none" class="no-padding no-line" [ngClass]="{'error':!parent.controls['firstname'].valid && submitted }">
          <mat-label>Vorname (inkl. Titel und Zweitnamen falls vorhanden)</mat-label>
          <input matInput required formControlName="firstname"
            name="firstname" pattern="^[^\s]+(\s+[^\s]+)*$" appTrim/>
        </mat-form-field>
        <mat-form-field appearance="none" class="no-padding no-line" [ngClass]="{'error':!parent.controls['lastname'].valid && submitted }">
          <mat-label>Nachname</mat-label>
          <input matInput required formControlName="lastname" pattern="^[^\s]+(\s+[^\s]+)*$" appTrim/>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list [cols]="breakpoint" [rowHeight]="rowHeight" class="two-cols">
      <mat-grid-tile>
        <mat-form-field appearance="none" class="no-padding no-line datePickerField" [ngClass]="{'error': !parent.controls['formatedBirthday'].valid  && submitted }">
          <mat-label>Geburtstag (dd.mm.yyyy)</mat-label>
          <input matInput [matDatepicker]="picker" required formControlName="formatedBirthday"/>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="none" class="no-padding no-line" [ngClass]="{'error':!parent.controls['address'].valid && submitted }">
          <mat-label>Straße + Hausnummer</mat-label>
          <input matInput required formControlName="address" pattern="^[^\s]+(\s+[^\s]+)*$" appTrim/>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list [cols]="breakpoint" [rowHeight]="rowHeight" class="two-cols">
      <mat-grid-tile>
        <mat-form-field appearance="none" class="no-padding no-line" [ngClass]="{'error':!parent.controls['postalCode'].valid && submitted }">
          <mat-label>PLZ</mat-label>
          <input matInput maxlength="5" required formControlName="postalCode" pattern="^[^\s]+(\s+[^\s]+)*$" appTrim/>
        </mat-form-field>
        <mat-form-field appearance="none" class="no-padding no-line" [ngClass]="{'error':!parent.controls['city'].valid && submitted }">
          <mat-label>Ort</mat-label>
          <input matInput required formControlName="city" pattern="^[^\s]+(\s+[^\s]+)*$" appTrim/>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list [cols]="breakpoint" [rowHeight]="rowHeight" class="two-cols">
      <mat-grid-tile>
        <mat-form-field appearance="none" class="no-padding no-line" [ngClass]="{'error':(!parent.controls['beteiligungsquote'].valid || superParent.errors?.sharesError) && submitted }">
          <mat-label>Beteiligungsquote</mat-label>
          <input matInput required formControlName="beteiligungsquote" pattern="^[^\s]+(\s+[^\s]+)*$" appTrim/>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</form>
