<div id="{{'hauptdiv' + IDIN}}" class="radio-left-right">
  <div class="col-12">
    <div class="yellow-line-2 mt-2 mb-2"></div>
  </div>

  <div class="row">
    <div class="col-6">
      <p>
        <label>Werden sonstige Rechte eingeräumt?</label>
      </p>
    </div>
    <div class="col-6">
      <mat-radio-group required aria-labelledby="example-radio-group-label" class="example-radio-group"
        [(ngModel)]="SonstigeRechteData.frageSonstigeRechte" id="{{'frageSonstigeRechte' + IDIN}}"
        name="{{'frageSonstigeRechte' + IDIN}}" [ngModelOptions]="{standalone: true}" [class.validation-error]="submitted">
        <mat-radio-button class="example-radio-button" *ngFor="let frageSonstigeRechte of frageSonstigeRechteOptionen"
          [value]="frageSonstigeRechte">
          {{frageSonstigeRechte}}&emsp;
        </mat-radio-button>&emsp;
      </mat-radio-group>
    </div>
  </div>

  <div *ngIf="SonstigeRechteData.frageSonstigeRechte === 'Ja'">
    <div class="no-grid radio-left-right">
      <div class="row">
        <div class="col-6">
          <p>
            <label>Werden Personen Rechte eingeräumt, deren Daten bislang nicht erfasst wurden?</label>
          </p>
        </div>
        <div class="col-6">
          <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
            [(ngModel)]="SonstigeRechteData.frageRechteDritter" id="{{'frageRechteDritter' + IDIN}}"
            name="{{'frageRechteDritter' + IDIN}}" [ngModelOptions]="{standalone: true}">
            <mat-radio-button class="example-radio-button" *ngFor="let frageRechteDritter of frageSonstigeRechteOptionen"
              [value]="frageRechteDritter">
              {{frageRechteDritter}}&emsp;
            </mat-radio-button>&emsp;
          </mat-radio-group>
        </div>
      </div>

      <div *ngIf="SonstigeRechteData.frageRechteDritter == 'Ja'">
        <h3>Rechteinhaber für sonstige Rechte:</h3>
        <div style="width:80% !important; margin: auto !important">
          <div class="text-left">
            <label>
              Geben Sie die Daten des Rechteinhabers ein:
            </label>
          </div>
          <br>
          <div style="text-align: left;">
            <button class="small-height-button" mat-raised-button color="primary" (click)="addRightOwnerComplex('person')">
              Privatperson hinzufügen
            </button>
            <button class="small-height-button" mat-raised-button color="primary" (click)="addRightOwnerComplex('company')">
              Firma hinzufügen
            </button>
          </div>


          <div *ngFor="let buyer of rightsPrivateFormArray.controls; let i = index">
            <h4 class="title-with-icon">Rechteinhaber Privatperson {{i + 1}}
              <fa-icon [icon]="faTimes" class="icon" (click)="removeRightPrivate(i)"></fa-icon>
            </h4>
            <app-person-data-form [parent]="buyer"></app-person-data-form>
          </div>

          <div *ngFor="let buyer of rightsCompanyFormArray.controls; let i = index">
            <h4 class="title-with-icon">Rechteinhaber Firma {{i + 1}}
              <fa-icon [icon]="faTimes" class="icon" (click)="removeRightCompany(i)"></fa-icon>
            </h4>
            <app-company-data-form [parent]="buyer"></app-company-data-form>
          </div>

          <div style="text-align: left;">
            <button mat-raised-button class="yellow-bgg small-height-button"
              (click)="refreshRightOwners()">
              Rechteinhaber speichern&nbsp;
              <fa-icon style="color: inherit; background-color: inherit;"
                [icon]="faSave">
              </fa-icon>
            </button>
          </div>
          <br />
        </div>
      </div>
    </div>

    <div *ngIf="this.contractData.contactData.contractOption !== 'UBL'" class="no-grid">
      <div class="row">
        <div class="col-6">
          <p>
            <label>Wird ein Wohnrecht eingeräumt?</label>
          </p>
        </div>
        <div class="col-6">
          <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
            id="{{'wohnrechtid' + IDIN}}" [(ngModel)]="SonstigeRechteData.frageWohnrecht" name="{{'frageWohnrecht' + IDIN}}"
            [ngModelOptions]="{standalone: true}">
            <mat-radio-button class="example-radio-button" *ngFor="let frageWohnrecht of frageSonstigeRechteOptionen"
              [value]="frageWohnrecht">
              {{frageWohnrecht}}&emsp;
            </mat-radio-button>&emsp;
          </mat-radio-group>
        </div>
      </div>

      <div *ngIf="this.SonstigeRechteData.frageWohnrecht == 'Ja'">
        <div class="radio-left-right">
          <div class="row">
            <div class="col-6">
              <p>
                <label>Was ist vom Wohnrecht umfasst?</label>
              </p>
            </div>
            <div class="col-6">
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                id="{{'frageSonstigeRechte' + IDIN}}" [(ngModel)]="SonstigeRechteData.wohnrecht.option"
                name="{{'wohnrecht' + IDIN}}" [ngModelOptions]="{standalone: true}">
                <mat-radio-button class="example-radio-button" *ngFor="let wohnrecht of wohnrechtOptionen"
                  [value]="wohnrecht.value">
                  {{wohnrecht.viewValue}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
        <div *ngIf="this.SonstigeRechteData.wohnrecht.option == 'teile'" class="half-field">
          <br>
          <mat-form-field appearance="none" class="no-padding no-line rechtewidth" [class.validation-error]="submitted">
            <mat-label>Vom Wohnrecht umfasst sind</mat-label>
            <textarea required matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"
              cdkAutosizeMaxRows="8" [(ngModel)]="SonstigeRechteData.wohnrecht.freitext" name="wohnrechttext"
              [ngModelOptions]="{standalone: true}" pattern="^[^\s]+(\s+[^\s]+)*$" appTrim>
            </textarea>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="this.SonstigeRechteData.frageWohnrecht == 'Ja'" class="half-field">
        <label>Wem wird das Recht eingeräumt?</label>
        <br>
        <mat-form-field appearance="none" class="no-padding no-line" *ngIf="contractData.masterData.dritte.rightPersonList.length > 0">
          <mat-label>Privatperson</mat-label>
          <mat-select [(ngModel)]="this.SonstigeRechteData.wohnrecht.personArray" multiple name="wohnrechtperson"
            [ngModelOptions]="{standalone: true}">
            <mat-option *ngFor="let rightOwner of contractData.masterData.dritte.rightPersonList" [value]="rightOwner">
              {{rightOwner.firstname + ' ' + rightOwner.lastname}}</mat-option>
          </mat-select>
        </mat-form-field>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <mat-form-field appearance="none" class="no-padding no-line"
          *ngIf="contractData.masterData.dritte.rightCompanyList.length > 0">
          <mat-label>Firma</mat-label>
          <mat-select [(ngModel)]="this.SonstigeRechteData.wohnrecht.companyArray" multiple name="wohnrechtcompany"
            [ngModelOptions]="{standalone: true}">
            <mat-option *ngFor="let rightOwner of contractData.masterData.dritte.rightCompanyList" [value]="rightOwner">
              {{rightOwner.companyName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="this.contractData.contactData.contractOption === 'UBL'" class="no-grid radio-left-right">
      <div class="row">
        <div class="col-6">
          <p>
            <label>Wird ein Nutzungsrecht eingeräumt?</label>
          </p>
        </div>
        <div class="col-6">
          <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
            id="{{'nutzungsrecht' + IDIN}}" [(ngModel)]="SonstigeRechteData.frageWohnrecht"
            name="{{'frageWohnrecht' + IDIN}}" [ngModelOptions]="{standalone: true}">
            <mat-radio-button class="example-radio-button" *ngFor="let frageWohnrecht of frageSonstigeRechteOptionen"
              [value]="frageWohnrecht">
              {{frageWohnrecht}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <br />

      <div *ngIf="this.SonstigeRechteData.frageWohnrecht == 'Ja'">
        <div class="row">
          <div class="col-6">
            <p>
              <label>Was ist vom Nutzungsrecht umfasst?</label>
            </p>
          </div>
          <div class="col-6">
            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
              id="{{'wohnrechtOption' + IDIN}}" [(ngModel)]="SonstigeRechteData.wohnrecht.option"
              name="{{'wohnrecht' + IDIN}}" [ngModelOptions]="{standalone: true}">
              <mat-radio-button class="example-radio-button" *ngFor="let wohnrecht of nutzungsRechtOptionen"
                [value]="wohnrecht.value">
                {{wohnrecht.viewValue}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <br />
        <div *ngIf="this.SonstigeRechteData.wohnrecht.option == 'teile'" class="half-field">
          <br>
          <mat-form-field appearance="none" class="no-padding no-line rechtewidth" [class.validation-error]="submitted">
            <!--              <input required
          matInput
          placeholder="Vom Nutzungsrecht umfasst sind:" [(ngModel)]="SonstigeRechteData.wohnrecht.freitext" name="wohnrechttext"
        />-->
            <mat-label>Vom Nutzungsrecht umfasst sind</mat-label>
            <textarea required matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"
              cdkAutosizeMaxRows="8" [(ngModel)]="SonstigeRechteData.wohnrecht.freitext" name="wohnrechttext"
              [ngModelOptions]="{standalone: true}" pattern="^[^\s]+(\s+[^\s]+)*$" appTrim>
            </textarea>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="this.SonstigeRechteData.frageWohnrecht == 'Ja'" class="half-field">
        <label>Wem wird das Recht eingeräumt?</label>
        <br>
        <mat-form-field appearance="none" class="no-padding no-line"
          *ngIf="contractData.masterData.dritte.rightPersonList.length > 0">
          <mat-label>Privatperson</mat-label>
          <mat-select [(ngModel)]="this.SonstigeRechteData.wohnrecht.personArray" multiple name="wohnrechtperson"
            [ngModelOptions]="{standalone: true}">
            <mat-option *ngFor="let rightOwner of contractData.masterData.dritte.rightPersonList" [value]="rightOwner">
              {{rightOwner.firstname + ' ' + rightOwner.lastname}}</mat-option>
          </mat-select>
        </mat-form-field>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <mat-form-field appearance="none" class="no-padding no-line"
          *ngIf="contractData.masterData.dritte.rightCompanyList.length > 0">
          <mat-label>Firma</mat-label>
          <mat-select [(ngModel)]="this.SonstigeRechteData.wohnrecht.companyArray" multiple name="wohnrechtcompany"
            [ngModelOptions]="{standalone: true}">
            <mat-option *ngFor="let rightOwner of contractData.masterData.dritte.rightCompanyList" [value]="rightOwner">
              {{rightOwner.companyName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="no-grid radio-left-right">
      <div class="row">
        <div class="col-6">
          <p>
            <label>Wird ein Fruchtgenussrecht eingeräumt?</label>
          </p>
        </div>
        <div class="col-6">
          <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
            id="{{'fruchtgenussrecht' + IDIN}}" [(ngModel)]="SonstigeRechteData.frageFruchtgenussrecht"
            name="{{'frageFruchtgenussrecht' + IDIN}}" [ngModelOptions]="{standalone: true}">
            <mat-radio-button class="example-radio-button"
              *ngFor="let frageFruchtgenussrecht of frageSonstigeRechteOptionen" [value]="frageFruchtgenussrecht">
              {{frageFruchtgenussrecht}}&emsp;
            </mat-radio-button>&emsp;
          </mat-radio-group>
        </div>
      </div>

      <br />

      <div *ngIf="this.SonstigeRechteData.frageFruchtgenussrecht == 'Ja'" class="radio-left-right">
        <div class="row">
          <div class="col-6">
            <p>
              <label>Was ist vom Fruchtgenussrecht umfasst?</label>
            </p>
          </div>
          <div class="col-6">
            <mat-radio-group aria-labelledby="example-radio-group-label" style="float: left;" class="example-radio-group"
              [(ngModel)]="SonstigeRechteData.fruchtgenussrecht.option" name="fruchtgenussrecht"
              [ngModelOptions]="{standalone: true}">
              <mat-radio-button class="example-radio-button" *ngFor="let fruchtgenussrecht of fruchtgenussrechtOptionen"
                [value]="fruchtgenussrecht.value">
                {{fruchtgenussrecht.viewValue}}&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group>
          </div>
        </div>
        <br />
        <div *ngIf="this.SonstigeRechteData.fruchtgenussrecht.option == 'teile'" class="half-field">
          <mat-form-field appearance="none" class="no-padding no-line rechtewidth" [class.validation-error]="submitted">
            <mat-label>Vom Fruchtgenaussrecht umfasst sind</mat-label>
            <!--            <input required
        matInput
        placeholder="Vom Fruchtgenaussrecht umfasst sind:" [(ngModel)]="SonstigeRechteData.fruchtgenussrecht.freitext" name="fruchtgenusstext"
      />-->
            <textarea rows="3" required matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"
              cdkAutosizeMaxRows="3" [(ngModel)]="SonstigeRechteData.fruchtgenussrecht.freitext" name="fruchtgenusstext"
              [ngModelOptions]="{standalone: true}" pattern="^[^\s]+(\s+[^\s]+)*$" appTrim>
            </textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="half-field" *ngIf="this.SonstigeRechteData.frageFruchtgenussrecht == 'Ja'">
        <label>Wem wird das Recht eingeräumt?</label>
      </div>
      <div *ngIf="this.SonstigeRechteData.frageFruchtgenussrecht == 'Ja'" class="half-field">
        <mat-form-field appearance="none" class="no-padding no-line"
          *ngIf="contractData.masterData.dritte.rightPersonList.length > 0">
          <mat-label>Privatperson</mat-label>
          <mat-select [(ngModel)]="this.SonstigeRechteData.fruchtgenussrecht.personArray" multiple
            name="fruchtgenussrechtperson" [ngModelOptions]="{standalone: true}">
            <mat-option *ngFor="let rightOwner of contractData.masterData.dritte.rightPersonList" [value]="rightOwner">
              {{rightOwner.firstname + ' ' + rightOwner.lastname}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="none" class="no-padding no-line"
          *ngIf="contractData.masterData.dritte.rightCompanyList.length > 0">
          <mat-label>Firma</mat-label>
          <mat-select [(ngModel)]="this.SonstigeRechteData.fruchtgenussrecht.companyArray" multiple
            name="fruchtgenussrechtcompany" [ngModelOptions]="{standalone: true}">
            <mat-option *ngFor="let rightOwner of contractData.masterData.dritte.rightCompanyList" [value]="rightOwner">
              {{rightOwner.companyName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>

    <div class="no-grid radio-left-right">
      <div class="row">
        <div class="col-6">
          <p>
            <label>Soll ein Belastungs und/oder Veräusserungsverbot eingeräumt werden?</label>
          </p>
        </div>
        <div class="col-6">
          <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
            id="{{'frageBelastungsverbot' + IDIN}}" [(ngModel)]="SonstigeRechteData.frageBelastungsverbot"
            name="{{'frageBelastungsverbot' + IDIN}}" [ngModelOptions]="{standalone: true}">
            <mat-radio-button class="example-radio-button" *ngFor="let frageBelastungsverbot of frageSonstigeRechteOptionen"
              [value]="frageBelastungsverbot">
              {{frageBelastungsverbot}}&emsp;
            </mat-radio-button>&emsp;
          </mat-radio-group>
        </div>
      </div>
      <div *ngIf="this.SonstigeRechteData.frageBelastungsverbot == 'Ja'">
        <div class="row">
          <div class="col-6">
            <p>
              <label>Wie sieht das Belastungs und/oder Veräusserungsverbot aus?</label>
            </p>
          </div>
          <div class="col-6">
            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
              id="{{'BelastUVeraeussVerbotOption' + IDIN}}"
              [(ngModel)]="SonstigeRechteData.belastungsUndVeraeusserungsverbot.option" name="{{'belastungsverbot' + IDIN}}"
              [ngModelOptions]="{standalone: true}">
              <mat-radio-button class="example-radio-button"
                *ngFor="let belastungsUndVeraeusserungsverbot of belastungsUndVeraeusserungsverbotOptionen"
                [value]="belastungsUndVeraeusserungsverbot.value">
                {{belastungsUndVeraeusserungsverbot.viewValue}}&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group>
          </div>
        </div>
        <br>
        <br>
        <label>Wem wird das Recht eingeräumt?</label>
        <br>
        <mat-form-field appearance="none" class="no-padding no-line half-field"
          *ngIf="contractData.masterData.dritte.rightPersonList.length > 0">
          <mat-label>Privatperson</mat-label>
          <mat-select [(ngModel)]="this.SonstigeRechteData.belastungsUndVeraeusserungsverbot.personArray" multiple
            name="belastungsverbotperson" [ngModelOptions]="{standalone: true}">
            <mat-option *ngFor="let rightOwner of contractData.masterData.dritte.rightPersonList" [value]="rightOwner">
              {{rightOwner.firstname + ' ' + rightOwner.lastname}}</mat-option>
          </mat-select>
        </mat-form-field>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <mat-form-field appearance="none" class="no-padding no-line"
          *ngIf="contractData.masterData.dritte.rightCompanyList.length > 0">
          <mat-label>Firma</mat-label>
          <mat-select [(ngModel)]="this.SonstigeRechteData.belastungsUndVeraeusserungsverbot.companyArray" multiple
            name="belastungsverbotcompany" [ngModelOptions]="{standalone: true}">
            <mat-option *ngFor="let rightOwner of contractData.masterData.dritte.rightCompanyList" [value]="rightOwner">
              {{rightOwner.companyName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="this.SonstigeRechteData.frageBelastungsverbot == 'Ja'">
        <div class="row">
          <div class="col-6">
            <p>
              <label>
                Erfolgt die Rechtseinräumung zwischen Ehegatten, eingetragenen Partnern, Eltern und Kindern, Wahl- oder
                Pflegekindern oder deren Ehegatten oder eingetragenen Partnern?
              </label>
            </p>
          </div>
          <div class="col-6">
            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
              id="{{'BelastUVeraeussVerbotNaheAngehaerige' + IDIN}}"
              [(ngModel)]="SonstigeRechteData.belastungsUndVeraeusserungsverbot.naheAngehoerige"
              name="{{'belastungsverbot2' + IDIN}}" [ngModelOptions]="{standalone: true}">
              <mat-radio-button class="example-radio-button" *ngFor="let optionAngehoerige of naheAngehorigeOptionen"
                [value]="optionAngehoerige.value">
                {{optionAngehoerige.viewValue}}&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group>
          </div>
      </div>
    </div>

    <div id="{{'vorkaufsrecht' + IDIN}}" class="no-grid radio-left-right">
      <div class="row">
        <div class="col-6">
          <p>
            <label>Wird ein Vorkaufsrecht eingeräumt?</label>
          </p>
        </div>
        <div class="col-6">
          <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
            id="{{'frageVorkaufsrecht' + IDIN}}" [(ngModel)]="SonstigeRechteData.frageVorkaufsrecht"
            name="{{'frageVorkaufsrecht' + IDIN}}" [ngModelOptions]="{standalone: true}">
            <mat-radio-button class="example-radio-button" *ngFor="let frageVorkaufsrecht of frageSonstigeRechteOptionen"
              [value]="frageVorkaufsrecht">
              {{frageVorkaufsrecht}}&emsp;
            </mat-radio-button>&emsp;
          </mat-radio-group>
        </div>
      </div>

      <div *ngIf="this.SonstigeRechteData.frageVorkaufsrecht == 'Ja'">
        <div class="row">
          <div class="col-6">
            <p>
              <label>Welche Art des Vorkaufsrechtes soll verwendet werden?</label>
            </p>
          </div>
          <div class="col-6">
            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
              id="{{'vorverkaufsrechtOption' + IDIN}}" [(ngModel)]="SonstigeRechteData.vorkaufsrecht.option"
              name="{{'vorkaufsrecht' + IDIN}}" [ngModelOptions]="{standalone: true}">
              <mat-radio-button class="example-radio-button" *ngFor="let vorkaufsrecht of vorkaufsrechtOptionen"
                [value]="vorkaufsrecht.value">
                {{vorkaufsrecht.viewValue}}&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group>
          </div>
        </div>
        <br />
      </div>


      <div *ngIf="this.SonstigeRechteData.frageVorkaufsrecht == 'Ja'" class="half-field">
        <label>Wem wird das Recht eingeräumt?</label>
        <br>
        <mat-form-field appearance="none" class="no-padding no-line"
          *ngIf="contractData.masterData.dritte.rightPersonList.length > 0">
          <mat-label>Privatperson</mat-label>
          <mat-select [(ngModel)]="this.SonstigeRechteData.vorkaufsrecht.personArray" multiple
            name="vorkaufsrechtperson" [ngModelOptions]="{standalone: true}">
            <mat-option *ngFor="let rightOwner of contractData.masterData.dritte.rightPersonList" [value]="rightOwner">
              {{rightOwner.firstname + ' ' + rightOwner.lastname}}</mat-option>
          </mat-select>
        </mat-form-field>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <mat-form-field appearance="none" class="no-padding no-line"
          *ngIf="contractData.masterData.dritte.rightCompanyList.length > 0">
          <mat-label> Firma </mat-label>
          <mat-select [(ngModel)]="this.SonstigeRechteData.vorkaufsrecht.companyArray" multiple
            name="vorkaufsrechtcompany" [ngModelOptions]="{standalone: true}">
            <mat-option *ngFor="let rightOwner of contractData.masterData.dritte.rightCompanyList" [value]="rightOwner">
              {{rightOwner.companyName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div
        *ngIf="this.SonstigeRechteData.frageVorkaufsrecht == 'Ja' && this.SonstigeRechteData.vorkaufsrecht.option == 'alle'"  class="radio-left-right">
        <div class="row">
          <div class="col-6">
            <p>
              <label>Wie hoch soll der Kaufpreis im Fall des Eintritts des Vorkaufsfalls sein?</label>
            </p>
          </div>
          <div class="col-6">
            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
              id="{{'vorverkaufsrechtPreisoptionen' + IDIN}}" [(ngModel)]="SonstigeRechteData.vorkaufsrecht.preisoption"
              name="{{'vorkaufsrechtpreis' + IDIN}}" [ngModelOptions]="{standalone: true}">
              <mat-radio-button class="example-radio-button" *ngFor="let vorkaufsrechtpreis of vorkaufsrechtPreisOptionen"
                [value]="vorkaufsrechtpreis.value">
                {{vorkaufsrechtpreis.viewValue}}&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group>
          </div>
        </div>
      </div>
      <div
        *ngIf="SonstigeRechteData.vorkaufsrecht.preisoption == 'fixpreis' && SonstigeRechteData.vorkaufsrecht.option == 'alle' && this.SonstigeRechteData.frageVorkaufsrecht == 'Ja'" class="half-field">
        <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
          <mat-label>Wie hoch ist der Preis der Immobilie (in EUR)?</mat-label>
          <input required matInput [ngModel]="SonstigeRechteData.vorkaufsrecht.preisinEUR | looseCurrency:'EUR'"
            name="vorkaufsrechtPreisInEUR" [ngModelOptions]="{updateOn:'blur'}"
            (ngModelChange)="SonstigeRechteData.vorkaufsrecht.preisinEUR=$event" [ngModelOptions]="{standalone: true}" pattern="^[^\s]+(\s+[^\s]+)*$" appTrim>
        </mat-form-field>
      </div>

    </div>

    <div class="no-grid radio-left-right">
      <div class="row">
        <div class="col-6">
          <p>
            <label>Wird ein Wiederkaufsrecht eingeräumt?</label>
          </p>
        </div>
        <div class="col-6">
          <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
            id="{{'wiederkaufsrecht' + IDIN}}" [(ngModel)]="SonstigeRechteData.frageWiederkaufsrecht"
            name="{{'frageWiederkaufsrecht' + IDIN}}" [ngModelOptions]="{standalone: true}">
            <mat-radio-button class="example-radio-button" *ngFor="let frageWiederkaufsrecht of frageSonstigeRechteOptionen"
              [value]="frageWiederkaufsrecht">
              {{frageWiederkaufsrecht}}&emsp;
            </mat-radio-button>&emsp;
          </mat-radio-group><br>
        </div>
      </div>
      <br />


      <div *ngIf="this.SonstigeRechteData.frageWiederkaufsrecht == 'Ja'" class="half-field">
        <label>Wem wird das Recht eingeräumt?</label>
        <mat-form-field appearance="none" class="no-padding no-line"
          *ngIf="contractData.masterData.dritte.rightPersonList.length > 0">
          <mat-label> Privatperson</mat-label>
          <mat-select [(ngModel)]="this.SonstigeRechteData.wiederkaufsrecht.personArray" multiple
            name="wiederkaufsrechtperson" [ngModelOptions]="{standalone: true}">
            <mat-option *ngFor="let rightOwner of contractData.masterData.dritte.rightPersonList" [value]="rightOwner">
              {{rightOwner.firstname + ' ' + rightOwner.lastname}}</mat-option>
          </mat-select>
        </mat-form-field>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <mat-form-field appearance="none" class="no-padding no-line"
          *ngIf="contractData.masterData.dritte.rightCompanyList.length > 0">
          <mat-label> Firma </mat-label>
          <mat-select [(ngModel)]="this.SonstigeRechteData.wiederkaufsrecht.companyArray" multiple
            name="wiederkaufsrechtcompany" [ngModelOptions]="{standalone: true}">
            <mat-option *ngFor="let rightOwner of contractData.masterData.dritte.rightCompanyList" [value]="rightOwner">
              {{rightOwner.companyName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <br>
        <br>
        <label>Wann tritt der Wiederkaufsfall ein?</label>
        <mat-form-field appearance="none" class="no-padding no-line rechtewidth" [class.validation-error]="submitted">
          <mat-label>Der Wiederkaufsfall tritt ein, wenn</mat-label>
          <!--            <input required
        matInput
        placeholder="Der Wiederkaufsfall tritt ein, wenn:" [(ngModel)]="SonstigeRechteData.wiederkaufsrecht.text" name="wiederkaufsrechttext"
      />-->
          <textarea required matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="8" [(ngModel)]="SonstigeRechteData.wiederkaufsrecht.text" name="wiederkaufsrechttext"
            [ngModelOptions]="{standalone: true}" pattern="^[^\s]+(\s+[^\s]+)*$" appTrim>
          </textarea>
        </mat-form-field>
      </div>


      <div *ngIf="this.SonstigeRechteData.frageWiederkaufsrecht == 'Ja'" class="radio-left-right">
        <div class="row">
          <div class="col-6">
            <p>
              <label>Wie hoch soll der Kaufpreis im Fall des Eintritts des Wiederkauffalls sein?</label>
            </p>
          </div>
          <div class="col-6">
            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
              id="{{'wiederverkaufsrechtPreisoptionen' + IDIN}}"
              [(ngModel)]="SonstigeRechteData.wiederkaufsrecht.preisoption" name="{{'wiederkaufsrechtpreis' + IDIN}}"
              [ngModelOptions]="{standalone: true}">
              <mat-radio-button class="example-radio-button"
                *ngFor="let wiederkaufsrechtpreis of wiederkaufsrechtPreisOptionen" [value]="wiederkaufsrechtpreis.value">
                {{wiederkaufsrechtpreis.viewValue}}&emsp;
              </mat-radio-button>&emsp;
            </mat-radio-group><br>
          </div>
        </div>
        <br />
      </div>

      <div *ngIf="SonstigeRechteData.wiederkaufsrecht.preisoption == 'fixpreis'" class="half-field">
        <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
          <mat-label>Wie hoch ist der Preis der Immobilie (in EUR)?</mat-label>
          <input required matInput [ngModel]="SonstigeRechteData.wiederkaufsrecht.preisinEUR | looseCurrency:'EUR'"
            name="wiederkaufsrechtpreisinEUR" [ngModelOptions]="{updateOn:'blur'}"
            (ngModelChange)="SonstigeRechteData.wiederkaufsrecht.preisinEUR=$event"
            [ngModelOptions]="{standalone: true}" pattern="^[^\s]+(\s+[^\s]+)*$" appTrim>
        </mat-form-field>
      </div>

    </div>
  </div>
</div>
