<div class="container mb-5 wegLogic">
  <!-- <h3>Allgemeines </h3> -->
  <form class="example-form" #contractForm="ngForm">

    <div class="half-field">
      <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
        <mat-label>Die Firma der Gesellschaft lautet:</mat-label>
        <input required matInput [(ngModel)]="wegLogicData.firma" name="firma" pattern="^[^\s]+(\s+[^\s]+)*$" appTrim>
      </mat-form-field>
    </div>


    <div class="half-field">
      <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
        <mat-label>Wo ist der Sitz der Gesellschaft:</mat-label>
        <input required matInput [(ngModel)]="wegLogicData.sitz" name="sitz" pattern="^[^\s]+(\s+[^\s]+)*$" appTrim>
      </mat-form-field>
    </div>


    <div class="half-field">
      <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
        <mat-label>Gegenstand des Unternehmens ist:</mat-label>
        <input required matInput [(ngModel)]="wegLogicData.gegenstand" name="gegenstand" pattern="^[^\s]+(\s+[^\s]+)*$" appTrim>
      </mat-form-field>
    </div>

    <div class="half-field">
      <mat-form-field appearance="none" class="no-padding no-line" [class.validation-error]="submitted">
        <mat-label>Die Höhe des Stammkapitals beträgt: (in EUR)</mat-label>
        <input required matInput [ngModel]="wegLogicData.stammkapital | looseCurrency:'EUR'" name="stammkapital" [ngModelOptions]="{updateOn:'blur'}" id="stammkapital" #stammkapital (ngModelChange)="wegLogicData.stammkapital=$event" (blur)="changeStammkapital($event, true)" (input)="changeStammkapital($event)" pattern="^[^\s]+(\s+[^\s]+)*$" appTrim>

        <!-- <input required matInput [(ngModel)]="wegLogicData.stammkapital" (blur)="changeStammkapital22($event)" name="stammkapital" pattern="^[^\s]+(\s+[^\s]+)*$"> -->
      </mat-form-field>
    </div>
    <div class="row mt-3">
      <div class="col-6">
        <p>
          <label>Wie viel des Stammkapitals wird einbezahlt?</label>
        </p>
      </div>
      <div class="col-6">
        <mat-radio-group required  class="example-radio-group" (change)="stammkapitalTypeChange($event)" [(ngModel)]="wegLogicData.stammkapitalType" name="stammkapitalType" [class.validation-error]="submitted">
          <mat-radio-button class="example-radio-button" [value]="'gesamtesStammkapital'"> Gesamtes Stammkapital </mat-radio-button>
          <mat-radio-button class="example-radio-button" [value]="'halbesStammkapital'"> Halbes Stammkapital </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="mt-3 mb-3" *ngIf="wegLogicData.stammkapital && wegLogicData.stammkapital != ''">
      <table style="width: 75% !important; margin: auto;" class="table table-bordered bg-white">
        <tr>
          <th>Gesellschafter</th>
          <th>Übernommene Stammeinlage</th>
          <th>Hierauf geleistet</th>
          <th>Beteiligungsquote in % </th>
        </tr>
        <tr *ngFor="let b of allBuyers">
          <td> <data *ngIf="b.companyName">{{ b.companyName }}</data> <data *ngIf="!b.companyName">{{ b.firstname }} {{ b.lastname }}</data></td>
          <!-- <td> {{ (( b.beteiligungsquote / 100 ) * wegLogicData.stammkapital).toFixed(2)   }}</td> -->
          <td>{{ b.payment }}</td>
          <td>
            <data *ngIf="wegLogicData.stammkapitalType == 'gesamtesStammkapital'">{{ b.payment }}</data>
            <data *ngIf="wegLogicData.stammkapitalType == 'halbesStammkapital'">{{ b.paymentHalf }}</data>
          </td>
          <td>{{ b.beteiligungsquote }}%</td>
        </tr>

        <tr>
          <td></td>
          <td><b>{{ total }}</b></td>
          <td>
            <data *ngIf="wegLogicData.stammkapitalType == 'gesamtesStammkapital'"><b>{{ total }}</b></data>
            <data *ngIf="wegLogicData.stammkapitalType == 'halbesStammkapital'"><b>{{ totalHalf }}</b></data>
          </td>
          <td><b>100%</b></td>
        </tr>
      </table>
    </div>

    <div class="row mt-3">
      <div class="col-6">
        <p>
          <label>Soll die Geschäftsführerbestellung im Gesellschaftsvertrag erfolgen (dann kann ein Geschäftsführer nur aus wichtigem Grund abbestellt werden)?</label>
        </p>
      </div>
      <div class="col-6">
        <mat-radio-group required  class="example-radio-group" [(ngModel)]="wegLogicData.geschaftsfuhrerbestellung" name="geschaftsfuhrerbestellung" [class.validation-error]="submitted">
          <mat-radio-button class="example-radio-button" [value]="'Ja'"> Ja </mat-radio-button>
          <mat-radio-button class="example-radio-button" [value]="'Nein'"> Nein </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="row mt-3" *ngIf="wegLogicData.geschaftsfuhrerbestellung == 'Ja' || wegLogicData.geschaftsfuhrerbestellung == 'Nein'">
      <div class="col-10 mx-auto">
        <h3>Geben Sie den / die Geschäftsführer ein:</h3>
      </div>
      <div class="col-8 offset-1">
        <mat-form-field appearance="none" class="no-padding no-line" *ngIf="(contractData.masterData.dritte.rightPersonList.length + contractData.masterData.dritte.rightCompanyList.length) > 0">
          <mat-label *ngIf="wegLogicData.geschaftsfuhrerbestellung == 'Ja'">Geschäftsführer auswählen (Im Gesellschaftsvertrag kann nur ein Geschäftsführer bestellt werden, der auch Gesellschafter ist.)</mat-label>
          <mat-label *ngIf="wegLogicData.geschaftsfuhrerbestellung == 'Nein'">Geschäftsführer auswählen</mat-label>
          <mat-select [(ngModel)]="personArray" multiple name="personArray" [ngModelOptions]="{standalone: true}">
            <mat-option *ngFor="let rightOwner of contractData.masterData.dritte.rightPersonList" [value]="rightOwner.id">
              {{rightOwner.firstname + ' ' + rightOwner.lastname}}
            </mat-option>
            <mat-option *ngFor="let rightOwner of contractData.masterData.dritte.rightCompanyList" [value]="rightOwner.id">
              {{rightOwner.companyName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="wegLogicData.geschaftsfuhrerbestellung == 'Nein'">
      <div class="col-12">
        <h3>Geschäftsführer</h3>
      </div>
      <div class="col-12 mx-auto">
        <div class="text-left mb-2">
          <label>
            Geben Sie die Daten des Geschäftsführer ein:
          </label>
        </div>
        <div class="text-left mb-2">
          <button class="small-height-button" mat-raised-button color="primary" (click)="addRightOwnerComplex('person')">
            Privatperson hinzufügen
          </button>
        </div>
        <div *ngFor="let buyer of rightsPrivateFormArray.controls; let i = index" class="mb-2">
          <h4 class="title-with-icon">Rechteinhaber Privatperson {{i + 1}}
            <fa-icon [icon]="faTimes" class="icon" (click)="removeRightPrivate(i)"></fa-icon>
          </h4>
          <app-person-data-form [parent]="buyer"></app-person-data-form>
        </div>
      </div>
    </div>

    <!-- sonstiges -->
      <div class="col-12">
        <div class="yellow-line mb-4 mt-4"></div>
      </div>
      <h3 class="mt-3">Sonstiges</h3>
      <textarea [placeholder]="primeType !== 'kfz' ? 'Hinweise für den Vertragserrichter:' : '' " class="textarea" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"
        cdkAutosizeMaxRows="8" [(ngModel)]="wegLogicData.hinweiseVertrag" name="hinweiseVertrag"
        style="border:solid 1px #446688; width: 98% !important; background-color: #fff; padding: 2px;" pattern="^[^\s]+(\s+[^\s]+)*$" appTrim>
      </textarea>
      <br>
      <br>
      <div class="text-center">
        <label>
          Sonstiger Anhang:
          <input type="file" class="accent-color" (change)="changeListener($event)" [(ngModel)]="wegLogicData.attachmentName" name="attachmentName" style="background-color: transparent !important">
        </label>
      </div>
      <br>

      <!-- Checkboxen AGB etc pp -->
      <div class="checkbox-div text-left">
        <mat-checkbox [(ngModel)]="wegLogicData.faggVerzicht" required style="margin: 0;" class="mat-checkbox-layout"
          name="faggVerzicht" [class.validation-error]="submitted">Ich verzichte auf Wideruffsrecht nach <a style="color: blue;" target="_blank" href="/assets/fagg.html">FAGG</a>.
        </mat-checkbox><br>
        <mat-checkbox [(ngModel)]="wegLogicData.datenschutz" style="margin: 0;" required class="mat-checkbox-layout"
          name="datenschutz" [class.validation-error]="submitted">Ich
          stimme
          der
          <a style="color: blue;" target="_blank" href="/assets/datenschutz.html">Datenschutzerklärung</a> zu.
        </mat-checkbox><br>
        <mat-checkbox [(ngModel)]="wegLogicData.agb" style="margin: 0;" required class="mat-checkbox-layout"
          name="agb" [class.validation-error]="submitted">Ich stimme
          hiermit
          den
          <a style="color: blue;" target="_blank" href="/assets/agb.html">AGB</a> zu.
        </mat-checkbox>
      </div>
      <br>
      <div class="text-center" style="color: red" *ngIf="!contractForm.valid && contractForm.touched">
        Bitte füllen Sie alle Felder aus!
      </div>
      <br>
      <div class="custom-weiter-div" style="border-top: 2px solid #cba857 !important">
        <button mat-raised-button color="primary" class="custom-weiter bg-button" (click)="gotoNext(contractForm, contractForm.valid )">
          Absenden
        </button>
        <br>
      </div>

  </form>
</div>
