import { Component, Inject, OnInit, Input, ViewChild } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  switchMap,
  tap
} from 'rxjs/operators';
import { DlgUbblDatenZLsComponent } from '../dialogs/dlg-ubbl-daten-z-ls/dlg-ubbl-daten-z-ls.component';
import { ContractDataService } from '../shared/contractData.service';
import { SonstigeRechteLogicComponent } from '../sonstige-rechte-logic/sonstige-rechte-logic.component';
import { MatDialog } from '@angular/material/dialog';
import { faQuestion, faUniversity } from '@fortawesome/free-solid-svg-icons';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
declare const $: any;

export interface GemeindeDaten {
  KGNr: number;
  KGName: string;
  PGNr: number;
  PolName: string;
}

@Component({
  selector: 'app-immo-detail-logic',
  templateUrl: './immo-detail-logic.component.html',
  styleUrls: ['./immo-detail-logic.component.css']
})
export class ImmoDetailLogicComponent implements OnInit {
  contractData;
  @Input() LSBOOL: boolean[];
  @Input() KBOOL: boolean;
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger }) autoComplete: MatAutocompleteTrigger;
  @ViewChild('autoCompleteInput2', { read: MatAutocompleteTrigger }) autoComplete2: MatAutocompleteTrigger;
  @ViewChild('autoCompleteInput3', { read: MatAutocompleteTrigger }) autoComplete3: MatAutocompleteTrigger;
  @ViewChild('autoCompleteInput4', { read: MatAutocompleteTrigger }) autoComplete4: MatAutocompleteTrigger;
  public ZweiPlusAnteil = [false, false, false, false];
  public KZweiPlusAnteil = [false, false, false, false];
  public miet: boolean;
  faUniversity = faQuestion;

  @ViewChild('l1') sonstigeRechte: SonstigeRechteLogicComponent;
  @ViewChild('l2') l2sonstigeRechte: SonstigeRechteLogicComponent;
  @ViewChild('l3') l3sonstigeRechte: SonstigeRechteLogicComponent;
  @ViewChild('l4') l4sonstigeRechte: SonstigeRechteLogicComponent;
  @Input() submitted: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    @Inject(ContractDataService)
    private contractDataService: ContractDataService,
    public UBBLDatenzLsDlg: MatDialog,
  ) { }

  ngAfterViewInit() {
    setTimeout(()=> {
      var v = '';
      v = $('.katastralGemeinde').val();
      if(v.includes("undefined")) {
        $('.katastralGemeinde').val('').focus();
      }
    }, 500);

     this.autoComplete.panelClosingActions
       .subscribe(e => {
         this.immodata.get('immodetail')
           .get('katastralGemeinde').setValue(null);
       });
     if(this.autoComplete2) {
       this.autoComplete2.panelClosingActions
         .subscribe(e => {
           this.immodata.get('l2immodetail')
             .get('l2katastralGemeinde').setValue(null);
         });
     }
     if(this.autoComplete3) {
       this.autoComplete3.panelClosingActions
         .subscribe(e => {
           this.immodata.get('l3immodetail')
             .get('l3katastralGemeinde').setValue(null);
         });
     }
     if(this.autoComplete4) {
       this.autoComplete4.panelClosingActions
         .subscribe(e => {
           this.immodata.get('l4immodetail')
             .get('l4katastralGemeinde').setValue(null);
         });
     }
   }

  public getValid(): boolean {
    return this.immodata.valid;
  }

  //#region  for Autocomplete
  filteredVillages: any[];
  l2filteredVillages: any[];
  l3filteredVillages: any[];
  l4filteredVillages: any[];

  isLoading = false;
  l2isLoading = false;
  l3isLoading = false;
  l4isLoading = false;
  //#endregion
  //Formgroup
  public immodata = this.formBuilder.group({
    immodetail: this.formBuilder.group({
      katastralGemeinde: new UntypedFormControl(''),
      einlageZahl: new UntypedFormControl(''),
      grundstuecksnummerAr: this.formBuilder.array([
        new UntypedFormGroup({
          grundstuecksnummer: new UntypedFormControl('')
        })
      ]),
      uebertragungsumfang: new UntypedFormControl('')
    }),
    l2immodetail: this.formBuilder.group({
      l2katastralGemeinde: new UntypedFormControl(''),
      l2einlageZahl: new UntypedFormControl(''),
      l2grundstuecksnummerAr: this.formBuilder.array([
        new UntypedFormGroup({
          l2grundstuecksnummer: new UntypedFormControl('')
        })
      ]),
      l2uebertragungsumfang: ''
    }),
    l3immodetail: this.formBuilder.group({
      l3katastralGemeinde: new UntypedFormControl(''),
      l3einlageZahl: new UntypedFormControl(''),
      l3grundstuecksnummerAr: this.formBuilder.array([
        new UntypedFormGroup({
          l3grundstuecksnummer: new UntypedFormControl('')
        })
      ]),
      l3uebertragungsumfang: ''
    }),
    l4immodetail: this.formBuilder.group({
      l4katastralGemeinde: new UntypedFormControl(''),
      l4einlageZahl: new UntypedFormControl(''),
      l4grundstuecksnummerAr: this.formBuilder.array([
        new UntypedFormGroup({
          l4grundstuecksnummer: new UntypedFormControl('')
        })
      ]),
      l4uebertragungsumfang: ''
    })
  });

  public uebertragsumfangArten: string[] = [
    'gesamte Liegenschaft',
    'einzelne Grundstücke'
  ];

  //#region SetAnteileBools für Share Buttons
  public setAnteilBool(lx: string) {
    if (lx == "l1") {
      this.setl1AnteilBool();
    }
    else if (lx == "l2") {
      this.setl2AnteilBool();
    }
    else if (lx == "l3") {
      this.setl3AnteilBool();
    }
    else if (lx == "l4") {
      this.setl4AnteilBool();
    }
    else {
      this.setl1AnteilBool();
      this.setl2AnteilBool();
      this.setl3AnteilBool();
      this.setl4AnteilBool();
    }
  }

  private setl4AnteilBool() {
    if (this.LSBOOL[3]) {
      let sum = 0;
      let Ksum = 0;
      if (this.contractData.anteildata.l4.seller.sellersPrivate) this.contractData.anteildata.l4.seller.sellersPrivate.forEach(seller => { if (seller.shareArDev) sum += seller.shareArDev.value.length; });
      if (this.contractData.anteildata.l4.seller.sellersCompany) this.contractData.anteildata.l4.seller.sellersCompany.forEach(seller => { if (seller.shareArDev) sum += seller.shareArDev.value.length; });
      if (sum < 2) this.ZweiPlusAnteil[3] = false;
      else this.ZweiPlusAnteil[3] = true;

      if (this.KBOOL) {
        if (this.contractData.anteildata.l4.buyer.buyersPrivate) this.contractData.anteildata.l4.buyer.buyersPrivate.forEach(buyer => { if (buyer.shareArDev) Ksum += buyer.shareArDev.value.length; });
        if (this.contractData.anteildata.l4.buyer.buyersCompany) this.contractData.anteildata.l4.buyer.buyersCompany.forEach(buyer => { if (buyer.shareArDev) Ksum += buyer.shareArDev.value.length; });
        if (Ksum < 2) this.KZweiPlusAnteil[3] = false;
        else this.KZweiPlusAnteil[3] = true;
      }
    }
  }

  private setl3AnteilBool() {
    let sum = 0;
    let Ksum = 0;
    if (this.LSBOOL[2]) {
      if (this.contractData.anteildata.l3.seller.sellersPrivate) this.contractData.anteildata.l3.seller.sellersPrivate.forEach(seller => { if (seller.shareArDev) sum += seller.shareArDev.value.length; });
      if (this.contractData.anteildata.l3.seller.sellersCompany) this.contractData.anteildata.l3.seller.sellersCompany.forEach(seller => { if (seller.shareArDev) sum += seller.shareArDev.value.length; });
      if (sum < 2) this.ZweiPlusAnteil[2] = false;
      else this.ZweiPlusAnteil[2] = true;

      if (this.KBOOL) {
        if (this.contractData.anteildata.l3.buyer.buyersPrivate) this.contractData.anteildata.l3.buyer.buyersPrivate.forEach(buyer => { if (buyer.shareArDev) Ksum += buyer.shareArDev.value.length; });
        if (this.contractData.anteildata.l3.buyer.buyersCompany) this.contractData.anteildata.l3.buyer.buyersCompany.forEach(buyer => { if (buyer.shareArDev) Ksum += buyer.shareArDev.value.length; });
        if (Ksum < 2) this.KZweiPlusAnteil[2] = false;
        else this.KZweiPlusAnteil[2] = true;
      }
    }
  }

  private setl2AnteilBool() {
    let sum = 0;
    let Ksum = 0;
    if (this.LSBOOL[1]) {
      if (this.contractData.anteildata.l2.seller.sellersPrivate) this.contractData.anteildata.l2.seller.sellersPrivate.forEach(seller => { if (seller.shareArDev) sum += seller.shareArDev.value.length; });
      if (this.contractData.anteildata.l2.seller.sellersCompany) this.contractData.anteildata.l2.seller.sellersCompany.forEach(seller => { if (seller.shareArDev) sum += seller.shareArDev.value.length; });
      if (sum < 2) this.ZweiPlusAnteil[1] = false;
      else this.ZweiPlusAnteil[1] = true;

      if (this.KBOOL) {
        if (this.contractData.anteildata.l2.buyer.buyersPrivate) this.contractData.anteildata.l2.buyer.buyersPrivate.forEach(buyer => { if (buyer.shareArDev) Ksum += buyer.shareArDev.value.length; });
        if (this.contractData.anteildata.l2.buyer.buyersCompany) this.contractData.anteildata.l2.buyer.buyersCompany.forEach(buyer => { if (buyer.shareArDev) Ksum += buyer.shareArDev.value.length; });
        if (Ksum < 2) this.KZweiPlusAnteil[1] = false;
        else this.KZweiPlusAnteil[1] = true;
      }
    }
  }

  private setl1AnteilBool() {
    if (this.LSBOOL[0]) {
      let sum = 0;
      let Ksum = 0;
      if (this.contractData.anteildata.l1.seller.sellersPrivate) this.contractData.anteildata.l1.seller.sellersPrivate.forEach(seller => { if (seller.shareArDev) sum += seller.shareArDev.value.length; });
      if (this.contractData.anteildata.l1.seller.sellersCompany) this.contractData.anteildata.l1.seller.sellersCompany.forEach(seller => { if (seller.shareArDev) sum += seller.shareArDev.value.length; });
      if (sum < 2) this.ZweiPlusAnteil[0] = false;
      else this.ZweiPlusAnteil[0] = true;

      if (this.KBOOL) {
        if (this.contractData.anteildata.l1.buyer.buyersPrivate) this.contractData.anteildata.l1.buyer.buyersPrivate.forEach(buyer => { if (buyer.shareArDev) Ksum += buyer.shareArDev.value.length; });
        if (this.contractData.anteildata.l1.buyer.buyersCompany) this.contractData.anteildata.l1.buyer.buyersCompany.forEach(buyer => { if (buyer.shareArDev) Ksum += buyer.shareArDev.value.length; });
        if (Ksum < 2) this.KZweiPlusAnteil[0] = false;
        else this.KZweiPlusAnteil[0] = true;
      }
    }
  }
  //#endregion

  ngOnInit() {
    this.logConsole();
    this.contractData = this.contractDataService.getContractData();
    console.log("this.contractData");
    console.log(this.contractData);
    console.log(this.LSBOOL);
    this.resetShares();

    //#region vervolständigung Katastral
    this.immodata.controls.immodetail
      .get('katastralGemeinde')
      .valueChanges.pipe(
        tap(() => (this.filteredVillages = [])),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.isLoading = true)),
        switchMap(inputValue =>
          this.contractDataService
            .searchCommunes(inputValue)
            .pipe(finalize(() => (this.isLoading = false)))
        )
      )
      .subscribe(res => {
        this.filteredVillages = res;
      });

    this.immodata.controls.l2immodetail
      .get('l2katastralGemeinde')
      .valueChanges.pipe(
        tap(() => (this.l2filteredVillages = [])),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.l2isLoading = true)),
        switchMap(inputValue =>
          this.contractDataService
            .searchCommunes(inputValue)
            .pipe(finalize(() => (this.l2isLoading = false)))
        )
      )
      .subscribe(res => {
        this.l2filteredVillages = res;
      });

    this.immodata.controls.l3immodetail
      .get('l3katastralGemeinde')
      .valueChanges.pipe(
        tap(() => (this.l3filteredVillages = [])),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.l3isLoading = true)),
        switchMap(inputValue =>
          this.contractDataService
            .searchCommunes(inputValue)
            .pipe(finalize(() => (this.l3isLoading = false)))
        )
      )
      .subscribe(res => {
        this.l3filteredVillages = res;
      });
    this.immodata.controls.l4immodetail
      .get('l4katastralGemeinde')
      .valueChanges.pipe(
        tap(() => (this.l4filteredVillages = [])),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.l4isLoading = true)),
        switchMap(inputValue =>
          this.contractDataService
            .searchCommunes(inputValue)
            .pipe(finalize(() => (this.l4isLoading = false)))
        )
      )
      .subscribe(res => {
        this.l4filteredVillages = res;
      });

    //#endregion

    if (this.contractData.primeType === 'miet') this.miet = true;
    else this.miet = false;

    this.setAnteilBool("0");
    this.logConsole();
    this.fillFormWithData();
  }

  initShareFull(data): UntypedFormGroup {
    return this.formBuilder.group({
      anteil: [data.anteil],
      BLNR: [data.BLNR]
    });
  }

  initKShareFull(data): UntypedFormGroup {
    return this.formBuilder.group({
      anteil: [data.anteil]
    });
  }

  public resetShares() {
    console.log(this.contractData.anteildata);
    if (this.LSBOOL[0]) {
      if (this.contractData.anteildata.l1.seller.sellersPrivate) {
        this.contractData.anteildata.l1.seller.sellersPrivate.forEach(seller => {
          seller.shareArDev = new UntypedFormArray([]);
          if (seller.shareAr) {
            console.log('resetShares');
            seller.shareAr.forEach(element => {
              seller.shareArDev.push(this.initShareFull(element));
            });
          }
          else {
            console.log('resetShares');
            seller.shareArDev.push(this.initShare());
          }
        });
      }
      console.log('this.contractData.anteildata.l1');
      console.log(this.contractData.anteildata.l1);

      if (this.contractData.anteildata.l1.seller.sellersCompany) {
        this.contractData.anteildata.l1.seller.sellersCompany.forEach(seller => {
          seller.shareArDev = new UntypedFormArray([]);
          if (seller.shareAr) {
            seller.shareAr.forEach(element => {
              seller.shareArDev.push(this.initShareFull(element));
            });
          }
          else {
            seller.shareArDev.push(this.initShare());
          }
        });
      }

      if (this.KBOOL) {
        if (this.contractData.anteildata.l1.buyer.buyersPrivate) {
          this.contractData.anteildata.l1.buyer.buyersPrivate.forEach(buyer => {
            buyer.shareArDev = new UntypedFormArray([]);
            if (buyer.shareAr) {
              buyer.shareAr.forEach(element => {
                buyer.shareArDev.push(this.initKShareFull(element));
              });
            }
            else {

              buyer.shareArDev.push(this.KinitShare());
            }
          });
        }

        if (this.contractData.anteildata.l1.buyer.buyersCompany) {
          this.contractData.anteildata.l1.buyer.buyersCompany.forEach(buyer => {
            buyer.shareArDev = new UntypedFormArray([]);
            if (buyer.shareAr) {
              buyer.shareAr.forEach(element => {
                buyer.shareArDev.push(this.initKShareFull(element));
              });
            }
            else {

              buyer.shareArDev.push(this.KinitShare());
            }
          });
        }
      }
    }

    if (this.LSBOOL[1]) {
      if (this.contractData.anteildata.l2.seller.sellersPrivate) {
        this.contractData.anteildata.l2.seller.sellersPrivate.forEach(seller => {
          seller.shareArDev = new UntypedFormArray([]);
          if (seller.shareAr) {
            seller.shareAr.forEach(element => {
              seller.shareArDev.push(this.initShareFull(element));
            });
          }
          else {

            seller.shareArDev.push(this.initShare());
          }
        });
      }

      if (this.contractData.anteildata.l2.seller.sellersCompany) {
        this.contractData.anteildata.l2.seller.sellersCompany.forEach(seller => {
          seller.shareArDev = new UntypedFormArray([]);
          if (seller.shareAr) {
            seller.shareAr.forEach(element => {
              seller.shareArDev.push(this.initShareFull(element));
            });
          }
          else {
            seller.shareArDev.push(this.initShare());
          }
        });
      }

      if (this.KBOOL) {
        if (this.contractData.anteildata.l2.buyer.buyersPrivate) {
          this.contractData.anteildata.l2.buyer.buyersPrivate.forEach(buyer => {
            buyer.shareArDev = new UntypedFormArray([]);
            if (buyer.shareAr) {
              buyer.shareAr.forEach(element => {
                buyer.shareArDev.push(this.initKShareFull(element));
              });
            }
            else {

              buyer.shareArDev.push(this.KinitShare());
            }
          });
        }

        if (this.contractData.anteildata.l2.buyer.buyersCompany) {
          this.contractData.anteildata.l2.buyer.buyersCompany.forEach(buyer => {
            buyer.shareArDev = new UntypedFormArray([]);
            if (buyer.shareAr) {
              buyer.shareAr.forEach(element => {
                buyer.shareArDev.push(this.initKShareFull(element));
              });
            }
            else {

              buyer.shareArDev.push(this.KinitShare());
            }
          });
        }
      }
    }

    if (this.LSBOOL[2]) {
      if (this.contractData.anteildata.l3.seller.sellersPrivate) {
        this.contractData.anteildata.l3.seller.sellersPrivate.forEach(seller => {
          seller.shareArDev = new UntypedFormArray([]);
          if (seller.shareAr) {
            seller.shareAr.forEach(element => {
              seller.shareArDev.push(this.initShareFull(element));
            });
          }
          else {

            seller.shareArDev.push(this.initShare());
          }
        });
      }

      if (this.contractData.anteildata.l3.seller.sellersCompany) {
        this.contractData.anteildata.l3.seller.sellersCompany.forEach(seller => {
          seller.shareArDev = new UntypedFormArray([]);
          if (seller.shareAr) {
            seller.shareAr.forEach(element => {
              seller.shareArDev.push(this.initShareFull(element));
            });
          }
          else {
            seller.shareArDev.push(this.initShare());
          }
        });
      }

      if (this.KBOOL) {
        if (this.contractData.anteildata.l3.buyer.buyersPrivate) {
          this.contractData.anteildata.l3.buyer.buyersPrivate.forEach(buyer => {
            buyer.shareArDev = new UntypedFormArray([]);
            if (buyer.shareAr) {
              buyer.shareAr.forEach(element => {
                buyer.shareArDev.push(this.initKShareFull(element));
              });
            }
            else {

              buyer.shareArDev.push(this.KinitShare());
            }
          });
        }

        if (this.contractData.anteildata.l3.buyer.buyersCompany) {
          this.contractData.anteildata.l3.buyer.buyersCompany.forEach(buyer => {
            buyer.shareArDev = new UntypedFormArray([]);
            if (buyer.shareAr) {
              buyer.shareAr.forEach(element => {
                buyer.shareArDev.push(this.initKShareFull(element));
              });
            }
            else {

              buyer.shareArDev.push(this.KinitShare());
            }
          });
        }
      }
    }

    if (this.LSBOOL[3]) {
      if (this.contractData.anteildata.l4.seller.sellersPrivate) {
        this.contractData.anteildata.l4.seller.sellersPrivate.forEach(seller => {
          seller.shareArDev = new UntypedFormArray([]);
          if (seller.shareAr) {
            seller.shareAr.forEach(element => {
              seller.shareArDev.push(this.initShareFull(element));
            });
          }
          else {
            seller.shareArDev.push(this.initShare());
          }
        });
      }

      if (this.contractData.anteildata.l4.seller.sellersCompany) {
        this.contractData.anteildata.l4.seller.sellersCompany.forEach(seller => {
          seller.shareArDev = new UntypedFormArray([]);
          if (seller.shareAr) {
            seller.shareAr.forEach(element => {
              seller.shareArDev.push(this.initShareFull(element));
            });
          }
          else {
            seller.shareArDev.push(this.initShare());
          }
        });
      }

      if (this.KBOOL) {
        if (this.contractData.anteildata.l4.buyer.buyersPrivate) {
          this.contractData.anteildata.l4.buyer.buyersPrivate.forEach(buyer => {
            buyer.shareArDev = new UntypedFormArray([]);
            if (buyer.shareAr) {
              buyer.shareAr.forEach(element => {
                buyer.shareArDev.push(this.initKShareFull(element));
              });
            }
            else {
              buyer.shareArDev.push(this.KinitShare());
            }
          });
        }

        if (this.contractData.anteildata.l4.buyer.buyersCompany) {
          this.contractData.anteildata.l4.buyer.buyersCompany.forEach(buyer => {
            buyer.shareArDev = new UntypedFormArray([]);
            if (buyer.shareAr) {
              buyer.shareAr.forEach(element => {
                buyer.shareArDev.push(this.initKShareFull(element));
              });
            }
            else {
              buyer.shareArDev.push(this.KinitShare());
            }
          });
        }
      }
    }
  }

  //#region Sonstige Rechte Helper: Zugriff auf Daten und Valid
  public getSonstigeRechte() {
    return this.sonstigeRechte.getData();
  }

  private getl1SonstigeRechteValidation() {
    if (this.LSBOOL[0]) return this.sonstigeRechte.getValidation();
    else return true;
  }

  public getl2SonstigeRechte() {
    return this.l2sonstigeRechte.getData();
  }

  private getl2SonstigeRechteValidation() {
    if (this.LSBOOL[1]) return this.l2sonstigeRechte.getValidation();
    else return true;
  }

  public getl3SonstigeRechte() {
    return this.l3sonstigeRechte.getData();
  }

  private getl3SonstigeRechteValidation() {
    if (this.LSBOOL[2]) return this.l3sonstigeRechte.getValidation();
    else return true;
  }

  public getl4SonstigeRechte() {
    return this.l4sonstigeRechte.getData();
  }

  private getl4SonstigeRechteValidation() {
    if (this.LSBOOL[3]) return this.l4sonstigeRechte.getValidation();
    else return true;
  }

  public getSonstigeRechteValidation() {
    return this.getl1SonstigeRechteValidation() && this.getl2SonstigeRechteValidation()
      && this.getl3SonstigeRechteValidation() && this.getl4SonstigeRechteValidation();
  }
  //#endregion

  //#region Fill form with Data
  fillFormWithData() {
    if (this.contractData && this.contractData.wegLogic) {
      if (this.contractData.wegLogic.immodetail) {
        this.initImmodetailFormGroupWithData(this.contractData.wegLogic.immodetail);
      }
      if (this.contractData.wegLogic.l2immodetail && this.LSBOOL[1]) {
        this.l2initImmodetailFormGroupWithData(this.contractData.wegLogic.l2immodetail);
      }
      if (this.contractData.wegLogic.l3immodetail && this.LSBOOL[2]) {
        this.l3initImmodetailFormGroupWithData(this.contractData.wegLogic.l3immodetail);
      }
      if (this.contractData.wegLogic.l4immodetail && this.LSBOOL[3]) {
        this.l4initImmodetailFormGroupWithData(this.contractData.wegLogic.l4immodetail);
      }
    }
  }


  initImmodetailFormGroupWithData(immodata: any) {
    let model = {
      katastralGemeinde: undefined,
      einlageZahl: undefined,
      uebertragungsumfang: undefined,
      grundstuecksnummerAr: undefined,
    }

    if (immodata.katastralGemeinde) model.katastralGemeinde = immodata.katastralGemeinde;

    if (immodata.einlageZahl) model.einlageZahl = immodata.einlageZahl;

    if (immodata.uebertragungsumfang) model.uebertragungsumfang = immodata.uebertragungsumfang;

    if (immodata.grundstuecksnummerAr) model.grundstuecksnummerAr = immodata.grundstuecksnummerAr;

    model.grundstuecksnummerAr.forEach((element, index) => {
      if (index != 0) {
        this.grundstuecksnummerAr.push(new UntypedFormGroup({
          grundstuecksnummer: new UntypedFormControl('')
        }));
      }
    });
    this.immodata.get('immodetail').patchValue(model);
  }

  l2initImmodetailFormGroupWithData(immodata: any) {
    let model = {
      l2katastralGemeinde: undefined,
      l2einlageZahl: undefined,
      l2uebertragungsumfang: undefined,
      l2grundstuecksnummerAr: undefined,
    }

    if (immodata.l2katastralGemeinde) model.l2katastralGemeinde = immodata.l2katastralGemeinde;

    if (immodata.l2einlageZahl) model.l2einlageZahl = immodata.l2einlageZahl;

    if (immodata.l2uebertragungsumfang) model.l2uebertragungsumfang = immodata.l2uebertragungsumfang;

    if (immodata.l2grundstuecksnummerAr) model.l2grundstuecksnummerAr = immodata.l2grundstuecksnummerAr;


    model.l2grundstuecksnummerAr.forEach((element, index) => {
      if (index != 0) {
        this.l2grundstuecksnummerAr.push(new UntypedFormGroup({
          l2grundstuecksnummer: new UntypedFormControl('')
        }));
      }
    });
    this.immodata.get('l2immodetail').patchValue(model);
  }

  l3initImmodetailFormGroupWithData(immodata: any) {
    let model = {
      l3katastralGemeinde: undefined,
      l3einlageZahl: undefined,
      l3uebertragungsumfang: undefined,
      l3grundstuecksnummerAr: undefined,
    }

    if (immodata.l3katastralGemeinde) model.l3katastralGemeinde = immodata.l3katastralGemeinde;

    if (immodata.l3einlageZahl) model.l3einlageZahl = immodata.l3einlageZahl;

    if (immodata.l3uebertragungsumfang) model.l3uebertragungsumfang = immodata.l3uebertragungsumfang;

    if (immodata.l3grundstuecksnummerAr) model.l3grundstuecksnummerAr = immodata.l3grundstuecksnummerAr;


    model.l3grundstuecksnummerAr.forEach((element, index) => {
      if (index != 0) {
        this.l3grundstuecksnummerAr.push(new UntypedFormGroup({
          l3grundstuecksnummer: new UntypedFormControl('')
        }));
      }
    });
    this.immodata.get('l3immodetail').patchValue(model);
  }

  l4initImmodetailFormGroupWithData(immodata: any) {
    let model = {
      l4katastralGemeinde: undefined,
      l4einlageZahl: undefined,
      l4uebertragungsumfang: undefined,
      l4grundstuecksnummerAr: undefined,
    }

    if (immodata.l4katastralGemeinde) model.l4katastralGemeinde = immodata.l4katastralGemeinde;

    if (immodata.l4einlageZahl) model.l4einlageZahl = immodata.l4einlageZahl;

    if (immodata.l4uebertragungsumfang) model.l4uebertragungsumfang = immodata.l4uebertragungsumfang;

    if (immodata.l4grundstuecksnummerAr) model.l4grundstuecksnummerAr = immodata.l4grundstuecksnummerAr;


    model.l4grundstuecksnummerAr.forEach((element, index) => {
      if (index != 0) {
        this.l4grundstuecksnummerAr.push(new UntypedFormGroup({
          l4grundstuecksnummer: new UntypedFormControl('')
        }));
      }
    });
    this.immodata.get('l4immodetail').patchValue(model);
  }

  //#endregion

  //#region GSTNR
  get grundstuecksnummerAr(): UntypedFormArray {
    return this.immodata.get('immodetail.grundstuecksnummerAr') as UntypedFormArray;
  }

  get l2grundstuecksnummerAr(): UntypedFormArray {
    return this.immodata.get('l2immodetail.l2grundstuecksnummerAr') as UntypedFormArray;
  }

  get l3grundstuecksnummerAr(): UntypedFormArray {
    return this.immodata.get('l3immodetail.l3grundstuecksnummerAr') as UntypedFormArray;
  }

  get l4grundstuecksnummerAr(): UntypedFormArray {
    return this.immodata.get('l4immodetail.l4grundstuecksnummerAr') as UntypedFormArray;
  }

  private initGSTNr(): UntypedFormGroup {
    return this.formBuilder.group({ grundstuecksnummer: [''] });
  }
  private l2initGSTNr(): UntypedFormGroup {
    return this.formBuilder.group({ l2grundstuecksnummer: [''] });
  }
  private l3initGSTNr(): UntypedFormGroup {
    return this.formBuilder.group({ l3grundstuecksnummer: [''] });
  }
  private l4initGSTNr(): UntypedFormGroup {
    return this.formBuilder.group({ l4grundstuecksnummer: [''] });
  }

  public addGstNr() {
    this.grundstuecksnummerAr.push(this.initGSTNr());
  }

  public l2addGstNr() {
    this.l2grundstuecksnummerAr.push(this.l2initGSTNr());
  }

  public l3addGstNr() {
    this.l3grundstuecksnummerAr.push(this.l3initGSTNr());
  }

  public l4addGstNr() {
    this.l4grundstuecksnummerAr.push(this.l4initGSTNr());
  }

  private removeGstNr(i) {
    this.grundstuecksnummerAr.removeAt(i);
  }

  private l2removeGstNr(i) {
    this.l2grundstuecksnummerAr.removeAt(i);
  }

  private l3removeGstNr(i) {
    this.l3grundstuecksnummerAr.removeAt(i);
  }

  private l4removeGstNr(i) {
    this.l4grundstuecksnummerAr.removeAt(i);
  }
  //#endregion


  logConsole() {
    console.log("immodata: , local contractdata");
    console.log(this.immodata);
    console.log(this.contractData);
  }

  //#region get Käuferanteile
  get kaeuferanteil(): UntypedFormGroup {
    return this.immodata.get('immodetail.kaeuferanteil') as UntypedFormGroup;
  }

  get l2kaeuferanteil(): UntypedFormGroup {
    return this.immodata.get('l2immodetail.l2kaeuferanteil') as UntypedFormGroup;
  }

  get l3kaeuferanteil(): UntypedFormGroup {
    return this.immodata.get('l3immodetail.l4kaeuferanteil') as UntypedFormGroup;
  }

  get l4kaeuferanteil(): UntypedFormGroup {
    return this.immodata.get('l4immodetail.l4kaeuferanteil') as UntypedFormGroup;
  }
  //#endregion

  //#region Käufer Fill form with data
  KfillFormWithData(anteildata: any) {
    if (anteildata && anteildata.l1.buyer.buyersPrivate.shareArDev) {
      this.kaeuferanteil.setValue(this.KinitkaeuferanteilFormGroupWithData(anteildata.l1.buyer.buyersPrivate.shareArDev));
    }
  }

  l2KfillFormWithData(anteildata: any) {
    if (anteildata && anteildata.l2.buyer.buyersPrivate.shareArDev) {
      this.l2kaeuferanteil.setValue(this.KinitkaeuferanteilFormGroupWithData(anteildata.l2.buyer.buyersPrivate.shareArDev));
    }
  }

  l3KfillFormWithData(anteildata: any) {
    if (anteildata && anteildata.l3.buyer.buyersPrivate.shareArDev) {
      this.l3kaeuferanteil.setValue(this.KinitkaeuferanteilFormGroupWithData(anteildata.l3.buyer.buyersPrivate.shareArDev));
    }
  }

  l4KfillFormWithData(anteildata: any) {
    if (anteildata && anteildata.l4.buyer.buyersPrivate.shareArDev) {
      this.l4kaeuferanteil.setValue(this.KinitkaeuferanteilFormGroupWithData(anteildata.l4.buyer.buyersPrivate.shareArDev));
    }
  }

  KinitkaeuferanteilFormGroupWithData(data: any): UntypedFormGroup {
    return this.formBuilder.group({
      anteil: new UntypedFormControl(data.anteil)
    });
  }
  //#endregion

  //#region Share Add and Remove

  addSharePrivate(i) {
    this.contractData.anteildata.l1.seller.sellersPrivate[i].shareArDev.push(
      this.initShare()
    );
    this.setAnteilBool("l1");
  }

  l2addSharePrivate(i) {
    this.contractData.anteildata.l2.seller.sellersPrivate[i].shareArDev.push(
      this.initShare()
    );
    this.setAnteilBool("l2");
  }

  l3addSharePrivate(i) {
    this.contractData.anteildata.l3.seller.sellersPrivate[i].shareArDev.push(
      this.initShare()
    );
    this.setAnteilBool("l3");
  }

  l4addSharePrivate(i) {
    this.contractData.anteildata.l4.seller.sellersPrivate[i].shareArDev.push(
      this.initShare()
    );
    this.setAnteilBool("l4");
  }

  removeSharePrivate(i, j) {
    this.contractData.anteildata.l1.seller.sellersPrivate[i].shareArDev.removeAt(j);
    this.setAnteilBool("l1");
  }

  l2removeSharePrivate(i, j) {
    this.contractData.anteildata.l2.seller.sellersPrivate[i].shareArDev.removeAt(j);
    this.setAnteilBool("l2");
  }

  l3removeSharePrivate(i, j) {
    this.contractData.anteildata.l3.seller.sellersPrivate[i].shareArDev.removeAt(j);
    this.setAnteilBool("l3");
  }

  l4removeSharePrivate(i, j) {
    this.contractData.anteildata.l4.seller.sellersPrivate[i].shareArDev.removeAt(j);
    this.setAnteilBool("l4");
  }


  addShareCompany(i) {
    this.contractData.anteildata.l1.seller.sellersCompany[i].shareArDev.push(
      this.initShare()
    );
    this.setAnteilBool("l1");
  }

  l2addShareCompany(i) {
    this.contractData.anteildata.l2.seller.sellersCompany[i].shareArDev.push(
      this.initShare()
    );
    this.setAnteilBool("l2");
  }

  l3addShareCompany(i) {
    this.contractData.anteildata.l3.seller.sellersCompany[i].shareArDev.push(
      this.initShare()
    );
    this.setAnteilBool("l3");
  }

  l4addShareCompany(i) {
    this.contractData.anteildata.l4.seller.sellersCompany[i].shareArDev.push(
      this.initShare()
    );
    this.setAnteilBool("l4");
  }

  removeShareCompany(i, j) {
    this.contractData.anteildata.l1.seller.sellersCompany[i].shareArDev.removeAt(j);
    this.setAnteilBool("l1");
  }

  l2removeShareCompany(i, j) {
    this.contractData.anteildata.l2.seller.sellersCompany[i].shareArDev.removeAt(j);
    this.setAnteilBool("l2");
  }

  l3removeShareCompany(i, j) {
    this.contractData.anteildata.l3.seller.sellersCompany[i].shareArDev.removeAt(j);
    this.setAnteilBool("l3");
  }

  l4removeShareCompany(i, j) {
    this.contractData.anteildata.l4.seller.sellersCompany[i].shareArDev.removeAt(j);
    this.setAnteilBool("l4");
  }

  //Ab Hier Käufer---------------------------------------------------------------------------------------------------------
  KaddSharePrivate(i) {
    this.contractData.anteildata.l1.buyer.buyersPrivate[i].shareArDev.push(this.KinitShare());
    this.setAnteilBool("l1");
  }
  l2KaddSharePrivate(i) {
    this.contractData.anteildata.l2.buyer.buyersPrivate[i].shareArDev.push(this.KinitShare());
    this.setAnteilBool("l2");
  }
  l3KaddSharePrivate(i) {
    this.contractData.anteildata.l3.buyer.buyersPrivate[i].shareArDev.push(this.KinitShare());
    this.setAnteilBool("l3");
  }
  l4KaddSharePrivate(i) {
    this.contractData.anteildata.l4.buyer.buyersPrivate[i].shareArDev.push(this.KinitShare());
    this.setAnteilBool("l4");
  }

  KremoveSharePrivate(i, j) {
    this.contractData.anteildata.l1.buyer.buyersPrivate[i].shareArDev.removeAt(j);
    this.setAnteilBool("l1");
  }
  l2KremoveSharePrivate(i, j) {
    this.contractData.anteildata.l2.buyer.buyersPrivate[i].shareArDev.removeAt(j);
    this.setAnteilBool("l2");
  }
  l3KremoveSharePrivate(i, j) {
    this.contractData.anteildata.l3.buyer.buyersPrivate[i].shareArDev.removeAt(j);
    this.setAnteilBool("l3");
  }
  l4KremoveSharePrivate(i, j) {
    this.contractData.anteildata.l4.buyer.buyersPrivate[i].shareArDev.removeAt(j);
    this.setAnteilBool("l4");
  }

  KaddShareCompany(i) {
    this.contractData.anteildata.l1.buyer.buyersCompany[i].shareArDev.push(this.KinitShare());
    this.setAnteilBool("l1");
  }
  l2KaddShareCompany(i) {
    this.contractData.anteildata.l2.buyer.buyersCompany[i].shareArDev.push(this.KinitShare());
    this.setAnteilBool("l2");
  }
  l3KaddShareCompany(i) {
    this.contractData.anteildata.l3.buyer.buyersCompany[i].shareArDev.push(this.KinitShare());
    this.setAnteilBool("l3");
  }
  l4KaddShareCompany(i) {
    this.contractData.anteildata.l4.buyer.buyersCompany[i].shareArDev.push(this.KinitShare());
    this.setAnteilBool("l4");
  }

  KremoveShareCompany(i, j) {
    this.contractData.anteildata.l1.buyer.buyersCompany[i].shareArDev.removeAt(j);
    this.setAnteilBool("l1");
  }
  l2KremoveShareCompany(i, j) {
    this.contractData.anteildata.l2.buyer.buyersCompany[i].shareArDev.removeAt(j);
    this.setAnteilBool("l2");
  }
  l3KremoveShareCompany(i, j) {
    this.contractData.anteildata.l3.buyer.buyersCompany[i].shareArDev.removeAt(j);
    this.setAnteilBool("l3");
  }
  l4KremoveShareCompany(i, j) {
    this.contractData.anteildata.l4.buyer.buyersCompany[i].shareArDev.removeAt(j);
    this.setAnteilBool("l4");
  }

  private KinitShare(): UntypedFormGroup {
    return this.formBuilder.group({ anteil: [''] });
  }

  private initShare(): UntypedFormGroup {
    return this.formBuilder.group({
      anteil: [''],
      BLNR: ['']
    });
  }
  //#endregion

  //#region Helper zur Anzeige der Katastralgemeinde
  displayFn(village?: any): string | undefined {
    console.log(village);
    let temp = village
      ? village.KGName + ' (' + village.KGNr + ')'
      : undefined;
    return village ? temp : undefined;
  }

  l2displayFn(l2village?: any): string | undefined {
    console.log(l2village);
    let temp = l2village
      ? l2village.KGName + ' (' + l2village.KGNr + ')'
      : undefined;
    return l2village ? temp : undefined;
  }

  l4displayFn(l4village?: any): string | undefined {
    let temp = l4village
      ? l4village.KGName + ' (' + l4village.KGNr + ')'
      : undefined;
    return l4village ? temp : undefined;
  }

  l3displayFn(l3village?: any): string | undefined {
    let temp = l3village
      ? l3village.KGName + ' (' + l3village.KGNr + ')'
      : undefined;
    return l3village ? temp : undefined;
  }
  //#endregion

  openUBBLDatenzLSDlg(): void {
    const dialogRef = this.UBBLDatenzLsDlg.open(DlgUbblDatenZLsComponent);
  }

  onSelectKg(type, v) {
    console.log(v);

    if(type == 'l1') {
      this.immodata.get('immodetail')
        .get('katastralGemeinde').setValue(v);
    } else if(type == 'l2') {
      this.immodata.get('l2immodetail')
        .get('l2katastralGemeinde').setValue(v);
    } else if(type == 'l3') {
      this.immodata.get('l3immodetail')
        .get('l3katastralGemeinde').setValue(v);
    } else if(type == 'l4') {
      this.immodata.get('l4immodetail')
        .get('l4katastralGemeinde').setValue(v);
    }
  }

}
