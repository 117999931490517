import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, RoutesRecognized, ActivationStart } from '@angular/router';
import { faUsers, faFileInvoice, faBuilding, faCog, faCogs, faUsersCog, faUserCog } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent implements AfterViewInit {

  faUsers = faUsers;
  faBuilding = faBuilding;
  faFileInvoice = faFileInvoice;

  faCog   = faCog;
  faCogs  = faCogs;
  faUsersCog = faUsersCog;
  faUserCog = faUserCog;
  isUserLoggedIn;
  userRole = 0;
  environment = environment;

  constructor(private router : Router, private auth: AuthService) {
  }

  ngAfterViewInit() {
    this.auth.currentUser.subscribe((user : any) => {
      if(user) {
        this.userRole = user.userRole;
        this.isUserLoggedIn = true;
      } else {
        this.isUserLoggedIn = false;
        this.userRole = 0;
      }
    });
  }

}
