<div class="popup-div">
  <button mat-icon-button aria-label="close dialog" mat-dialog-close class="popup-close">
    <mat-icon>close</mat-icon>
  </button>

  <div class="container">

    <div class="spinner-border" style="width: 4rem; height: 4rem;" *ngIf="loading">
      <span class="sr-only"></span>
    </div>

    <div class="full-grid-tile">
      <div class="col-12">
        <h3>Complete Your Payment</h3>
        <div class="amount">
          <label>Amount: </label>
          <span>{{ (invoice.amount / 100) | currency : 'EUR' }}</span>
        </div>

        <form id="payment-form" (ngSubmit)="pay()" *ngIf="!loading">
          <div id="payment-element"></div>
          <button id="submit" class="btn dark-blue-bg mb-3 mt-3">Pay Now</button>
          <div id="error-message" class="text-danger"></div>
        </form>
      </div>
    </div>
  </div>
</div>
