<form fxLayout="column" [formGroup]="parent">
  <div>
    <mat-form-field appearance="none" class="no-padding no-line mt-0 mb-0" [class.error]="submitted && parent.controls.pTOP.invalid">
      <mat-label>TOP</mat-label>
      <input required matInput maxlength="10" formControlName="pTOP" pattern="^[^\s]+(\s+[^\s]+)*$" appTrim/>
    </mat-form-field>
    <mat-form-field appearance="none" class="no-padding no-line mt-0 mb-0" [class.error]="submitted && parent.controls.pAnteil.invalid">
      <mat-label>Anteil</mat-label>
      <input required matInput formControlName="pAnteil" pattern="\d{1,9}/\d{1,9}" appTrim/>
      <label class="text-danger text-left error-message">Der Wert muss im m/n-Format vorliegen</label>
    </mat-form-field>
    <mat-form-field appearance="none" class="no-padding no-line mt-0 mb-0" [class.error]="submitted && parent.controls.pBLaufendeNr.invalid">
      <mat-label>B-LNR</mat-label>
      <input required matInput maxlength="10" formControlName="pBLaufendeNr" pattern="^[^\s]+(\s+[^\s]+)*$" appTrim/>
    </mat-form-field>
    <div *ngIf="!this.schenkung && !this.miet">
      <mat-form-field appearance="none" class="no-padding no-line" [class.error]="submitted && parent.controls.kaufpreisinEUR.invalid">
        <mat-label>Kaufpreis</mat-label>
        <input required matInput maxlength="15" formControlName="kaufpreisinEUR" #inputRef appTrim/>
      </mat-form-field>
    </div>
  </div>
</form>
