import {
  Component,
  OnDestroy,
  Inject,
  NgZone,
  OnInit,
  ViewChild,
  AfterViewInit,
  HostListener,
  ElementRef
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ContractDataService } from '../../../shared/contractData.service';
import { WEGlogicImmoDetailComponent } from '../../../weglogic-immo-detail/weglogic-immo-detail.component';
import { WEGlogicImmoDetailComponentMietWeg } from '../../../weglogic-immo-detail-miet-weg/weglogic-immo-detail-miet-weg.component';
import { faInfo, faTimes, faArrowRight, faSave, faUniversity, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DlgImmoSteuerComponent } from '../../../dialogs/dlg-immo-steuer/dlg-immo-steuer.component';
import { DlgLoeschenSonstigerLasterComponent } from '../../../dialogs/dlg-loeschen-sonstiger-laster/dlg-loeschen-sonstiger-laster.component';
import { DlgGeldlastenComponent } from '../../../dialogs/dlg-geldlasten/dlg-geldlasten.component';
import { DlgWidmungComponent } from '../../../dialogs/dlg-widmung/dlg-widmung.component';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImmoDetailLogicComponent } from '../../../immo-detail-logic/immo-detail-logic.component';
import { toNumber } from 'lodash';
import Fraction from 'fraction.js/fraction';
import { DlgVorschauGesellComponent } from '../../../dialogs/dlg-vorschau-gesell/dlg-vorschau-gesell.component';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { LooseCurrencyPipe } from '../../../shared/looseCurrency.pipe';
import { isNumber } from 'util';
import { SonstigeRechteLogicComponent } from '../../../sonstige-rechte-logic/sonstige-rechte-logic.component';
import { interval, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
declare var $:any;
import { SharedService } from '../../../shared/shared.service';
import { StripePaymentComponent } from '../../../shared/components/stripe-payment/stripe-payment.component';

@Component({
  selector: 'app-gesell-logic',
  templateUrl: './gesell-logic.component.html',
  styleUrls: ['./gesell-logic.component.scss']
})

export class GesellLogicComponent implements OnInit, OnDestroy, AfterViewInit{
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.saveDB();
  }

  nextClicked = false;
  primeType = undefined;
  wegLogicData = {
    firma: undefined,
    sitz: undefined,
    gegenstand: undefined,
    stammkapital: undefined,
    stammkapitalType: 'gesamtesStammkapital',
    attachment: undefined,
    attachmentName: undefined,
    halbestammeinlage: undefined,
    ganzestammeinlage: undefined,
    geschaftsfuhrerbestellung: undefined,
    faggVerzicht: undefined,
    hinweiseVertrag: undefined,
    datenschutz: undefined,
    agb: undefined,
  };
  personArray = [];
  submitted: boolean = false;
  ok: boolean = false;
  allBuyers = [];
  total = "";
  totalHalf = "";
  RightForm: UntypedFormGroup;
  faSave = faSave;
  faTimes = faTimes;
  contractData;

  constructor(
    public dialog: MatDialog,
    private shared: SharedService,
    private router: Router,
    @Inject(ContractDataService)
    private contractDataService: ContractDataService,
    private _ngZone: NgZone,
    public immoSteuerdialog: MatDialog,
    public sonstigeLastenDlg: MatDialog,
    public geldLastenDlg: MatDialog,
    private formBuilder: UntypedFormBuilder,
    public snackBar: MatSnackBar,
    public WidmungDlg: MatDialog,
    public VorschauDlg: MatDialog,
    public SaveDlg: MatDialog,
    private datepipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private looseCurrencyPipe: LooseCurrencyPipe,
    private el: ElementRef,
    private route:ActivatedRoute
  ) {
    this.RightForm = this.formBuilder.group({
      rightsPrivate: this.formBuilder.array([])
    });
    this.contractDataService.changePage({ page : route.snapshot.data.type ? route.snapshot.data.type : localStorage.getItem('page') });
  }

  ngOnDestroy() {
    console.log('calling');
    this.saveDB();
  }

  ngAfterViewInit() {
    // if(this.contractData.wegLogic)
    // this.personArray = this.contractData.wegLogic?.sonstigeRechte?.personArray;
    // console.log(this.personArray);
  }

  ngOnInit() {
    this.contractDataService.State = 2;
    this.nextClicked = false;

    this.primeType = this.contractDataService.getContractPrimeType();
    this.contractData = this.contractDataService.getContractData();
    console.log('this.contractData');
    console.log(this.contractData);


    let bp = this.contractData.masterData?.buyersPrivate ?? [];
    let bc = this.contractData.masterData?.buyersCompany ?? [];
    this.allBuyers = bp.concat(bc);

    if(this.contractData && this.contractData.wegLogic) {
      // this.contractData.wegLogic.stammkapital = this.transformCurrencyToNumber(this.contractData.wegLogic.stammkapital);
      this.wegLogicData = { ...this.contractData.wegLogic };
      if(this.contractData.wegLogic.sonstigeRechte.rightsPrivate) {
        if (this.contractData.wegLogic.sonstigeRechte.rightsPrivate.length > 0) {
          this.contractData.wegLogic.sonstigeRechte.rightsPrivate.forEach(buyer =>
            this.rightsPrivateFormArray.push(this.initBuyerPrivateWithData(buyer))
          );
        }
      }
      this.initPersons();
    }
    this.buyerTotalCalculate();
  }

  get rightsPrivateFormArray(): UntypedFormArray {
    return this.RightForm.get('rightsPrivate') as UntypedFormArray;
  }

  saveDB() {
    this.wegLogicData.stammkapital = this.looseCurrencyPipe.transform(this.wegLogicData.stammkapital, 'EUR');
    // let stammkapitalArray = JSON.parse(JSON.stringify(this.allBuyers));

    if(this.allBuyers.length) {
      let buyers = this.allBuyers.map(x => {
          const newX = { ...x };
          newX['amountFull']       = x.payment;
          newX['amountCalculated'] = this.wegLogicData.stammkapitalType == 'gesamtesStammkapital' ? x.payment : x.paymentHalf;
          delete newX.payment;
          delete newX.paymentHalf;
          return newX;
      });
      this.wegLogicData['stammkapitalArray'] = buyers;
    }

    console.log(this.allBuyers);

    this.wegLogicData['sonstigeRechte'] = this.RightForm.value;
    // this.wegLogicData['sonstigeRechte']['personArray'] = this.personArray;
    this.getPersons();
    let gruendungskosten = this.looseCurrencyPipe.transform((( 20 / 100 ) * Number(this.transformCurrencyToNumber(this.wegLogicData.stammkapital))) , 'EUR');
    this.wegLogicData['gruendungskosten'] = gruendungskosten;
    this.contractDataService.setGesellLogicData(this.wegLogicData);
  }

  initPersons() {
    let p = this.contractData.wegLogic?.sonstigeRechte?.personArray;
    console.log(p);
    if(p && p.length) {
      for (const pa of p) {
        this.personArray.push(pa.id);
      }
    }
    console.log(this.personArray);
  }

  getPersons() {
    let persons = [];
    let rp = this.contractData.masterData.dritte.rightPersonList;

    if(rp && rp.length) {
      for (const r of rp) {
        if (this.personArray.includes(r.id)) {
          persons.push(r);
        }
      }
    }

    let rc = this.contractData.masterData.dritte.rightCompanyList;
    if(rc && rc.length) {
      for (const r of rc) {
        if (this.personArray.includes(r.id)) {
          persons.push(r);
        }
      }
    }
    this.wegLogicData['sonstigeRechte']['personArray'] = persons;
  }

  gotoNext(Form, FormIsValid: boolean) {
    console.log(this.personArray);

    this.submitted = true;
    if(!FormIsValid || !this.RightForm.valid) {
      this.scrollToFirstInvalidControl();
    } else {
      this.saveDB();
      const dialogRef = this.VorschauDlg.open(DlgVorschauGesellComponent);
      dialogRef.afterClosed().subscribe(
        data => {
          console.log(data);
          if (data.success) {
            if(data.type == 'now') {
              this.contractDataService.changeLoading({ loading: true });
              this.contractDataService.generateInvoice('gesellschaftsvertrag').subscribe((res : any) => {
                res = JSON.parse(res);
                this.contractDataService.changeLoading({ loading: false });
                localStorage.setItem('invoice', JSON.stringify(res.invoice));
                const dialogRef = this.dialog.open(StripePaymentComponent, {
                  data: { invoice : res.invoice },
                  width: '800px',
                  disableClose : true
                });

                dialogRef.afterClosed().subscribe(
                data => {
                  this.shared.deleteApi('payment-intent/' + res.invoice.id).subscribe((res : any) => {
                    localStorage.removeItem('invoice');
                  });
                });
              }, err => {
                console.log(err);
              });
            } else {
              this.contractDataService.changeLoading({ loading: true });

              // Ok, if you want to generate contract & invoice & pay later
              this.contractDataService.generateInvoice('gesellschaftsvertrag').subscribe((res : any) => {
                res = JSON.parse(res);
                localStorage.setItem('invoice', JSON.stringify(res.invoice));
                this.contractDataService.postDataGesell().subscribe((res : any) => {
                  this.contractDataService.clearContractDataAll();
                  this.contractDataService.changeLoading({ loading: false });
                  this.router.navigate(['/contracts']);
                }, err => {
                  console.log(err);
                });
              }, err => {
                console.log(err);
              });
              // Ok, if you want to generate contract & invoice & pay later

              // this.contractDataService.changeLoading({ loading: true });
              // this.contractDataService.postDataGesellNoPayment('gesellschaftsvertrag').subscribe((res : any) => {
              //   this.contractDataService.clearContractDataAll();
              //   this.contractDataService.changeLoading({ loading: false });
              //   this.router.navigate(['/contracts']);
              // }, err => {
              //   console.log(err);
              // });

            }
          }
        })
    }
  }

  private scrollToFirstInvalidControl() {
    let firstInvalidControl: HTMLElement;
    firstInvalidControl = this.el.nativeElement.querySelector("form .ng-invalid");
    if (firstInvalidControl) {
      window.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
        behavior: "smooth"
      });
    }
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 15;
    return controlEl ? controlEl.getBoundingClientRect().top + window.scrollY - labelOffset : 0;
  }

  changeListener($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = () => {
      this.wegLogicData.attachment = myReader.result;
    };
    myReader.readAsDataURL(file);
  }

  changeStammkapital(e, blur = false) {
    console.log(this.wegLogicData.stammkapital);
    let stammkapital = this.transformCurrencyToNumber(this.wegLogicData.stammkapital);
    console.log(stammkapital);
    this.buyerTotalCalculate();
  }

  changeStammkapital2(e, blur = false) {
    let error = false;
    if(blur) {
      if(isNaN(e.target.value)) {
        error = true;
        this.wegLogicData.stammkapital = undefined;
      }
    }

    if(error == false) {
      this.buyerTotalCalculate();
    }
  }

  buyerTotalCalculate() {
    let stammkapital = this.transformCurrencyToNumber(this.wegLogicData.stammkapital);
    if(this.allBuyers.length) {
      this.allBuyers = this.allBuyers.map(x => ({
          ...x,
          payment: this.looseCurrencyPipe.transform(( x.beteiligungsquote / 100 ) * stammkapital, 'EUR'),
          paymentHalf: this.looseCurrencyPipe.transform(((( x.beteiligungsquote / 100 ) * stammkapital) / 2) , 'EUR')
      }));

      let total = 0;
      for (let b of this.allBuyers) {
        total += Number(this.transformCurrencyToNumber(b.payment));
      }
      this.total      = this.looseCurrencyPipe.transform(total, 'EUR');
      this.totalHalf  = this.looseCurrencyPipe.transform((total / 2), 'EUR');
    }
  }

  transformCurrencyToNumber(input) {
    if (input && input !== undefined && input !== '') {
      if (typeof input == 'number') {
        input = input.toString();
      }
      const strInput = input.replaceAll('.', '');
      const strInput2 = strInput.replaceAll(',', '.');
      const toNumber = parseFloat(strInput2);
      return toNumber;
    }
    return input;
  }

  stammkapitalTypeChange(e) {
    console.log(e);
    if(e.value == 'halbesStammkapital') {
      this.wegLogicData.halbestammeinlage = this.looseCurrencyPipe.transform((this.wegLogicData.stammkapital / 2), 'EUR');
      this.wegLogicData.ganzestammeinlage = undefined;
    } else {
      this.wegLogicData.ganzestammeinlage = this.looseCurrencyPipe.transform(this.wegLogicData.stammkapital, 'EUR');
      this.wegLogicData.halbestammeinlage = undefined;
    }
  }

  addRightOwnerComplex(buyerType): void {
    switch (buyerType) {
      case 'person': {
        this.rightsPrivateFormArray.push(this.initBuyerPrivateEmpty());
        break;
      }
    }
  }

  private initBuyerPrivateEmpty(): UntypedFormGroup {
    return this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      formatedBirthday: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      postalCode: ['', Validators.required]
    });
  }

  private initBuyerPrivateWithData(buyerPrivate: any): UntypedFormGroup {
    console.log(buyerPrivate);
    return this.formBuilder.group({
      firstname: [buyerPrivate.firstname, Validators.required],
      lastname: [buyerPrivate.lastname, Validators.required],
      formatedBirthday: [buyerPrivate.formatedBirthday ? new Date(buyerPrivate.formatedBirthday) : '', Validators.required],
      address: [buyerPrivate.address, Validators.required],
      city: [buyerPrivate.city, Validators.required],
      postalCode: [buyerPrivate.postalCode, Validators.required]
    });
  }

  removeRightPrivate(index: number) {
    this.rightsPrivateFormArray.removeAt(index);
  }

}
