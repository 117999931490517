import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ContractDataService } from '../shared/contractData.service';

@Component({
  selector: 'app-invoice-success',
  templateUrl: './finalize-contract.component.html',
  styleUrls: ['./finalize-contract.component.scss']
})

export class FinalizeContractComponent {

  onlyPayment : boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contractDataService : ContractDataService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.contractDataService.changePage({ page: 'Vertrag abschließen'});
    this.route.queryParams.subscribe(params => {
      console.log(params);
      if(params['onlyPayment']) {
        console.log('onlyPayment');
        this.onlyPayment = true;
      }
    });

    const dialogRef = this.dialog.open(DialogComponent, {
      width: '800px',
      disableClose : true
    });
    if (this.onlyPayment == false) {

      let primeType = localStorage.getItem('primeType');
      let contractData = localStorage.getItem('contractData');
      if(contractData) {
        if(primeType && primeType == 'gesellschaftsvertrag') {
          this.contractDataService.postDataGesell('gesellschaftsvertrag')
         .subscribe((res : any) => {
           dialogRef.close();
           this.contractDataService.clearContractDataAll();
           this.router.navigate(['/contracts']);
         });
       } else {
         this.contractDataService.postData()
        .subscribe((res : any) => {
          dialogRef.close();
          this.contractDataService.clearContractDataAll();
          this.router.navigate(['/contracts']);
        });
       }
     } else {
       dialogRef.close();
       this.router.navigate(['/contracts']);
     }
   } else {
     dialogRef.close();
     this.router.navigate(['/contracts']);
   }

    // setTimeout(() => {
    //   dialogRef.close();
    //   this.router.navigate(['/contracts']);
    // }, 1000);
  }

}

@Component({
  selector: 'app-dialog',
  template: `
  <div class="popup-div">
    <div class="container text-center">
      <h1 class="mb-2 mt-2">Vertragsabschluss. Bitte warten...</h1>
      <div class="text-center bg-white p-4 w-100">
        <div class="spinner-border" style="width: 4rem; height: 4rem;">
          <span class="sr-only"></span>
        </div>
      </div>

    </div>
  </div>
  `
})

export class DialogComponent {
  constructor(public dialogRef: MatDialogRef<DialogComponent>) {}
  closeDialog() {
    this.dialogRef.close();
  }
}
