<mat-dialog-content class="popup">
  <div class="custom-dlg-head">
    <h3 style="color: white !important; padding: 2%;">Bitte &uuml;berpr&uuml;fen Sie die Daten</h3>
  </div>

  <div class="custom-dlg-body">
      <div>
        <b>Geschäftsführer:</b>
        <div *ngFor="let chef of contractData.masterData.dritte.rightPersonList">
          {{ chef.firstname}} {{chef.lastname}} ({{chef.birthday}})<br>
          {{ chef.address}}, {{chef.postalCode}} {{chef.city}}
        </div>
        <br>
        <div *ngFor="let chef of contractData.masterData.dritte.rightCompanyList">
          {{ chef.companyName}} ({{chef.companiesRegisterNumber }})<br>
          {{ chef.address}}, {{chef.postalCode}} {{chef.city}}
        </div>

        <div class="mt-2">
          <label><b>Firma:&nbsp;</b> {{contractData.wegLogic.firma}}</label>
          <br>
          <label><b>Sitz:&nbsp;</b> {{contractData.wegLogic.sitz}}</label>
          <br>
          <label><b>Gegenstand:&nbsp;</b> {{contractData.wegLogic.gegenstand}}</label>
          <br>
          <label><b>Stammkapital:&nbsp;</b> {{contractData.wegLogic.stammkapital}}</label>
          <br>
          <label><b>Halbe Stammeinlage:&nbsp;</b> {{contractData.wegLogic.halbestammeinlage}}</label>
          <br>
          <label><b>Geschäftsführerbestellung:&nbsp;</b> {{contractData.wegLogic.geschaftsfuhrerbestellung}}</label>
        </div>

        <div class="custom-kosten" style="width: 85% !important">
          <h3>Stammkapital:</h3>
          <div class="inner-table">
            <table style="width:100%">
              <tr>
                <th>Gesellschafter</th>
                <th>Übernommene Stammeinlage</th>
                <th>Hierauf geleistet</th>
                <th>Beteiligungsquote in % </th>
              </tr>
              <tr *ngFor="let b of allBuyers">
                <td> <data *ngIf="b.companyName">{{ b.companyName }}</data> <data *ngIf="!b.companyName">{{ b.firstname }} {{ b.lastname }}</data></td>
                <td>{{ b.payment }}</td>
                <td>
                  <data *ngIf="contractData.wegLogic.stammkapitalType == 'gesamtesStammkapital'">{{ b.payment }}</data>
                  <data *ngIf="contractData.wegLogic.stammkapitalType == 'halbesStammkapital'">{{ b.paymentHalf }}</data>
                </td>
                <td>{{ b.beteiligungsquote }}%</td>
              </tr>

              <tr>
                <td></td>
                <td><b>{{ total }}</b></td>
                <td>
                  <data *ngIf="contractData.wegLogic.stammkapitalType == 'gesamtesStammkapital'"><b>{{ total }}</b></data>
                  <data *ngIf="contractData.wegLogic.stammkapitalType == 'halbesStammkapital'"><b>{{ totalHalf }}</b></data>
                </td>
                <td><b>100%</b></td>
              </tr>
            </table>
          </div>
        </div>

        <h3>Sonstige Rechte</h3>
        <div *ngIf="contractData.wegLogic.sonstigeRechte.rightsPrivate.length > 0">
          <div *ngFor="let chef of contractData.wegLogic.sonstigeRechte.rightsPrivate">
            <p>{{chef.firstname}}&nbsp;{{chef.lastname}}&nbsp;({{chef.birthday}})</p>
            <p>{{chef.address}},&nbsp;{{chef.postalCode}}&nbsp;{{chef.city}}</p>
            <br>
          </div>
        </div>
      </div>

      <div class="custom-dlg-footer">
        <button class="small-btn" mat-raised-button style="padding: 1%;" (click)="closeDlg()">
          <fa-icon style="color: inherit; background-color: inherit;" [icon]="faArrowLeft"></fa-icon>&nbsp;Daten
          &Auml;ndern
        </button>
        <br>
        <button class="bg-button mt-1" mat-raised-button style="padding: 1%;  color: rgb(0, 0, 0) !important;" [disabled]="disableSubmit" (click)="closeOKDlg('later')" *ngIf="paymentFlag && !this.isEditing">
          <span *ngIf="disableSubmit">
            <img src="assets/loading-gif.gif" alt="Loading">
          </span>
          <span *ngIf="!disableSubmit">
            Zahlen Sie später
            <fa-icon style="color: inherit; background-color: inherit;" [icon]="faCheck"></fa-icon>
          </span>
        </button>
        <button class="bg-button mt-1" mat-raised-button style="padding: 1%;  color: rgb(0, 0, 0) !important;" [disabled]="disableSubmit" (click)="closeOKDlg('now')">
          <span *ngIf="disableSubmit">
            <img src="assets/loading-gif.gif" alt="Loading">
          </span>
          <span *ngIf="!disableSubmit">
            VERTRAG BESTELLEN UND ZAHLEN
            <fa-icon style="color: inherit; background-color: inherit;" [icon]="faCheck"></fa-icon>
          </span>
        </button>
      </div>
    </div>
    </mat-dialog-content>
