import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { SharedService } from '../../shared.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { loadStripe } from '@stripe/stripe-js';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.scss']
})

export class StripePaymentComponent {

  stripe;
  elements;
  loading : boolean = false;
  submitted : boolean = false;
  invoice;
  cardElement;
  return_url;

  constructor(
    public dialogRef: MatDialogRef<StripePaymentComponent>,
    private router: Router,
    private route: ActivatedRoute,
    private shared : SharedService,
    @Inject(MAT_DIALOG_DATA) private dialogData: DataModel
  ) {
    console.log(environment.FrontEndUrl);
    console.log(dialogData);
    this.invoice = dialogData.invoice;
    this.return_url = dialogData.onlyPayment ? environment.FrontEndUrl + "/finalize-contract?onlyPayment=true" : environment.FrontEndUrl + "/finalize-contract";
  }

  ngOnInit(): void {
    this.submitted = false;
    this.loading = false;
    this.loadStripe();
  }

  async loadStripe() {
    this.stripe = await loadStripe(environment.stripe_public_key);
    const options = {
      clientSecret: this.invoice.client_secret,
      appearance: {/*...*/},
    };
    this.elements = this.stripe.elements(options);

    const paymentElement = this.elements.create('payment');
    // this.loading = false;
    paymentElement.mount('#payment-element');
  }

  async pay() {
    this.submitted = true;
    let elements = this.elements;
    const { error } = await this.stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: this.return_url
      }
    });

    if (error) {
      const messageContainer = document.querySelector('#error-message');
      messageContainer.textContent = error.message;
    }
  }

}

export class DataModel {
  constructor(public invoice: any, public onlyPayment: any) {
  }
}
