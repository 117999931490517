<div class="sidebar" data-color="white" data-active-color="danger">
  <div>
    <!-- <a href="https://www.creative-tim.com" class="simple-text logo-mini">
      <div class="logo-image-small">
        <img src="assets/logo_web_vertragsexpert.png" class="logo"/>
      </div>
    </a> -->
    <a class="simple-text logo-normal">

      <div class="logo-image-big text-center">
        <!-- <img src="assets/output-onlinepngtools.png" class="logo"/> -->
        <img src="assets/v_logo.png" class="logo w-50 mt-4"/>
      </div>
    </a>
  </div>
  <div class="sidebar-wrapper mt-4">
    <ul class="nav" *ngIf="(isUserLoggedIn && userRole == 1) || (isUserLoggedIn && userRole == 2)">
      <li routerLinkActive="active">
        <a routerLink="/objects">
          <p><fa-icon class="accent-color fa-2x" style="position: relative; left: 6px;" [icon]="faBuilding"></fa-icon> <span>Objekt</span></p>
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/contracts">
          <p><fa-icon class="accent-color fa-2x" style="position: relative; left: 6px;" [icon]="faBuilding"></fa-icon> <span>Verträge</span></p>
        </a>
      </li>
      <li  routerLinkActive="active" *ngIf="userRole == 1">
        <a routerLink="/customers">
          <p><fa-icon class="accent-color fa-2x" [icon]="faUsers"></fa-icon> <span>Kunden</span></p>
        </a>
      </li>
      <li  routerLinkActive="active" *ngIf="userRole == 1">
        <a routerLink="/gesellschaft">
          <p><fa-icon class="accent-color fa-2x" [icon]="faUsers"></fa-icon> <span>GmbH-Generator</span></p>
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/billing/invoices">
          <p><fa-icon class="accent-color fa-2x" style="position: relative; left: 4px;" [icon]="faCog"></fa-icon> <span>Abrechnungen</span></p>
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/settings">
          <p><fa-icon class="accent-color fa-2x" style="position: relative; left: 4px;" [icon]="faCog"></fa-icon> <span>Einstellungen</span></p>
        </a>
      </li>
    </ul>

    <ul class="nav" *ngIf="isUserLoggedIn && userRole == 0">
      <li routerLinkActive="active">
        <a routerLink="/admin-panel/clients">
          <p><fa-icon class="accent-color fa-2x" style="position: relative; left: 6px;" [icon]="faBuilding"></fa-icon> <span>Kunden</span></p>
        </a>
      </li>
      <!-- <li routerLinkActive="active">
        <a routerLink="/objects">
          <p><fa-icon class="accent-color fa-2x" style="position: relative; left: 6px;" [icon]="faBuilding"></fa-icon> <span>Objects</span></p>
        </a>
      </li> -->
      <li routerLinkActive="active">
        <a routerLink="/contracts">
          <p><fa-icon class="accent-color fa-2x" style="position: relative; left: 6px;" [icon]="faBuilding"></fa-icon> <span>Verträge</span></p>
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/billing/invoices">
          <p><fa-icon class="accent-color fa-2x" style="position: relative; left: 4px;" [icon]="faCog"></fa-icon> <span>Abrechnungen</span></p>
        </a>
      </li>
      <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a routerLink="/settings">
          <p><fa-icon class="accent-color fa-2x" style="position: relative; left: 4px;" [icon]="faCog"></fa-icon> <span>Einstellungen</span></p>
        </a>
      </li>
      <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a routerLink="/settings/api-users">
          <p><fa-icon class="accent-color fa-2x" style="position: relative; left: 4px;" [icon]="faCog"></fa-icon> <span>API-Benutzer</span></p>
        </a>
      </li>
    </ul>

  </div>
</div>
