import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from '../shared/shared.service';
import { MatSort, SortDirection} from '@angular/material/sort';
import { ContractDataService } from '../shared/contractData.service';
import { MatTableDataSource } from '@angular/material/table';
import { DataSource } from '@angular/cdk/table';
import { MatPaginator} from '@angular/material/paginator';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { AuthService } from '../shared/auth.service';
import { ContractConfirmComponent } from '../contract-confirm/contract-confirm.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
} from '@angular/material/dialog';
import { StripePaymentComponent } from '../shared/components/stripe-payment/stripe-payment.component';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.css']
})
export class ContractsComponent implements OnInit {

  constructor(
    private router: Router,
    private http: HttpClient,
    private auth: AuthService,
    private route: ActivatedRoute,
    private shared : SharedService,
    public dialog: MatDialog,
    @Inject(ContractDataService) private contractDataService: ContractDataService
  ) { }

  contracts:any = [];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  users:any = [];
  pending = 0;
  completed = 0;
  all = 0;
  user = 0;
  resultsLength = 0;
  perPage = 10;
  pageIndex = 0;
  sortKey = 'createdAt';
  searchText = '';
  date = '';
  date2 = '';
  sortDirection = 'desc';
  environment = environment;
  displayedColumns: string[] = [
    'contractID',
    'createdAt',
    'contactId',
    'contactEmail',
    'invNo',
    'invStatus',
    'postID',
    'amount',
    'status',
    'contractType',
    'action'
  ];

  displayedColumnLabels = { _id : 'Id',
    contractID : 'Vertrags ID',
    contactId : 'Ansprechpartner',
    contactEmail : 'E-Mail',
    postID : 'Post ID',
    amount : 'Amount',
    status : 'Status',
    addedBy : 'Benutzer',
    invNo : 'Rechnungs-Nr.',
    invStatus : 'Rechnungs status',
    contractType : 'Vertragsart',
    createdAt : 'Erstellungsdatum',
    action : 'Optionen' };

  searchTextUpdate = new Subject<string>();
  dateUpdate = new Subject<string>();
  loading = false;
  contractType = "all";
  status = "all";
  customer = "all";
  loggedInUser;

  ngOnInit(): void {
    this.user = this.route.snapshot.queryParams['user'];
    let cus = this.route.snapshot.queryParams['customer'];

    if(cus)
      this.customer = this.route.snapshot.queryParams['customer'];

    this.contractDataService.changePage({ page: 'Verträge'});
    this.list(this.user);
    this.getCounters();
    setTimeout(() => {
      // this.list();
    }, 5000);

    this.searchTextUpdate.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(v => {
        this.loading = true;
        this.list(this.user);
      });

      this.dateUpdate.pipe(
        debounceTime(400),
        distinctUntilChanged())
        .subscribe(v => {
          if(v) {
            let date = new Date(v);
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            this.date2 = day + '-' + month + '-' + year;
          } else {
            this.date2 = '';
          }

          this.loading = true;
          this.list(this.user);
      });
  }

  ngAfterViewInit() {
    this.auth.currentUser.subscribe((user : any) => {
      if(user) {
        this.loggedInUser = user;
      }
      else
        this.loggedInUser = undefined;
    });
  }

  list(user = null) {
    this.loading = true;
    this.shared.getApi('contract/list' + '?page=' + this.pageIndex + '&sort=' + this.sortKey  + '&sortDirection=' + this.sortDirection + '&searchText=' + this.searchText + '&contractType=' + this.contractType + '&status=' + this.status + '&perPage=' + this.perPage + '&customer=' + this.customer + '&date=' + this.date2 )
      .subscribe((res:any) => {
        this.resultsLength = res.count;
        this.contracts     = new MatTableDataSource(res.data);
        this.loading = false;
      }, (err:any) => {

      });
  }

  getCounters() {
    this.shared.getApi('contract/counters')
      .subscribe((res:any) => {
        this.pending = res.pending;
        this.completed = res.done;
        this.all = res.all;
      }, (err:any) => {

      });
  }

  editGesellContract(id) {
    localStorage.setItem('isEditing', 'true');
    this.shared.openSnackBar("Bearbeitungsmaske lädt");
    this.shared.getApi('contract/' + id)
      .subscribe((res:any) => {
        localStorage.removeItem('contractData');
        localStorage.removeItem('primeType');


        try{
          let json = JSON.parse(res.acf.json);

          localStorage.setItem('contractData', JSON.stringify(json));
          localStorage.setItem('contractDataSafe', JSON.stringify(json));
          var primeType = "";
          if(json.primeType) {
            primeType = json.primeType;
          } else if(json.contactData.primeType) {
            primeType = json.contactData.primeType;
          }

          localStorage.setItem('primeType', primeType);
          document.body.className = "";
          this.router.navigate(['gesellschaftsvertrag/personaldata']);

        }
        catch(e) {
          console.log(e);
        }


      }, (err:any) => {
      });
  }

  editContract(id) {
    localStorage.setItem('isEditing', 'true');
    this.shared.openSnackBar("Bearbeitungsmaske lädt");
    this.shared.getApi('contract/' + id)
      .subscribe((res:any) => {
        localStorage.removeItem('contractData');
        localStorage.removeItem('primeType');

        try{
          let json = JSON.parse(res.acf.json);
          if(json.wegLogic && json.wegLogic.lastenLoeschenCLfdNr && json.wegLogic.lastenLoeschenCLfdNr.length) {
            let lastenclnr = json.wegLogic.lastenLoeschenCLfdNr;
            console.log(lastenclnr);
            if(lastenclnr) {
              let clnrs = [];
              for(let clnr of lastenclnr) {
                console.log(clnr);
                if(clnr && clnr['CLNR'] && clnr['CLNR'] != "") {
                  let cla = clnr['CLNR'].split(" in EZ ");
                  if(cla[0] && cla[0] != "") {
                  	clnrs.push(cla[0]);
                  }
                }
              }
              json.wegLogic.lastenLoeschenCLfdNr = clnrs;
            }
          }

          if(json.wegLogic && json.wegLogic) {
            var einlageZahlArs = [];
            var grundstuecksnummerAr = [];
            for (let index = 0; index < 10; index++) {
              var title = "";
              if(index == 0) {
                title = 'immodetail';
              } else {
                title = 'immodetail' + index;
              }
              // if(json.wegLogic[title]) {
              //   var immo = json.wegLogic[title];
              //   console.log(immo);
              //   if(immo.einlageZahlAr && immo.einlageZahlAr.length)
              //     einlageZahlArs.push(immo['einlageZahlAr'][0]);
              //
              //   if(immo.grundstuecksnummerAr && immo.grundstuecksnummerAr.length)
              //     grundstuecksnummerAr.push(immo['grundstuecksnummerAr'][0]);
              // }

              // if(index > 0) {
              //   if(json['wegLogic'][title]) {
              //     delete json['wegLogic'][title];
              //   }
              // }
            }

            // json['wegLogic']['immodetail']['einlageZahlAr']        = JSON.parse(JSON.stringify(einlageZahlArs));
            // json['wegLogic']['immodetail']['grundstuecksnummerAr'] = JSON.parse(JSON.stringify(grundstuecksnummerAr));
          }

          if(!json.wegLogic.admin.faggVerzicht)
          json['wegLogic']['admin'] = json.admin;

          localStorage.setItem('contractData', JSON.stringify(json));
          localStorage.setItem('contractDataSafe', JSON.stringify(json));
          var primeType = "";
          if(json.primeType) {
            primeType = json.primeType;
          } else if(json.contactData.primeType) {
            primeType = json.contactData.primeType;
          }

          localStorage.setItem('primeType', primeType);
          document.body.className = "";


          if(primeType == 'kauf')
            this.router.navigate(['kaufvertrag/personaldata']);

          else if(primeType == 'schenkung')
            this.router.navigate(['schenkung/personaldata']);

          else if(primeType == 'miet')
            this.router.navigate(['mietvertrag/personaldata']);

          else
            this.router.navigate(['kaufvertrag/personaldata']);
        }
        catch(e) {
          console.log(e);
        }
      }, (err:any) => {
      });
  }

  downloadContract(con, id, link) {
    if(link == 'docx') {
      window.open("https://kvgen.fra1.digitaloceanspaces.com/output_" + con.contractID + ".docx", "_blank");
    } else {
      this.shared.getApi('contract/' + id)
        .subscribe((res:any) => {
          if(link == 'vertrag_url') {
            if(res.acf['vertrag_pdf_2'] && res.acf['vertrag_pdf_2'] != '') {
              console.log('vertrag_pdf_2');
              window.open(res.acf['vertrag_pdf_2'], "_blank");
            } else {
              console.log('vertrag_url');
              window.open(environment.NodeAppUrl +'/dl/'+ con.contractID, "_blank");
            }
          } else {
            console.log('here 3 other');
            window.open(res.acf[link], "_blank");
          }
        }, (err:any) => {

        });
    }
  }

  filter(e) {
    this.user = e.value;
    this.list(e.value);
  }

  filterContractType(e) {
    this.contractType = e;
    this.list(this.user);
  }

  filterStatus(e) {
    this.status = e.value;
    this.list(this.user);
  }

  search() {
    console.log(this.searchText);
    // this.user = e.value;
    this.list(this.user);
  }

  handlePageEvent(event) {
    console.log(event);
    this.pageIndex = event.pageIndex;
    this.perPage = event.pageSize;
    this.list(this.user);
  }

  sortChange(event) {
    this.sortKey = event.active;
    this.sortDirection = event.direction;
    this.list(this.user);
    console.log(event);
  }

  reloadCons(type) {
    this.status = type;
    this.list(this.user);
  }

  confirmContract(e, type, con) {
    console.log(con);

    const dialogRef = this.dialog.open(ContractConfirmComponent,{
      data: { id: con._id, postID: con.postID, eMail: (con.addedByApi ? con.addedByApi.email : con.auth.email) },
      width: '800px',
      disableClose : true
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.list(this.user);
          this.getCounters();
        }
        else {}
      });
  }

  contractStatus(id, status) {
    this.shared.postApi('contract/update/' + id , { status }).subscribe( (res:any) => {
      this.list(this.user);
      this.getCounters();
      this.shared.openSnackBar(res.success);
    }, (err:any) => {
      this.shared.openSnackBar("Something went wrong");
    });
  }

  newKauf(con) {
    this.shared.getApi('contract/' + con._id)
      .subscribe((res:any) => {
        let json = res.acf.json;
        let jsonn = JSON.parse(json);
        jsonn.primeType = 'kauf';
        delete jsonn.contractID;
        delete jsonn.id;
        delete jsonn.postID;
        delete jsonn.wegLogic.datumRestoreBindung;
        this.parseJson(jsonn);
        localStorage.setItem('object', JSON.stringify(con.objectId));
        localStorage.removeItem('isEditing');
        console.log(jsonn);
      }, (err:any) => {
      });
  }

  convertToISO8601(dateString) {
    const [day, month, year] = dateString.split('.');
    const isoString = `${year}-${month}-${day}T00:00:00.000Z`;
    return isoString;
  }

  parseJson(json) {
    try {
      if(json.wegLogic && json.wegLogic.lastenLoeschenCLfdNr && json.wegLogic.lastenLoeschenCLfdNr.length) {
        let lastenclnr = json.wegLogic.lastenLoeschenCLfdNr;
        if(lastenclnr) {
          let clnrs = [];
          for(let clnr of lastenclnr) {
            console.log(clnr);
            if(clnr && clnr['CLNR'] && clnr['CLNR'] != "") {
              let cla = clnr['CLNR'].split(" in EZ ");
              if(cla[0] && cla[0] != "") {
                clnrs.push(cla[0]);
              }
            }
          }
          json.wegLogic.lastenLoeschenCLfdNr = clnrs;
        }
      }

      if(json.wegLogic && json.wegLogic) {
        var einlageZahlArs = [];
        var grundstuecksnummerAr = [];
        for (let index = 0; index < 10; index++) {
          var title = "";
          if(index == 0) {
            title = 'immodetail';
          } else {
            title = 'immodetail' + index;
          }
        }

        if(json.wegLogic.datumRestoreBindung) {
          console.log(json.wegLogic.datumRestoreBindung);
          // json.wegLogic.datumRestoreBindung = this.convertToISO8601(json.wegLogic.datumRestoreBindung);
          console.log(json.wegLogic.datumRestoreBindung);
        }
      }

      if(!json.wegLogic.admin.faggVerzicht)
      json['wegLogic']['admin'] = json.admin;

      localStorage.setItem('contractData', JSON.stringify(json));
      var primeType = "";
      if(json.primeType) {
        primeType = json.primeType;
      } else if(json.contactData.primeType) {
        primeType = json.contactData.primeType;
      }
      localStorage.setItem('primeType', primeType);
      document.body.className = "";

      if(primeType == 'kauf')
        this.router.navigate(['kaufvertrag/personaldata']);
      else if(primeType == 'schenkung')
        this.router.navigate(['schenkung/personaldata']);
      else if(primeType == 'miet')
        this.router.navigate(['mietvertrag/personaldata']);
      else
        this.router.navigate(['kaufvertrag/personaldata']);
    }
    catch(e) {
      console.log(e);
    }
  }

  payNow(contract) {
    const dialogRef = this.dialog.open(StripePaymentComponent, {
      data: { invoice : contract.invoice.stripe_paymentIntent, onlyPayment: true },
      width: '800px',
      disableClose : true
    });
  }

}
