<mat-toolbar color="white" class="mat-elevation-z2">
    <span>
      <h4 class="mt-1 mb-0 text-capitalize">
        {{ title }}
      </h4>
    </span>
    <span style="flex: 1 1 auto;"></span>
    <div fxLayout="row" fxShow="false" fxShow.gt-sm>
      <button mat-icon-button [matMenuTriggerFor]="menu" style="margin-left : auto !important">
        <fa-icon class="accent-color fa-3x" [icon]="faUserCircle"></fa-icon>
      </button>
    </div>
    <button class="menu" mat-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm>
     <mat-icon>menu</mat-icon>
    </button>
</mat-toolbar>
<mat-menu #menuu="matMenu">
  <button mat-menu-item routerLink="/products">Products</button>
  <button mat-menu-item routerLink="/dashboard">Dashboard</button>
</mat-menu>
<mat-menu #menu="matMenu" class="mmm">
  <button mat-menu-item disabled>{{ user?.firstName }} {{ user?.lastName }}</button>
  <button mat-menu-item (click)="logOut()">Ausloggen</button>
</mat-menu>


<mat-sidenav-container fxFlexFill fxHide.gt-sm>
  <mat-sidenav #sidenav>
    <mat-nav-list *ngIf="(isUserLoggedIn && userRole == 1) || (isUserLoggedIn && userRole == 2)" style="padding-top: 0px !important">
      <a routerLink="/objects">
        <p><fa-icon class="accent-color fa-1x" [icon]="faBuilding"></fa-icon> <span>Objekt</span></p>
      </a>
      <a routerLink="/contracts">
        <p><fa-icon class="accent-color fa-1x" [icon]="faBuilding"></fa-icon> <span>Verträge</span></p>
      </a>
      <a routerLink="/customers" *ngIf="userRole == 1">
        <p><fa-icon class="accent-color fa-1x" [icon]="faUsers" style="margin-right: 7px;"></fa-icon> <span>Kunden</span></p>
      </a>
      <a routerLink="/gesellschaft" *ngIf="userRole == 1">
        <p><fa-icon class="accent-color fa-1x" [icon]="faUsers" style="margin-right: 7px;"></fa-icon> <span>GmbH-Generator</span></p>
      </a>
      <a routerLink="/billing/invoices">
        <p><fa-icon class="accent-color fa-1x" [icon]="faCog"></fa-icon> <span>Abrechnungen</span></p>
      </a>
      <a routerLink="/settings">
        <p><fa-icon class="accent-color fa-1x" [icon]="faCog"></fa-icon> <span>Einstellungen</span></p>
      </a>
    </mat-nav-list>
    <mat-nav-list *ngIf="isUserLoggedIn && userRole == 0">
      <a routerLink="/admin-panel/clients">
        <p><fa-icon class="accent-color fa-1x" [icon]="faUsers" style="margin-right: 7px;"></fa-icon> <span>Kunden</span></p>
      </a>
      <a routerLink="/contracts">
        <p><fa-icon class="accent-color fa-1x" [icon]="faBuilding"></fa-icon> <span>Verträge</span></p>
      </a>
      <a routerLink="/billing/invoices">
        <p><fa-icon class="accent-color fa-1x" [icon]="faCog"></fa-icon> <span>Abrechnungen</span></p>
      </a>
      <a routerLink="/settings">
        <p><fa-icon class="accent-color fa-1x" [icon]="faCog"></fa-icon> <span>Einstellungen</span></p>
      </a>
      <a routerLink="/settings/api-users">
        <p><fa-icon class="accent-color fa-1x" [icon]="faCog"></fa-icon> <span>API-Benutzer</span></p>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content fxFlexFill>

  </mat-sidenav-content>
</mat-sidenav-container>
