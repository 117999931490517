import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { UntypedFormBuilder, FormArray, UntypedFormGroup, Validators, AbstractControl  } from '@angular/forms';
import { SharedService } from '../shared/shared.service';
import { ContractDataService } from '../shared/contractData.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-contract-confirm',
  templateUrl: './contract-confirm.component.html',
  styleUrls: ['./contract-confirm.component.scss']
})
export class ContractConfirmComponent {
  @ViewChild('UploadFileInput') uploadFileInput: ElementRef;
  @ViewChild('UploadFileInput2') uploadFileInput2: ElementRef;
  myfilename = '';
  vertragPdf = 'Keine Datei ausgewählt';
  vertragPdfFile = [];
  contractId;
  postID;
  eMail;
  contract;
  contractForm: UntypedFormGroup;
  loading = false;
  fileExists = false;

  constructor(
    public dialogRef: MatDialogRef<ContractConfirmComponent>,
    private router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private shared : SharedService,
    private formBuilder: UntypedFormBuilder,
    private contractService : ContractDataService,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel
  ) {
    this.contractId = data.id;
    this.contractDetails(data.id);
    this.postID = data.postID;
    this.eMail = data.eMail;
  }

  ngOnInit(): void {
    this.contractForm = this.formBuilder.group({
      vertrag_url: ['', Validators.required]
    });
  }

  contractDetails(id) {
    this.loading = true;
    this.shared.getApi('contract/' + id)
      .subscribe((res:any) => {
        this.loading = false;
        this.contract = res.con;
        if(res.acf.vertrag_pdf_2 != '') {
          this.fileExists = true;
        }
        this.vertragPdf= res.acf.vertrag_pdf_2 ? res.acf.vertrag_pdf_2 : 'No File Selected';
      }, (err:any) => {

      });
  }

  fileChangeEvent2(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.vertragPdf = fileInput.target.files[0].name;
      this.vertragPdfFile = fileInput.target.files;
    } else {
      this.shared.openSnackBar("Bitte Datei auswählen");
    }
  }


  gotoNext(draft = null) {
    this.loading = true;
    let formData:FormData = new FormData();
    if(this.vertragPdfFile.length) {
      formData.append('uploadFile', this.vertragPdfFile[0], this.vertragPdfFile[0].name);
    }

    if(draft)
    formData.append('draft', 'true');
    formData.append('postID', this.postID);
    formData.append('eMail', this.eMail);
    formData.append('status', 'Bestatigt');

    this.shared.postApiFile('contract/vertrag-upload/' + this.contractId + '?type=vertragPdf', formData).subscribe( (res:any) => {
      this.vertragPdf = "";
      this.shared.openSnackBar(res.success);
      this.dialogRef.close(true);
      this.loading = false;
    }, (err:any) => {
      this.shared.openSnackBar("Something went wrong");
    });
  }


}



export class ConfirmDialogModel {
  constructor(public id: string, public postID: string, public eMail: string) {
  }
}
