import { Component, Inject, Input, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import {
  Form,
  FormGroup,
  FormArray,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Observable } from 'rxjs';
import {
  map,
  startWith,
  filter,
  switchMap,
  debounce,
  debounceTime,
  tap,
  finalize,
  distinctUntilChanged
} from 'rxjs/operators';
import { ContractDataService } from '../shared/contractData.service';
import { DlgDatenLiegenschaftComponent } from '../dialogs/dlg-daten-liegenschaft/dlg-daten-liegenschaft.component';
import { MatDialog } from '@angular/material/dialog';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { faQuestion, faUniversity, faUserGraduate } from '@fortawesome/free-solid-svg-icons';
import { DlgWegDatenZurLiegenschaftComponent } from '../dialogs/dlg-weg-daten-zur-liegenschaft/dlg-weg-daten-zur-liegenschaft.component';
import { DlgDatenZWohnodGeschMoreVerComponent } from '../dialogs/dlg-daten-z-wohnod-gesch-more-ver/dlg-daten-z-wohnod-gesch-more-ver.component';
import { DlgDatenZWohnodGeschOneVerComponent } from '../dialogs/dlg-daten-z-wohnod-gesch-one-ver/dlg-daten-z-wohnod-gesch-one-ver.component';
declare const $: any;

export interface GemeindeDaten {
  KGNr: number;
  KGName: string;
  PGNr: number;
  PolName: string;
}

@Component({
  selector: 'app-weglogic-immo-detail-miet-weg',
  templateUrl: './weglogic-immo-detail-miet-weg.component.html',
  styleUrls: ['./weglogic-immo-detail-miet-weg.component.css']
})
export class WEGlogicImmoDetailComponentMietWeg implements OnInit, AfterViewInit {
  eins = [0];
  bins = [0];
  @Input() contractOption: string;
  @Input() parkplatz: boolean;
  @Input() submitted: boolean = false;
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger }) autoComplete: MatAutocompleteTrigger;
  faUniversity = faQuestion;
  faUserGraduate = faUserGraduate;
  contractData;
  // for Autocomplete
  filteredVillages: any[];
  tempVar: any;
  blaufendeNrCnt;

  immodetail = this.formBuilder.group({
    katastralGemeinde: new UntypedFormControl(''),
    einlageZahlObject: this.formBuilder.array([]),
  });
  public BLaufendeNr2Visible: boolean;
  isLoading = false;
  rowHeight = (window.innerWidth <= 768) ? '140px' : '75px';

  ngAfterViewInit() {
    setTimeout(()=> {
      var v = '';
      v = $('.katastralGemeinde').val();
      if(v.includes("undefined")) {
        $('.katastralGemeinde').val('').focus();
      }
    }, 500);

     this.autoComplete.panelClosingActions
       .subscribe(e => {
         this.immodetail
           .get('katastralGemeinde').setValue(null);
       });
   }

  constructor(
    private formBuilder: UntypedFormBuilder,
    @Inject(ContractDataService)
    private contractDataService: ContractDataService,
    public datenLiegenschaftDlg: MatDialog,
    public wegDatenzLSDlg: MatDialog,
    public WEGDatenzWohnodGeschOneVerDlg: MatDialog,
    public WEGDatenzWohnodGeschMoreVerDlg: MatDialog,
  ) {
    setTimeout(()=> {
      console.log('this.immodetail');
    }, 1000);
  }


  private initParkingPlaceEmpty(): UntypedFormGroup {
    if (this.BLaufendeNr2Visible) {
      console.log('Parkplatz mit 2. BLNR');
      return this.formBuilder.group({
        pTOP: [''],
        pAnteil: [''],
        pBLaufendeNr: [''],
        pBLaufendeNr2: [''],
        kaufpreisinEUR: ['']
      });
    } else {
      console.log('Parkplatz ohne 2. BLNR');
      return this.formBuilder.group({
        pTOP: [''],
        pAnteil: [''],
        pBLaufendeNr: [''],
        kaufpreisinEUR: ['']
      });
    }
  }

  private initParkingPlaceWithData(parkingPlace: any): UntypedFormGroup {
    console.log('**** parkingPlace');
    console.log(parkingPlace);
    if (this.BLaufendeNr2Visible) {
      console.log('**** VISIBLE');
      return this.formBuilder.group({
        pTOP: [parkingPlace.pTOP],
        pAnteil: [parkingPlace.pAnteil],
        pBLaufendeNr: [parkingPlace.pBLaufendeNr],
        pBLaufendeNr2: [parkingPlace.pBLaufendeNr2],
        kaufpreisinEUR: [parkingPlace.kaufpreisinEUR]
      });
    } else {
      console.log('**** NOT VISIBLE');
      return this.formBuilder.group({
        pTOP: [parkingPlace.pTOP],
        pAnteil: [parkingPlace.pAnteil],
        pBLaufendeNr: [parkingPlace.pBLaufendeNr],
        kaufpreisinEUR: [parkingPlace.kaufpreisinEUR]
      });
    }
  }

  public getValid(): boolean {
    return this.immodetail.valid;
  }

  ngOnInit() {
    // this.autoCompleteInput.value = 'Whale!';
    this.contractData = this.contractDataService.getContractData();
    console.log('this.blaufendeNrCnt');
    this.blaufendeNrCnt = this.contractData.masterData.sellersPrivate.length + this.contractData.masterData.sellersCompany.length;
    console.log(this.blaufendeNrCnt);

    // for (let i = 0; i < this.blaufendeNrCnt; i++) {
    //   this.bLaufendeNrAr.push(this.initBLNR());
    // }

    // GCCRM-8219 STARTS
    // this.setBLNR();
    // this.grundstuecksnummerAr.push(this.initGSTNr());
    // this.einlageZahlObject.push(this.initEinlageZahl());
    // GCCRM-8219 ENDS

    if (
      this.contractData.masterData.sellersPrivate.length > 1 ||
      this.contractData.masterData.sellersCompany.length > 1 || (this.contractData.masterData.sellersCompany.length === 1 && this.contractData.masterData.sellersPrivate.length === 1)
    ) {
      this.BLaufendeNr2Visible = true;
    }
    // this.parkingPlaces = new UntypedFormArray([]);

    // fill form if weglogic data is available
    const weglogic = this.contractDataService.getContractWEGLogicData();
    this.fillFormWithData(weglogic);

    // 8261
    // if(weglogic && weglogic.immodetail)
    //   this.immodetail.get('parkingPlaceCnt').setValue(weglogic.immodetail.parkingPlaceCnt);

    // this.immodetail.get('parkingPlaceCnt').valueChanges.subscribe(value => {
    //   this.parkingPlaces.controls.splice(0);
    //   for (let i = 0; i < value; i++) {
    //     this.parkingPlaces.push(this.initParkingPlaceEmpty());
    //     console.log('Hallo Welt!');
    //     if (i == 7) break;
    //   }
    // });

    this.immodetail
      .get('katastralGemeinde')
      .valueChanges.pipe(
        tap(() => (this.filteredVillages = [])),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.isLoading = true)),
        switchMap(inputValue =>
          this.contractDataService
            .searchCommunes(inputValue)
            .pipe(finalize(() => (this.isLoading = false)))
        )
      )
      .subscribe(res => {
        this.filteredVillages = res;
      });
  }

  private changePlaceCnt(i) {
    // this.addParking(i);

    // let parkingPlaces   = einls[i].parkingPlace;
    // let parkingPlaceCnt = einls[i].parkingPlaceCnt;

    let group = this.einlageZahlObject.controls[i];
    let parkingPlaces   = group.get('parkingPlace').value;
    let parkingPlaceCnt = group.get('parkingPlaceCnt').value;
    console.log(parkingPlaces);
    console.log(parkingPlaceCnt);

    let s = group.get('parkingPlace') as UntypedFormArray;
    console.log(s.controls);
    console.log(s.controls.length);

    s.controls.splice(0);
    // for (let index = 0; index < s.controls.length ; index++) {
    //   s.controls.splice(index);
    // }

    console.log(s.controls);
    console.log(this.einlageZahlObject);

    for (let j = 0; j < parkingPlaceCnt; j++) {
      this.addParking(i);
      if (j == 7) break;
    }

  }

  public addParking(i) {
    let group = this.einlageZahlObject.controls[i];
    let s = group.get('parkingPlace') as UntypedFormArray;
    s.push(this.initParkingPlaceEmpty());
  }

  private initEinlageZahl(data = null, parkingPlaceCnt = null): UntypedFormGroup {
    let json  = {
      einlageZahl: (data && data.einlageZahl) ? data.einlageZahl : '',
      top : (data && data.top) ? data.top : '',
      anteil : (data && data.anteil) ? data.anteil : '',
      bLaufendeNrAr  : (data && data.bLaufendeNrAr) ? data.bLaufendeNrAr : this.initBLNRWithCount(),
      grundstuecksnummerAr : (data && data.grundstuecksnummerAr) ? data.grundstuecksnummerAr : this.initGSTNew(),
      parkingPlaceCnt : (data && data.parkingPlaceCnt) ? data.parkingPlaceCnt : '',
      parkingPlace : (data && data.parkingPlace) ? data.parkingPlace : this.initParking(parkingPlaceCnt)
    };
    return this.formBuilder.group(json);
  }

  private initBLNRWithCount(data = null) {
    console.log(data);
    let controls = [];
    for (let i = 0; i < this.blaufendeNrCnt; i++) {
      if (data) {
        if(data[i])
        controls.push(this.initBLNR(data[i]));
        else
        controls.push(this.initBLNR());
      } else {
        controls.push(this.initBLNR());
      }
    }
    return this.formBuilder.array(controls);
  }

  private  initGSTNew(data = null) {
    let controls = [];
    console.log(data);
    if(data && data.length) {
      for (let d of data) {
        console.log(d);
        controls.push(this.initGSTNr(d));
      }
    } else {
      controls.push(this.initGSTNr());
    }
    return this.formBuilder.array(controls);
  }

  private initParking(parkingPlaceCnt, data = null) {
    if(parkingPlaceCnt == 0) {
      let controls = [];
      for (let i = 0; i < parkingPlaceCnt; i++) {
        controls.push(this.initParkingPlaceEmpty());
        if (i == 7) break;
      }
      return this.formBuilder.array(controls);
    }
    if(data) {
      console.log(data);
      let controls = [];
      for (let d of data) {
        console.log(d);
        controls.push(this.initParkingPlaceWithData(d));
      }
      return this.formBuilder.array(controls);
    }
    else {
      let controls = [];
      for (let i = 0; i < parkingPlaceCnt; i++) {
        controls.push(this.initParkingPlaceEmpty());
        if (i == 7) break;
      }
      return this.formBuilder.array(controls);
    }
  }

  private initBLNR(data = null) {
    return this.formBuilder.group({ bLaufendeNr: data ? new UntypedFormControl(data['bLaufendeNr']) : new UntypedFormControl('') });
  }

  private initGSTNr(data = null) {
    return this.formBuilder.group({ grundstuecksnummer: data ? new UntypedFormControl(data['grundstuecksnummer']) : new UntypedFormControl('') });
  }

  fillFormWithData(weglogic: any) {
    if (weglogic && weglogic.immodetail) {
      this.immodetail = this.initImmodetailFormGroupWithData(
        weglogic.immodetail
      );
      console.log(weglogic);
      console.log(weglogic.immodetail);
      console.log(weglogic.immodetail.bLaufendeNrAr);
      console.log(weglogic.immodetail.grundstuecksnummerAr);
      let JSON = {
        einlageZahl: weglogic.immodetail.einlageZahl ? weglogic.immodetail.einlageZahl : '',
        top : weglogic.immodetail.top ? weglogic.immodetail.top : '',
        anteil : weglogic.immodetail.anteil ? weglogic.immodetail.anteil : '',
        bLaufendeNrAr : weglogic.immodetail.bLaufendeNrAr ? this.initBLNRWithCount(weglogic.immodetail.bLaufendeNrAr) : null,
        grundstuecksnummerAr : weglogic.immodetail.grundstuecksnummerAr ? this.initGSTNew(weglogic.immodetail.grundstuecksnummerAr) : null,
        parkingPlaceCnt : weglogic.immodetail.parkingPlaceCnt ? weglogic.immodetail.parkingPlaceCnt : '',
        parkingPlace : weglogic.parkingPlaces ? this.initParking(weglogic.immodetail.parkingPlaceCnt, weglogic.parkingPlaces) : null
      };
      this.einlageZahlObject.push(this.initEinlageZahl(JSON, weglogic.immodetail.parkingPlaceCnt ? weglogic.immodetail.parkingPlaceCnt : 0));
    } else {
      console.log("else of fillFormWithData");
      let JSON = {
        einlageZahl: '',
        top : '',
        anteil : '',
        bLaufendeNrAr : null,
        grundstuecksnummerAr : null,
        parkingPlaceCnt : '',
        parkingPlace : null
      };
      this.einlageZahlObject.push(this.initEinlageZahl(JSON, 0));
    }

    if (weglogic && weglogic.immodetail1) {
      let JSON = {
        einlageZahl: weglogic.immodetail1.einlageZahl ? weglogic.immodetail1.einlageZahl : '',
        top : weglogic.immodetail1.top ? weglogic.immodetail1.top : '',
        anteil : weglogic.immodetail1.anteil ? weglogic.immodetail1.anteil : '',
        bLaufendeNrAr : weglogic.immodetail1.bLaufendeNrAr ? this.initBLNRWithCount(weglogic.immodetail1.bLaufendeNrAr) : null,
        grundstuecksnummerAr : weglogic.immodetail1.grundstuecksnummerAr ? this.initGSTNew(weglogic.immodetail1.grundstuecksnummerAr) : null,
        parkingPlaceCnt : weglogic.immodetail1.parkingPlaceCnt ? weglogic.immodetail1.parkingPlaceCnt : '',
        parkingPlace : weglogic.parkingPlaces1 ? this.initParking(weglogic.immodetail1.parkingPlaceCnt, weglogic.parkingPlaces1) : null
      };
      this.einlageZahlObject.push(this.initEinlageZahl(JSON));
    }

    if (weglogic && weglogic.immodetail2) {
      let JSON = {
        einlageZahl: weglogic.immodetail2.einlageZahl ? weglogic.immodetail2.einlageZahl : '',
        top : weglogic.immodetail2.top ? weglogic.immodetail2.top : '',
        anteil : weglogic.immodetail2.anteil ? weglogic.immodetail2.anteil : '',
        bLaufendeNrAr : weglogic.immodetail2.bLaufendeNrAr ? this.initBLNRWithCount(weglogic.immodetail2.bLaufendeNrAr) : null,
        grundstuecksnummerAr : weglogic.immodetail2.grundstuecksnummerAr ? this.initGSTNew(weglogic.immodetail2.grundstuecksnummerAr) : null,
        parkingPlaceCnt : weglogic.immodetail2.parkingPlaceCnt ? weglogic.immodetail2.parkingPlaceCnt : '',
        parkingPlace : weglogic.parkingPlaces2 ? this.initParking(weglogic.immodetail2.parkingPlaceCnt, weglogic.parkingPlaces2) : null
      };
      this.einlageZahlObject.push(this.initEinlageZahl(JSON));
    }

    if (weglogic && weglogic.immodetail3) {
      let JSON = {
        einlageZahl: weglogic.immodetail3.einlageZahl ? weglogic.immodetail3.einlageZahl : '',
        top : weglogic.immodetail3.top ? weglogic.immodetail3.top : '',
        anteil : weglogic.immodetail3.anteil ? weglogic.immodetail3.anteil : '',
        bLaufendeNrAr : weglogic.immodetail3.bLaufendeNrAr ? this.initBLNRWithCount(weglogic.immodetail3.bLaufendeNrAr) : null,
        grundstuecksnummerAr : weglogic.immodetail3.grundstuecksnummerAr ? this.initGSTNew(weglogic.immodetail3.grundstuecksnummerAr) : null,
        parkingPlaceCnt : weglogic.immodetail3.parkingPlaceCnt ? weglogic.immodetail3.parkingPlaceCnt : '',
        parkingPlace : weglogic.parkingPlaces3 ? this.initParking(weglogic.immodetail3.parkingPlaceCnt, weglogic.parkingPlaces3) : null
      };
      this.einlageZahlObject.push(this.initEinlageZahl(JSON));
    }

    // 8261
    // if (weglogic && weglogic.immodetail && weglogic.immodetail.parkingPlaceCnt && weglogic.immodetail.parkingPlaceCnt > 0) {
    //   weglogic.parkingPlaces.forEach(element =>
    //     this.parkingPlaces.push(this.initParkingPlaceWithData(element))
    //   );
    // }
  }

  save() {
    console.log(this.immodetail);
  }

  initImmodetailFormGroupWithData(immodetail: any): UntypedFormGroup {
    console.log("immodetail.katastralGemeinde");
    console.log(immodetail.katastralGemeinde);
    return this.formBuilder.group({
      katastralGemeinde: new UntypedFormControl(immodetail.katastralGemeinde),
      einlageZahlObject: this.formBuilder.array([])
    });
  }

  setExpenseCategories(data) {
    let control = <UntypedFormArray>this.immodetail.controls.grundstuecksnummerAr;
    data.forEach(x => {
      control.push(this.formBuilder.group(x));
    });
  }

  setEinlageZahlAr(data) {
    let control = <UntypedFormArray>this.immodetail.controls.einlageZahlObject;
    data.forEach(x => {
      control.push(this.formBuilder.group(x));
    });
  }

  get bLaufendeNrAr(): UntypedFormArray {
    return this.immodetail.get('einlageZahlObject.bLaufendeNrAr') as UntypedFormArray;
  }

  get grundstuecksnummerAr(): UntypedFormArray {
    return this.immodetail.get('einlageZahlObject') as UntypedFormArray;
  }

  get einlageZahlObject(): UntypedFormArray {
    return this.immodetail.get('einlageZahlObject') as UntypedFormArray;
  }

  public addGstNr(i) {
    let group = this.grundstuecksnummerAr.controls[i];
    let s = group.get('grundstuecksnummerAr') as UntypedFormArray;
    s.push(this.formBuilder.group({ grundstuecksnummer: new UntypedFormControl('') }));
  }

  private removeGstNr(i, j) {
    let group = this.grundstuecksnummerAr.controls[i];
    let s = group.get('grundstuecksnummerAr') as UntypedFormArray;
    s.removeAt(j);
  }

  public addEinlageZahl() {
    this.einlageZahlObject.push(this.initEinlageZahl());
  }

  private removeEinlageZahl(i) {
    this.einlageZahlObject.removeAt(i);
  }

  displayFn(village?: any): string | undefined {
    const temp = village
      ? village.KGName + ' (' + village.KGNr + ')'
      : undefined;
    return village ? temp : undefined;
  }

  openDatenLiegenschaftDlg(): void {
    const dialogRef = this.datenLiegenschaftDlg.open(DlgDatenLiegenschaftComponent);
  }

  openWEGDatenzLSDlg(): void {
    const dialogRef = this.wegDatenzLSDlg.open(DlgWegDatenZurLiegenschaftComponent);
  }

  openWEGDatenzWohnodGeschOneVerDlg(): void {
    const dialogRef = this.WEGDatenzWohnodGeschOneVerDlg.open(DlgDatenZWohnodGeschOneVerComponent);
  }

  openWEGDatenzWohnodGeschMoreVerDlg(): void {
    const dialogRef = this.WEGDatenzWohnodGeschMoreVerDlg.open(DlgDatenZWohnodGeschMoreVerComponent);
  }

  onSelectKg(type, v) {
    console.log("onSelectKg");
    if(type == 'l1') {
      this.immodetail
        .get('katastralGemeinde').setValue(v);
    }
  }

  logg(e) {
    console.log(e);
  }

}
