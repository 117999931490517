import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, FormArray, UntypedFormGroup, Validators, AbstractControl  } from '@angular/forms';
import { SharedService } from '../shared/shared.service';
import { ContractDataService } from '../shared/contractData.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-contract-details',
  templateUrl: './contract-details.component.html',
  styleUrls: ['./contract-details.component.css']
})
export class ContractDetailsComponent implements OnInit {

  contract : any = {};
  json : any = "";
  contractForm: UntypedFormGroup;
  id = 0
  @ViewChild('UploadFileInput') uploadFileInput: ElementRef;
  @ViewChild('UploadFileInput2') uploadFileInput2: ElementRef;
  myfilename = '';
  vertragPdf = 'Keine Datei ausgewählt';
  contractInvoices : any = [];
  invoicesLength = 0;
  invoices : any = [];
  displayedColumns: string[] = [
    'fileTitle',
    'action'
  ];

  displayedColumnLabels = {
    fileTitle : 'File title',
    action : 'Action'
  };
  environment = environment;
  vertragPdfFile = [];

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private shared : SharedService,
    private contractService : ContractDataService
  ) { }

  ngOnInit(): void {
    this.contractForm = this.formBuilder.group({
      title: ['', Validators.required],
      json: ['', Validators.required],
      status: ['', Validators.required],
      contractID: ['', Validators.required],
      contractType: ['', Validators.required],
      vertrag_url: ['', Validators.required],
      informationsschreiben_kaufer: ['', Validators.required],
      informationsschreiben_verkaufer: ['', Validators.required],
      rangordnung: ['', Validators.required]
    });
    this.id = this.route.snapshot.params['id'];
    this.contractService.changePage({ page : 'Vertrags details' });
    this.contractDetails(this.id);
  }

  contractDetails(id) {
    this.shared.getApi('contract/' + id)
      .subscribe((res:any) => {
        this.json = res.acf.json;
        this.contract = res;
        let { informationsschreiben_kaufer, informationsschreiben_verkaufer, rangordnung, vertrag_url } = res.acf;
        this.contractForm.patchValue({ contractType : res.con.contractType, title : res.con.title, json : this.json , status : res.con.status , contractID : res.con.contractID , informationsschreiben_kaufer, informationsschreiben_verkaufer, rangordnung, vertrag_url });
        this.invoicesLength    = res.invoices.length;
        this.invoices          = res.invoices;
        this.contractInvoices  = new MatTableDataSource(res.invoices);
        this.vertragPdf        = res.acf.vertrag_pdf_2 ? res.acf.vertrag_pdf_2 : 'No File Selected';
      }, (err:any) => {

      });
  }

  gotoNext() {
    let value = this.contractForm.value;
    value['postID'] = this.contract.con.postID

    this.shared.postApi('contract/update/' + this.id + '?type=vertragPdf', value).subscribe( (res:any) => {
      this.vertragPdf = "";
      this.contractDetails(this.id);
      this.shared.openSnackBar(res.success);
    }, (err:any) => {
      this.shared.openSnackBar("Something went wrong");
    });

    // this.shared.postApi('contract/update/' + this.id,this.contractForm.value)
    //   .subscribe((res:any) => {
    //     this.shared.openSnackBar(res.success);
    //   }, (err:any) => {
    //     this.shared.openSnackBar("Something went wrong");
    //   });
  }


  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.shared.openSnackBar("Uploading...");
      this.myfilename = fileInput.target.files[0].name;
       let formData:FormData = new FormData();
       formData.append('uploadFile', fileInput.target.files[0], fileInput.target.files[0].name);
       this.shared.uploadFile('contracts/upload-invoice/' + this.id, fileInput.target.files[0]).subscribe( (res:any) => {
         this.myfilename = '';
         this.contractDetails(this.id);
         this.shared.openSnackBar(res.success);
       }, (err:any) => {
         this.shared.openSnackBar("Something went wrong");
       });
    } else {
      this.shared.openSnackBar("Bitte Datei auswählen");
    }
  }

  fileChangeEvent2(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.vertragPdf = fileInput.target.files[0].name;
      this.vertragPdfFile = fileInput.target.files;
       // let formData:FormData = new FormData();
       // formData.append('uploadFile', fileInput.target.files[0], fileInput.target.files[0].name);
       // this.shared.uploadFile('contracts/upload-invoice/' + this.id, fileInput.target.files[0]).subscribe( (res:any) => {
       //   this.myfilename = '';
       //   this.contractDetails(this.id);
       //   this.shared.openSnackBar(res.success);
       // }, (err:any) => {
       //   this.shared.openSnackBar("Something went wrong");
       // });
    } else {
      this.shared.openSnackBar("Bitte Datei auswählen");
    }
  }

  deleteContact(event, row, i) {
    console.log(event);
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data : { title : "Sind Sie sicher, dass Sie löschen möchten?" }});
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        console.log("this");
        this.shared.deleteApi('contracts/invoice/' + row._id)
          .subscribe((res:any) => {
            this.invoices.splice(i, 1); // Not worked
            this.contractInvoices  = new MatTableDataSource(this.invoices);
          }, (err:any) => {

          });
      }
     });
  }


  copyUrl(event, vertragPdf) {
    event.stopPropagation();
    console.log(this.vertragPdf);
    navigator.clipboard.writeText(this.vertragPdf);
  }


}
