import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../auth.service';
import { faUsers, faFileInvoice, faBuilding, faCog, faCogs, faUsersCog, faUserCog } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})

export class NavbarComponent {
  faUserCircle = faUserCircle;
  @Input() title: String = "";
  user;
  faUsers = faUsers;
  faBuilding = faBuilding;
  faFileInvoice = faFileInvoice;

  faCog   = faCog;
  faCogs  = faCogs;
  faUsersCog = faUsersCog;
  faUserCog = faUserCog;
  isUserLoggedIn;
  userRole = 0;

  constructor(private router : Router, private auth: AuthService) {
  }

  ngAfterViewInit() {
    this.auth.currentUser.subscribe((user : any) => {
      if(user) {
        this.user = user;
        this.userRole = user.userRole;
        this.isUserLoggedIn = true;
      } else {
        this.user = undefined;
        this.isUserLoggedIn = false;
        this.userRole = 0;
      }
    });
  }

  logOut() {
    this.auth.logOut();
    this.router.navigate(['/auth/login']);
  }

}
