import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { map, retry } from 'rxjs/operators';
import * as _ from 'lodash';
import { LooseCurrencyPipe } from './looseCurrency.pipe';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class AuthService {

  private dataSource = new BehaviorSubject({ page: 'kauf' });
  private dataSource2 = new BehaviorSubject(null);
  currentUser  = this.dataSource2.asObservable();

  constructor(
    private http: HttpClient,
  ) {
    var user = localStorage.getItem('user');
    if(user) {
      this.dataSource2.next(JSON.parse(user));
    }
  }

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  login(data) {
    return this.http.post(environment.NodeAppUrl + '/auth/', data, this.httpOptions);
  }

  loginNew(data) {
    return this.http.post(environment.NodeAppUrl + '/auth/login', data, this.httpOptions);
  }

  resendVerificationEmail(data) {
    return this.http.post(environment.NodeAppUrl + '/auth/resend-verification-email', data, this.httpOptions);
  }

  forgotPassword(data) {
    return this.http.post(environment.NodeAppUrl + '/auth/forgot-password', data, this.httpOptions);
  }

  resetPassword(data, token) {
    return this.http.post(environment.NodeAppUrl + '/auth/reset-password/' + token, data, this.httpOptions);
  }

  register(data) {
    return this.http.post(environment.NodeAppUrl + '/auth/register', data, this.httpOptions);
  }

  changeUser(data: any) {
    if(data && data.user) {
      localStorage.setItem('user', JSON.stringify(data.user));
      this.dataSource2.next(data.user);
    } else {
      this.dataSource2.next(null);
    }
  }

  isUserLoggedIn() {
    var user = localStorage.getItem('user');
    if(user) {
      let u = JSON.parse(user);
      return true;
    }
    return false;
  }

  getUser() {
    var user = localStorage.getItem('user');
    if(user) {
      let u = JSON.parse(user);
      return u;
    }
    return undefined;
  }

  async getToken() {
    var user = localStorage.getItem("user");
    if(user) {
      let u = JSON.parse(user);
      return u.access_token;
    }
    return undefined;
  }

  logOut() {
    localStorage.removeItem("page");
    localStorage.removeItem("user");
    localStorage.removeItem("isEditing");
    localStorage.removeItem("userLoggedIn");
    localStorage.removeItem("primeType");
    localStorage.removeItem("contractDataSafe");
    localStorage.removeItem("contractData");
    localStorage.removeItem("object");
    localStorage.removeItem("gesellschafts");
    document.cookie = "wpUser=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    this.changeUser(null);
  }

  updatePassword(data) {
    return this.http.post(environment.NodeAppUrl + '/password/update', data, this.httpOptions);
  }

  updateAccount(data) {
    return this.http.post(environment.NodeAppUrl + '/account/update', data, this.httpOptions);
  }

  getUserDetails(id) {
    return this.http.get(environment.NodeAppUrl + '/lawyers/'+id, this.httpOptions);
  }

}
