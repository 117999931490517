<mat-dialog-content>
  <mat-card>
    <mat-card-content>
      <!-- <h1 mat-dialog-title>{{ title }}</h1> -->
      <div mat-dialog-content style="font-size: 18px">{{ title }}</div>
      <div mat-dialog-actions>
        <button class="btn btn-sm shadow-btn btn-dark mr-2" (click)="onConfirm()">Yes</button>
        <button class="btn btn-sm shadow-btn btn-light" (click)="onDismiss()">No</button>
      </div>
    </mat-card-content>
  </mat-card>
</mat-dialog-content>
