import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import { faArrowLeft, faArrowRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import { ContractDataService } from '../../shared/contractData.service';
import { LooseCurrencyPipe } from '../../shared/looseCurrency.pipe';
import { AuthService } from '../../shared/auth.service';

@Component({
  selector: 'app-dlg-vorschau-gesell',
  templateUrl: './dlg-vorschau-gesell.component.html',
  styleUrls: ['./dlg-vorschau-gesell.component.css']
})
export class DlgVorschauGesellComponent implements OnInit {

  constructor(private auth : AuthService, private looseCurrencyPipe : LooseCurrencyPipe, public dialogRef: MatDialogRef<DlgVorschauGesellComponent>, @Inject(ContractDataService) private contractDataService: ContractDataService,) { }

  faArrowRight = faArrowRight;
  faArrowLeft = faArrowLeft;
  faCheck = faCheck;
  total = "";
  totalHalf = "";
  allBuyers = [];
  public contractData;
  disableSubmit = false;
  isEditing : boolean = false;
  paymentFlag;

  ngOnInit() {
    this.auth.currentUser.subscribe((user : any) => {
      if(user) {
        this.paymentFlag = user.paymentFlag;
      }
      else
        this.paymentFlag = false;
    });

    this.contractData = this.contractDataService.getContractData();
    console.log(this.contractData);
    this.isEditing = localStorage.getItem('isEditing') ? true : false;

    let bp = this.contractData.masterData?.buyersPrivate ?? [];
    let bc = this.contractData.masterData?.buyersCompany ?? [];
    this.allBuyers = bp.concat(bc);

    if(this.allBuyers.length) {
      this.allBuyers = this.allBuyers.map(x => ({
          ...x,
          payment: this.looseCurrencyPipe.transform(( x.beteiligungsquote / 100 ) * this.transformCurrencyToNumber(this.contractData.wegLogic.stammkapital), 'EUR'),
          paymentHalf: this.looseCurrencyPipe.transform(((( x.beteiligungsquote / 100 ) * this.transformCurrencyToNumber(this.contractData.wegLogic.stammkapital)) / 2) , 'EUR')
      }));

      console.log(this.allBuyers);

      let total = 0;
      for (let b of this.allBuyers) {
        total += Number(this.transformCurrencyToNumber(b.payment));
      }
      this.total      = this.looseCurrencyPipe.transform(total, 'EUR');
      this.totalHalf  = this.looseCurrencyPipe.transform((total / 2), 'EUR');
    }
  }

  closeDlg(): void {
    this.dialogRef.close({ success: false });
  }

  closeOKDlg(type): void {
    this.dialogRef.close({ success: true, type });
  }

  transformCurrencyToNumber(input) {
    if (input !== undefined) {
      const strInput = input.replace('.', '');
      const strInput2 = strInput.replace(',', '.');
      const toNumber = parseFloat(strInput2);
      return toNumber;
    }
    return input;
  }

}
