export interface VertragStateModel {
  tst: string | null;
  metaData: string | null;
  contactData: string | null;
  contractOption: string | null;
  currentStep: string | null;
  primeType: string | null;
  buyers: [] | null;
  sellers: [] | null;
  noOfSellers: null;
  noOfBuyers: null;
  kaeuferanteile: string | null;
  AnzahlLS: number | null;
  moreLSBT: boolean | null;
  lessLSBT: boolean | null;
  subStep: null;
  subObject: boolean | null;
  newUi: boolean | null;
  reload: boolean | null;
  parent: string | null;
  objectId: string | null;
}

export class Modify {
  static readonly type = '[Auth] Modify';
  constructor(public payload: any) {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
}
