import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class AuthGuard implements CanActivate, CanActivateChild {

  constructor( private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot)
    {
      // if(environment.production) {
        if (localStorage.getItem("user") === null) {
          if(route.params['postId'])
          localStorage.setItem('postId', route.params['postId']);
          var url = route['_routerState'].url;
          console.log(url);
          // url = url.split('/');
          console.log(url);
          this.router.navigate(['/auth/login'], { queryParams : {'redirect' : url }});
          return true;
        }
        else {
          return true;
        }
      // } else {
      //   return true;
      // }
    }


  canActivateChild() {
    console.log('checking child route access');
    return true;
  }

}
