<div class="container">
    <div class="full-grid-tile">

      <div class="row counters mt-2 mb-2">
        <mat-card class="col-3 hand" (click)="reloadCons('all')">
          <h1>{{ all }}</h1>
          <mat-card-content class="text-warning">Gesamt</mat-card-content>
        </mat-card>
        <mat-card class="col-3 ml-4 hand" routerLink="/contracts" (click)="reloadCons('Bestatigung offen')">
          <h1>{{ pending }}</h1>
          <mat-card-content class="text-danger">Ausstehend</mat-card-content>
        </mat-card>
        <mat-card class="col-3 ml-4 hand" routerLink="/contracts" (click)="reloadCons('Bestatigt')">
          <h1>{{ completed }}</h1>
          <mat-card-content class="text-success">Bestätigt</mat-card-content>
        </mat-card>
      </div>

      <!-- <mat-form-field appearance="none" style="width: 20% !important; float: left">
        <mat-label>Filter nach Ansprechpartner</mat-label>
        <mat-select (selectionChange)="filter($event)" [(value)]="user" style="overflow: hidden;">
          <mat-option [value]="''">Alle</mat-option>
          <mat-option *ngFor="let user of users" [value]="user._id">
            {{ user.firstName }} {{ user.lastName }}
            <span *ngIf="user.lawyer && user.auth.userRole == 1"> Benutzer - {{ user.lawyer.firstName }} {{ user.lawyer.lastName }} </span>
            <span *ngIf="user.auth   && user.auth.userRole == 1"> ({{ user.auth.email }}) </span>
          </mat-option>
        </mat-select>
      </mat-form-field> -->
      <!-- <mat-form-field appearance="none" style="width: 23% !important; float: left; margin-left: 11px !important">
        <mat-label>Filter nach Vertragsart</mat-label>
        <mat-select (selectionChange)="filterContractType($event)" [(value)]="contractType">
          <mat-option value="">Alle</mat-option>
          <mat-option value="mietvertrag">Mietvertrag</mat-option>
          <mat-option value="kaufvertrag">Kaufvertrag</mat-option>
          <mat-option value="schenkung">Schenkung</mat-option>
          <mat-option value="kaufanbot">Kaufanbot</mat-option>
        </mat-select>
      </mat-form-field> -->
      <!-- <mat-form-field appearance="none" style="width: 18% !important; float: left; margin-left: 9px !important">
        <mat-label>Filter nach Status</mat-label>
        <mat-select (selectionChange)="filterStatus($event)" [(value)]="status">
          <mat-option value="">Alle</mat-option>
          <mat-option value="Bestatigung offen">Bestätigung offen</mat-option>
          <mat-option value="Bestatigt">Bestatigt</mat-option>
        </mat-select>
      </mat-form-field> -->

      <div class="row">
        <mat-form-field appearance="none" style="width: 35% !important; float: right;" class="bg-white-input">
          <mat-label>Suchen</mat-label>
          <input type="text" matInput [(ngModel)]="searchText" (ngModelChange)="searchTextUpdate.next($event)" appTrim/>
        </mat-form-field>

        <mat-form-field appearance="none" class="no-padding no-line datePickerField bg-white-input" style="width: 28% !important; float: right; margin-left:1% !important">
          <mat-label>Erstellungsdatum (dd.mm.yyyy)</mat-label>
          <input required matInput [matDatepicker]="picker" pattern="^[^\s]+(\s+[^\s]+)*$" [(ngModel)]="date" (ngModelChange)="dateUpdate.next($event)" appTrim/>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <div style="width: 35% !important; float: right; margin-left: 1%; padding-top: 16px;" class="bg-white-input">
          <mat-button-toggle-group [(ngModel)]="contractType" [ngModelOptions]="{standalone: true}" appearance="legacy" style="max-width: 100%">
            <mat-button-toggle value="all" style="padding: 3px;" (change)="filterContractType($event.value)">All</mat-button-toggle>
            <mat-button-toggle value="api" style="padding: 3px;" (change)="filterContractType($event.value)">Api</mat-button-toggle>
            <mat-button-toggle value="app" style="padding: 3px;" (change)="filterContractType($event.value)">App</mat-button-toggle>
            <mat-button-toggle value="gesellschaftsvertrag" style="padding: 3px;" (change)="filterContractType($event.value)">Gesellschaftsvertrag</mat-button-toggle>
          </mat-button-toggle-group>
        </div>


        <div *ngIf="resultsLength == 0 && !loading" class="bg-white p-4 w-100 shadow">
          Keine Verträge zum Anzeigen
        </div>

        <div *ngIf="resultsLength == 0 && loading" class="text-center bg-white p-4 w-100">
          <div class="spinner-border" style="width: 4rem; height: 4rem;">
            <span class="sr-only"></span>
          </div>
        </div>
      </div>

      <!-- <table mat-table [dataSource]="contracts">
        <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
          <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
          <td mat-cell *matCellDef="let con">{{ con[column] }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let conrow; columns: displayedColumns"></tr>
      </table> -->

      <div class="row table-outer">
        <table mat-table [dataSource]="contracts" class="mat-elevation-z8 w-100" matSort matSortActive="createdAt" matSortDirection="desc" (matSortChange)="sortChange($event)" *ngIf="resultsLength != 0">
          <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
              <span>
                {{ displayedColumnLabels[column] }}
              </span>
            </th>
            <td mat-cell *matCellDef="let con" class="">
              <div *ngIf="column != 'contactEmail' && column != 'contactId' && column != 'addedBy' && column != 'contractType' && column != 'createdAt' && column != 'invStatus' && column != 'invNo' && column != 'status'">
                {{ con[column] }}
              </div>
              <div *ngIf="column == 'invNo'">
                <span *ngIf="con?.invoice && con?.invoice.showToCustomer && loggedInUser.userRole != 0">
                  {{ con?.invoice?.invNo }}
                </span>
                <span *ngIf="con?.invoice && loggedInUser.userRole == 0">
                  {{ con?.invoice?.invNo }}
                </span>
              </div>
              <div *ngIf="column == 'invStatus'">
                <span *ngIf="con?.invoice && con?.invoice.showToCustomer  && loggedInUser.userRole != 0">
                  <data value="" *ngIf="con?.invoice?.paid" class="text-success">Bezahlt</data>
                  <data value="" *ngIf="!con?.invoice?.paid" class="text-danger">Nicht bezahlt</data>
                </span>
                <span *ngIf="con?.invoice && loggedInUser.userRole == 0">
                  <data value="" *ngIf="con?.invoice?.paid" class="text-success">Bezahlt</data>
                  <data value="" *ngIf="!con?.invoice?.paid" class="text-danger">Nicht bezahlt</data>
                </span>
              </div>
              <div *ngIf="column == 'status'">
                <button [matMenuTriggerFor]="menu2" mat-button class="bg-info small-button" *ngIf="loggedInUser?.userRole == 0">
                  <span *ngIf="con?.status == 'Bestatigung offen'">Bestätigung offen</span>
                  <span *ngIf="con?.status == 'Bestatigt'">Bestatigt</span>
                </button>

                <p *ngIf="loggedInUser?.userRole != 0 && con?.status == 'Bestatigung offen'">Bestätigung offen</p>
                <p *ngIf="loggedInUser?.userRole != 0 && con?.status == 'Bestatigt'">Bestatigt</p>

                <mat-menu [overlapTrigger]="false" #menu2="matMenu" backdropClass="my-mat-menu" style="width: 100px">
                  <button mat-menu-item color="primary" (click)="contractStatus(con._id, 'Bestatigung offen')">
                    Bestätigung offen
                  </button>
                  <button mat-menu-item color="primary" (click)="contractStatus(con._id, 'Bestatigt')">
                    Bestatigt
                  </button>
                </mat-menu>
              </div>
              <div *ngIf="column == 'contactId'">
                <data *ngIf="con.addedByDetails">
                  {{ con?.addedByDetails?.firstName }}
                  {{ con?.addedByDetails?.lastName }}
                </data>
                <data *ngIf="con.addedByApi">
                  {{ con?.addedByApi?.name }}
                </data>
              </div>
              <div *ngIf="column == 'contactEmail'">
                <data *ngIf="con.addedByDetails">
                  {{ con?.auth?.email }}
                </data>
                <data *ngIf="con.addedByApi">
                  {{ con?.addedByApi?.email }}
                </data>
              </div>

              <div *ngIf="column == 'amount'">
                {{ ( con?.invoice?.totalAmount / 100) | currency : 'EUR' }}
              </div>

              <!-- <div *ngIf="column == 'addedBy' && con[column]">
                {{ con[column]['firstName'] }}
                {{ con[column]['lastName'] }}
              </div> -->
              <div *ngIf="column == 'createdAt'">
                {{ con[column] | date: 'dd/MM/yyyy hh:mm a' }}
              </div>
              <div *ngIf="column == 'contractType'">
                <data *ngIf="con[column] == 'mietvertrag'">Mietvertrag</data>
                <data *ngIf="con[column] == 'kaufvertrag'">Kaufvertrag</data>
                <data *ngIf="con[column] == 'schenkung'">Schenkung</data>
                <data *ngIf="con[column] == 'kaufanbot'">Kaufanbot</data>
                <data *ngIf="con[column] == 'gesellschaftsvertrag'">Gesellschaftsvertrag</data>
              </div>
              <div *ngIf="column == 'action'">
                <button type="button" class="btn bg-dark shadow-btn" *ngIf="!con?.invoice?.paid && con?.invoice?.stripe_paymentIntent && loggedInUser?.userRole != 0" (click)="payNow(con)">Bezahlen Sie jetzt</button>
                <button *ngIf="loggedInUser?.userRole == 0" mat-button class="bg-info small-button" (click)="confirmContract($event, 'confirm', con)">
                  <mat-icon>done</mat-icon>
                </button>

                <button *ngIf="loggedInUser?.userRole == 0 && con['contractType'] != 'gesellschaftsvertrag'" (click)="editContract(con._id)" mat-icon-button>
                  <mat-icon style="color: #272844 !important">create</mat-icon>
                </button>
                <button *ngIf="loggedInUser?.userRole == 0 && con['contractType'] == 'gesellschaftsvertrag'" (click)="editGesellContract(con._id)" mat-icon-button>
                  <mat-icon style="color: #272844 !important">create</mat-icon>
                </button>

                <!-- <data class="text-warning" *ngIf="(con.draft == true || con.status == 'Bestatigung offen') && loggedInUser?.userRole == 1">Pending</data> -->
                <!-- *ngIf="((con.draft == false && con.status == 'Bestatigt') || (con.contractType == 'mietvertrag'))" -->

                <button mat-icon-button *ngIf="(con.draft == true || con.status == 'Bestatigung offen') &&  con.contractType != 'mietvertrag' && loggedInUser?.userRole != 0" disabled>
                  <mat-icon style="color: red !important">get_app</mat-icon>
                </button>

                <!-- <button *ngIf="!con.rejected || con.rejected == false" mat-button class="bg-danger small-button" (click)="cancelContract($event, 'cancel', con)"><mat-icon>close</mat-icon></button> -->

                <!-- <button class="btn btn-primary btn-sm" [matMenuTriggerFor]="menu">
                  Download Contract
                </button> -->

                <button [matMenuTriggerFor]="menu" mat-icon-button *ngIf="((loggedInUser?.userRole == 0) || (con.draft == false && con.status == 'Bestatigt') || (con.contractType == 'mietvertrag'))">
                  <mat-icon style="color: #272844 !important">get_app</mat-icon>
                </button>
                <a [routerLink]="'/contracts/' + con._id" style="position: relative; top: 8px;" *ngIf="loggedInUser?.userRole == 0">
                  <mat-icon style="color: #272844 !important">remove_red_eye</mat-icon>
                </a>

                <a [routerLink]="'/billing/invoices'" [queryParams]="{ contractId: con._id, gesellId: con?.gesellId?._id, customerId: con?.gesellId?.addedBy }" mat-icon-button *ngIf="loggedInUser?.userRole == 0 && con.contractType == 'gesellschaftsvertrag'">
                  <mat-icon style="color: #272844 !important">euro_symbol</mat-icon>
                </a>

                <a [routerLink]="'/billing/invoices'" [queryParams]="{ contractId: con._id, objectId: con?.objectId?._id, customerId: con?.objectId?.addedBy }" mat-icon-button *ngIf="loggedInUser?.userRole == 0 && con.contractType != 'gesellschaftsvertrag'">
                  <mat-icon style="color: #272844 !important">euro_symbol</mat-icon>
                </a>

                <mat-menu [overlapTrigger]="false" #menu="matMenu" backdropClass="my-mat-menu" style="width: 100px">
                  <button mat-menu-item color="primary" (click)="downloadContract(con, con._id, 'informationsschreiben_kaufer')" *ngIf="((con.draft == false && con.status == 'Bestatigt' && con.contractType != 'mietvertrag' && con.contractType != 'gesellschaftsvertrag' ))">
                    Informationsschreiben Käufer
                  </button>
                  <button mat-menu-item color="primary" (click)="downloadContract(con, con._id, 'informationsschreiben_verkaufer')" *ngIf="((con.draft == false && con.status == 'Bestatigt' && con.contractType != 'mietvertrag'  && con.contractType != 'gesellschaftsvertrag'))">
                    Informationsschreiben Verkäufer
                  </button>
                  <button mat-menu-item color="primary" (click)="downloadContract(con, con._id, 'rangordnung')" *ngIf="((con.draft == false && con.status == 'Bestatigt' && con.contractType != 'mietvertrag')) && con.contractType != 'schenkung'  && con.contractType != 'gesellschaftsvertrag'">
                    Rangordnung
                  </button>
                  <button mat-menu-item color="primary" (click)="downloadContract(con, con._id, 'vertrag_url')" *ngIf="((loggedInUser?.userRole == 0) || (con.draft == false && con.status == 'Bestatigt') || (con.contractType == 'mietvertrag'))">
                    <span *ngIf="loggedInUser?.userRole == 0">Vertag PDF</span>
                    <span *ngIf="loggedInUser?.userRole == 1">Vertag</span>
                  </button>
                  <button mat-menu-item color="primary" (click)="downloadContract(con, con._id, 'docx')" *ngIf="((loggedInUser?.userRole == 0) || (con.draft == false && con.status == 'Bestatigt') || (con.contractType == 'mietvertrag')) && loggedInUser?.userRole == 0">
                    <span>Vertag Docx</span>
                  </button>
                </mat-menu>

                <button type="button" class="btn bg-dark shadow-btn" (click)="newKauf(con)" *ngIf="con['contractType'] == 'kaufanbot'">
                  Kaufvertrag erstellen
                </button>

              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let conrow; columns: displayedColumns"></tr>
        </table>
        <mat-paginator *ngIf="resultsLength != 0" (page)="handlePageEvent($event)" [length]="resultsLength" [pageSize]="perPage" [pageSizeOptions]="[5, 10, 25, 50, 100]" class="mb-5 shadow w-100"></mat-paginator>
      </div>
    </div>
</div>
