<div class="container text-center">
  <h3>Daten Gesellschafter:</h3>

  <button mat-raised-button color="primary" (click)="addBuyerComplex('person')" class="small-height-button" [disabled]="ifMaximumBuyers()">
    Privaten Gesellschafter hinzufügen &nbsp;&nbsp;&nbsp;&nbsp;
  </button>
  <button mat-raised-button color="primary"
    (click)="addBuyerComplex('company')" class="small-height-button" [disabled]="ifMaximumBuyers()">
    Firma Gesellschafter hinzufügen &nbsp;&nbsp;&nbsp;&nbsp;
  </button>

  <div *ngFor="let buyer of buyersPrivateFormArray.controls; let i = index">
    <h4 class="title-with-icon  mb-0">Privater Gesellschafter {{ i + 1 }}
      <fa-icon [icon]="faTimes" class="icon" (click)="removeBuyerPrivate(i)"></fa-icon>
    </h4>
    <app-person-form [parent]="buyer" [superParent]="masterForm" [submitted]="submitted"></app-person-form>
    <div *ngIf="masterForm.errors?.sharesError" class="text-left text-danger">
      Die Gesamtzahl der Anteile muss 100% betragen.
    </div>
  </div>


  <div *ngFor="let buyer of buyersCompanyFormArray.controls; let i = index">
    <h4 class="title-with-icon mb-0">Firma Gesellschafter
      {{ i + 1 }}
      <fa-icon [icon]="faTimes" class="icon" (click)="removeBuyerCompany(i)"></fa-icon>
    </h4>
    <app-company-form [parent]="buyer" [superParent]="masterForm" [submitted]="submitted"></app-company-form>
    <div *ngIf="masterForm.errors?.sharesError" class="text-left text-danger">
      Die Gesamtzahl der Anteile muss 100% betragen.
    </div>
  </div>


  <div class="text-center mb-4 mt-3" style="color: red; display: block; width: 100%;"
    *ngIf="!masterForm.valid && masterForm.touched">
    Bitte füllen Sie alle Felder aus!<br />
  </div>
  <br>
  <div class="custom-weiter-div mt-3">
    <button mat-raised-button color="primary" class="custom-weiter bg-button"
      (click)="goBack()">
      Zurück
    </button>
    <button mat-raised-button color="primary" class="custom-weiter bg-button"
      (click)="gotoNext(masterForm.valid)">
      Weiter
    </button>
    <!-- <label style="float: right;">
      <fa-icon class="accent-color custom-weiter" [icon]="faArrowRight"></fa-icon>
    </label> -->
  </div>
  <br>
</div>
