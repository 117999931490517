<div class="container">
  <div class="col-6 offset-3 full-grid-tile">
    <mat-card style="position: relative; top: 150px;">
      <mat-card-content>
        <h4>The link has been expired</h4>
        <p>Haben Sie schon ein Konto? Hier anmelden, <a routerLink="/auth/register">Hier anmelden</a></p>
      </mat-card-content>
    </mat-card>
  </div>
</div>
