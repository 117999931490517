<div class="popup-div">
  <button mat-icon-button aria-label="close dialog" mat-dialog-close class="popup-close">
    <mat-icon>close</mat-icon>
  </button>

  <div class="container">
    <form [formGroup]="contractForm">
        <h3 class="text-left mt-0">PDF-Vertrag hochladen</h3>
        <div *ngIf="loading" class="text-center">
          <div class="spinner-border" style="width: 7rem; height: 7rem;">
            <span class="sr-only"></span>
          </div>
        </div>
        <div *ngIf="!loading">
          <mat-form-field appearance="none" class="no-padding no-line vertragPdfField">
            <div class="myfilebrowser">
              <mat-toolbar style="background-color: transparent !important;" class="p-0">
                <input matInput [(ngModel)]="vertragPdf" readonly name="vertragPdf" [ngModelOptions]="{standalone: true}" style="width: 50%;"/>
                <div layout="row">
                  <div flex="20" class="text-left">
                  </div>
                </div>
                <button mat-flat-button class="dark-blue-bg" (click)="copyUrl($event, this.vertragPdf)" type="button" *ngIf="vertragPdf != 'No File Selected' && vertragPdf != ''">
                  Link kopieren
                </button>
              </mat-toolbar>
              <input type="file" id="fileUpload2" #uploadFileInput2 (change)="fileChangeEvent2($event)" accept="application/pdf" />
            </div>
          </mat-form-field>
          <button class="btn btn-sm shadow-btn btn-light mr-2" [disabled]="!vertragPdfFile.length && !fileExists" (click)="gotoNext('draft')">Speichern</button>
          <button class="btn btn-sm shadow-btn btn-dark" [disabled]="!vertragPdfFile.length && !fileExists" (click)="gotoNext()">Speichern & Versenden</button>
        </div>
    </form>
  </div>


</div>
