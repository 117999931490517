<form [formGroup]="parent" fxLayout="column">
  <div class="full-grid-tile">
    <mat-grid-list [cols]="breakpoint" [rowHeight]="rowHeight" class="two-cols">
      <mat-grid-tile>
        <mat-form-field appearance="none" class="no-padding no-line" [ngClass]="{'error':!parent.controls['companyName'].valid && submitted }">
          <mat-label>Verlassenschaft nach</mat-label>
          <input matInput required formControlName="companyName" appTrim/>
        </mat-form-field>
        <mat-form-field appearance="none" class="no-padding no-line" [ngClass]="{'error':!parent.controls['companiesRegisterNumber'].valid && submitted }">
          <mat-label>Geburtstag (dd.mm.yyyy)</mat-label>
          <input matInput [matDatepicker]="picker" required
            formControlName="companiesRegisterNumber" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list [cols]="breakpoint" [rowHeight]="rowHeight" class="two-cols">
      <mat-grid-tile>
        <mat-form-field appearance="none" class="no-padding no-line" [ngClass]="{'error':!parent.controls['address'].valid && submitted }">
          <mat-label>Zuletzt wohnhaft in Straße + Hausnummer</mat-label>
          <input matInput required formControlName="address" appTrim/>
        </mat-form-field>

        <mat-form-field appearance="none" class="no-padding no-line" [ngClass]="{'error':!parent.controls['postalCode'].valid && submitted }">
          <mat-label>PLZ</mat-label>
          <input matInput maxlength="5" required formControlName="postalCode" appTrim/>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list [cols]="breakpoint" [rowHeight]="rowHeight" class="two-cols">
      <mat-grid-tile>
        <mat-form-field appearance="none" class="no-padding no-line" [ngClass]="{'error':!parent.controls['city'].valid && submitted }">
          <mat-label>Ort</mat-label>
          <input matInput required formControlName="city" appTrim/>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</form>
