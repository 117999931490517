<form [formGroup]="parent">
  <div class="full-grid-tile">
    <mat-grid-list [cols]="breakpoint" [rowHeight]="rowHeight" class="two-cols">
      <mat-grid-tile>
        <mat-form-field appearance="none" class="no-padding no-line" [ngClass]="{'error':!parent.controls['companyName'].valid && submitted }">
          <mat-label>Firmenname</mat-label>
          <input matInput required formControlName="companyName" pattern="^[^\s]+(\s+[^\s]+)*$" appTrim/>
        </mat-form-field>
        <mat-form-field appearance="none" class="no-padding no-line" [ngClass]="{'error':!parent.controls['companiesRegisterNumber'].valid && submitted }">
          <mat-label>Firmenbuchnummer</mat-label>
          <input matInput placeholder="z.B.: FN 454591n" required formControlName="companiesRegisterNumber" pattern="^[^\s]+(\s+[^\s]+)*$" appTrim/>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list [cols]="breakpoint" [rowHeight]="rowHeight" class="two-cols">
      <mat-grid-tile>
        <mat-form-field appearance="none" class="no-padding no-line" [ngClass]="{'error':!parent.controls['address'].valid && submitted }">
          <mat-label>Straße + Hausnummer</mat-label>
          <input matInput required formControlName="address" pattern="^[^\s]+(\s+[^\s]+)*$" appTrim/>
        </mat-form-field>
        <mat-form-field appearance="none" class="no-padding no-line" [ngClass]="{'error':!parent.controls['postalCode'].valid && submitted }">
          <mat-label>PLZ</mat-label>
          <input matInput maxlength="5" required formControlName="postalCode" pattern="^[^\s]+(\s+[^\s]+)*$" appTrim/>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list [cols]="breakpoint" [rowHeight]="rowHeight" class="two-cols">
      <mat-grid-tile>
        <mat-form-field appearance="none" class="no-padding no-line" [ngClass]="{'error':!parent.controls['city'].valid && submitted }">
          <mat-label>Ort</mat-label>
          <input matInput required formControlName="city" pattern="^[^\s]+(\s+[^\s]+)*$" appTrim/>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</form>
